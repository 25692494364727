import {defer, Observable} from "rxjs";
import {TextScrapWidget} from "../ui/text_scrap_widget";
import {TextSpec} from "../models/text_spec";
import {TextEditorWidget} from "../../text_editor/ui/text_editor_widget";
import {finalize, flatMap, last, tap} from "rxjs/operators";
import {commandUpdateTextScrap} from "../../collage_firebase/command_firestore_scrap";
import {doOnSubscribe} from "@piccollage/cbjs";
import {EditorWidget} from "../ui/editor_widget";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateEditText(editorWidget: EditorWidget, widget: TextScrapWidget)
  : Observable<TextSpec>
{
  return defer(() => {

    // ---- Create the editor
    const editor = editorWidget.legate(() => new TextEditorWidget(widget.spec$.value))
    editorWidget.textEditorWidget$.next(editor)
    return editor.spec$

    // ---- TODO: Shutdown up the TextEditorWidget
  }).pipe(
    doOnSubscribe(() => widget.manipulatorsCounter$.next(1)),
    finalize(() =>      widget.manipulatorsCounter$.next(-1)),

    // ---- Update the widget
    tap(spec => widget.spec_in$.next(spec)),

    // ---- Generate command when final
    last(),
    flatMap(spec =>
      commandUpdateTextScrap(widget.textScrap, spec)
        .push(editorWidget.undoContext)
        .execution()
        .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
    ),
    finalize(() => {
      editorWidget.textEditorWidget$.next(null)
    })
  )
}
