import {Context, ContextedDomainer} from "@piccollage/cbjs";
import {Observable, Subject} from "rxjs";
import {ScrapWidget} from "./scrap_widget";
import {BackdropWidget} from "./backdrop_widget";
import {tap} from "rxjs/operators";

export class EditorContext implements Context {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    readonly scrapWidgets$:     Observable<ScrapWidget[]>,
    readonly focusScrapWidget$: Observable<ScrapWidget|null>,
    readonly captureMode$:      Observable<boolean>,
    readonly backdropWidget:    BackdropWidget,
    readonly modified$:         Subject<any>,
  ) {
  }

  // ------------------------------------------------------
  // Convenience functions for modifiying the `modified$`.
  // Usage:
  //   command.execution() 
  //     .pipe(EditorContext.findFrom(someWidget).modifies)
  //
  static findFrom(contexted: ContextedDomainer) {
    return contexted.contexter.get(EditorContext)
  }
  modifies<T>():
    (execution: Observable<T>) => Observable<T>
  {
    return (execution: Observable<T>) =>
      execution.pipe(
        tap(p => this.modified$.next(p)),
      )

  }
}

export const NOOP = (t: any) => t

