import {Widget} from "@piccollage/cbjs";
import {CollageTileWidget} from "./collage_tile_widget";
import {BehaviorSubject} from "rxjs";
import {syncedPersonCollages$} from "../../app/firebase/person_firestore";
import {AppWidgetContext} from "../../app/ui/app_widget";
import {map} from "rxjs/operators";

export class TrashedCollagesWidget extends Widget {
  trashedCollages$: BehaviorSubject<CollageTileWidget[]>;

  constructor() {
    super();

    console.log("++++ TrashedCollagesWidget constructor")

    this.trashedCollages$ = new BehaviorSubject<CollageTileWidget[]>([]);

    const currentPerson = this.contexter.use(AppWidgetContext).appWidget.currentPerson$.value;

    this.connecting(
      syncedPersonCollages$(currentPerson!.id, true).pipe(
        map(trashedCollages => trashedCollages.map(
          collage => this.legate(() => new CollageTileWidget(collage))
        ))
      ),
      this.trashedCollages$
    )
  }
}