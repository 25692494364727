import * as React from "react";
import {AllCollagesWidget} from "../ui/all_collages_widget";
import styles from "./all_collages_view.module.scss";
import {CollageTileView} from "./collage_tile_view";
import {convertMouseToTouchEvent, TTap, useBehaviorSubject} from "@piccollage/cbjs";
import {Subject} from "rxjs";

export function AllCollagesView(props: {widget: AllCollagesWidget}) {
  const collageTileWidgets = useBehaviorSubject(props.widget.collageTileWidgets$);


  return (
    <div className={styles.AllCollagesView}>
      <NewCollageTile widget={props.widget} />
      {collageTileWidgets.map(widget =>
        <CollageTileView key={widget.collage.id} widget={widget}/>
        )}
    </div>);
}

function NewCollageTile(props: {widget: AllCollagesWidget}) {
  const onClick = (output$: Subject<TTap>) => (e: React.MouseEvent) => {
    return output$.next(new TTap(convertMouseToTouchEvent(e).touches[0]));
  }

  return (
    <div className={styles._CollageTile}>
      <div
        className={styles._content__newCollage}
        onClick={onClick(props.widget.tappedNewCollage$)}>
            <span>
              <div className={styles._header}>NEW</div>
              <div className={styles._plus}>+</div>
            </span>
      </div>
    </div>
  )
}
