import * as React from "react";
import {CreatorWidget} from "../ui/creator_widget";
import {EditorView} from "../../collage_editor/views/editor_view";
import {Subject} from "rxjs";
import {isMobile, useBehaviorSubject} from "@piccollage/cbjs";
import Icon from "../../toolkit/views/Icon";
import styles from "./creator_view.module.scss";
import {APP_VARIANT, APP_VARIANTS} from "../ui/app_widget";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


export function CreatorView(props: { widget: CreatorWidget }) {
  console.log("++++ CreatorView render")

  function onEvent(output$: Subject<boolean>) {
    return {
      onMouseDown:
        (e: React.BaseSyntheticEvent) => {
          e.preventDefault()
          e.stopPropagation()
          output$.next(true)
        }
    }
  }

  const size = isMobile ? 24 : 32;

  // ---- Sketch
  const curSketchColor = useBehaviorSubject(props.widget.editorWidget.curSketchColor$)


  return (
    <div>
      <EditorView widget={props.widget.editorWidget} />

      {/* =======================================================
          Use onMouseUp, bc onClick doesn't activate File dialog
          ======================================================= */}

      <div className={ styles.header }>

        <div className={ styles.sidebuttons } >

          <button
            { ...onEvent(props.widget.tappedBackButton$) }
            className={styles.backButton}
          >
            <ArrowBackIcon/>
          </button>

          <button
            { ...onEvent(props.widget.tappedUploadScrap$) }
            className={styles.addPhotoButton}
            data-tracking="FB - Add Photo">
            <Icon
              name="icon-add-photo"
              size={size}
              dataTracking="FB - Add Photo" />
          </button>

          <button
            { ...onEvent(props.widget.tappedUploadFace$) }
            className={styles.addFaceButton}
            data-tracking="FB - Add Face"
            style={{ display:  APP_VARIANT === APP_VARIANTS.APP_DEFAULT ? "block" : "none" }}
            >
            <Icon
              name="icon-add-face"
              size={size}
              dataTracking="FB - Add Face" />
          </button>

          <button
            { ...onEvent(props.widget.tappedBackground$) }
            className={styles.backgroundButton}
            data-tracking="FB - Background"
          >
            <FlipToBackIcon style={{ color: "#f66161" }} />
          </button>

          <button
            { ...onEvent(props.widget.tappedAddText$) }
            className={styles.addTextButton}
            data-tracking="FB - Add Text"
          >
            <TextFieldsIcon style={{ color: "#f66161" }} />
          </button>

          <button
            { ...onEvent(props.widget.tappedStickers$) }
            className={styles.stickersButton}
            data-tracking="FB - Stickers Toggle"
            // style={{ display:  APP_VARIANT === APP_VARIANTS.APP_DEFAULT ? "block" : "none" }}
          >
            <LoyaltyIcon style={{ color: "#f66161" }} />
          </button>

          {/* -------- Sketch button */}
          <button
            style={{
              backgroundColor: curSketchColor.code,
              display:  APP_VARIANT === APP_VARIANTS.APP_DEFAULT ? "block" : "none",
            }}
            className={styles._sketchColorButton}
            onClick={ e => {
              e.preventDefault()
              e.stopPropagation()
              props.widget.tappedSketchColor$.next(true)
            }}
          >
          </button>
        </div>

        <button
          { ...onEvent(props.widget.tappedSaveCollage$) }
          className={styles.shareButton}
          data-tracking="FB - Go to Share Page"
          style={{ display:  APP_VARIANT === APP_VARIANTS.APP_DEFAULT ? "block" : "none" }}
        >
          <Icon
            name="icon-share"
            size={size}
            dataTracking="FB - Go to Share Page"
          />
        </button>

        <button
          { ...onEvent(props.widget.tappedViewCollage$) }
          className={styles.viewButton}
          style={{ display:  APP_VARIANT === APP_VARIANTS.APP_PICCLASSROOM ? "block" : "none" }}
        >
          <VisibilityIcon />
        </button>


      </div>


    </div>
  )

}
