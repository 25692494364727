import {Scrap} from "../collage_editor/models/scrap";
import {Command, filtering, generateTimestamp, ID, promise$, Size} from "@piccollage/cbjs";
import {Collage, COLLAGE_URL, CollageContext} from "../collage_editor/models/collage";
import {Positioning} from "../toolkit/models/positioning";
import {firestoreCollageScrap, firestoreCollageScraps} from "./collage_firestore";
import {first, flatMap, map, tap} from "rxjs/operators";
import {EMPTY, Observable, of} from "rxjs";
import {Path, pathToArray} from "../toolkit/models/path";
import {TextScrap} from "../collage_editor/models/text_scrap";
import {TextSpec} from "../collage_editor/models/text_spec";
import {
  fieldify,
  fieldifyDeletable,
  fieldifyIf,
  fieldifyPoint,
  fieldifyTextSpec,
  fieldStroke
} from "./command_firestore_fieldify";
import {ImageScrap} from "../collage_editor/models/image_scrap";
import {Struct_ImageScrap, Struct_Positioning, Struct_Scrap, Struct_TextScrap} from "./command_firestore_struct";
import {Stroke} from "../toolkit/models/stroke";
import {SketchScrap} from "../collage_editor/models/sketch_scrap";

export function commandAddImageScrap(collage: Collage,
                                     positioning: Positioning,
                                     size: Size,
                                     url: COLLAGE_URL)
  : Command<ImageScrap, ImageScrap> {
  console.log("++++ commandAddImageScrap", collage, positioning, url)
  return commandAddScrap(
    `commandAddImageScrap(${collage.id}, ${positioning.toString()}, ${url.toString()})`,
    collage,
    positioning,
    size,
    {
      type: "image",
      image_source_url: url,
    })
}

export function commandAddTextScrap(collage: Collage,
                                    positioning: Positioning,
                                    size: Size,
                                    spec: TextSpec)
  : Command<TextScrap, TextScrap> {
  console.log("++++ commandAddTextScrap", collage, positioning, spec)
  return commandAddScrap(
    `commandAddTextScrap(${collage.id}, ${positioning.toString()})`,
    collage,
    positioning,
    size,
    {
      type: "text",
      ...fieldifyTextSpec('spec', spec),
    })
}

export function commandAddSketchScrap(collage: Collage,
                                      positioning: Positioning,
                                      size: Size,
                                      strokes: Stroke[])
  : Command<SketchScrap, SketchScrap> {

  console.log("++++ commandAddSketchScrap", collage, positioning, strokes)
  return commandAddScrap(
    `commandAddSketchScrap(${collage.id}, ${positioning.toString()})`,
    collage,
    positioning,
    size,
    {
      type: "sketch",
      ...fieldify('strokes', strokes.map(stroke => fieldStroke(stroke))),
      ...fieldify('strokes_timestamp', generateTimestamp())
    })
}

export function commandAddScrap<S extends Scrap, PROPS extends { [k: string]: any }>(
  description: string,
  collage: Collage,
  positioning: Positioning,
  size: Size,
  props: PROPS)
  : Command<S, S> {

  console.log("++++ commandAddScrap", collage, positioning, props)

  return new Command<S, S>(
    description,

    // ---- Execute
    (command: Command<S, S>) => {

      // ---- See if already created, then un-trash
      const scrap: S | undefined = command.memo
      if (scrap !== undefined)
        return updateScrapIsTrashed(scrap, false)

      // ---- Regular creation
      const struct: Struct_Scrap = {
        size_base: [size.width, size.height],
        positioning: {
          point: [positioning.point.x, positioning.point.y],
          rotation: positioning.rotation,
          scale: positioning.scale,
          z: positioning.z
        },
        ...props,
      }
      // ---- Create the Scrap on Firestore
      return promise$(() => firestoreCollageScraps(collage).add(struct)).pipe(
        // Find the newly created ImageScrap
        flatMap(docRef => {
          return collage.scraps$.pipe(
            filtering(scraps => scraps.find(scrap => scrap.id === docRef.id)),
            first(),
            map(_ => _ as S),
            // ---- Save it as the memo
            tap(scrap => {
              command.memo = scrap
            }),
          )
        })
      )
    },
    // ---- Unexecute
    (command: Command<S, S>) => {
      const scrap: S | undefined = command.memo
      if (scrap === undefined)
        return of()
      else
        return updateScrapIsTrashed(scrap, true)
    }
  )

}

export function commandUpdateScrapParent(scrap: Scrap, parentId: ID|null)
  : Command<Scrap, ID|null> {
  const collage = scrap.contexter.use(CollageContext).collage
  return new Command(
    `commandUpdateScrapParentId(${scrap.id}, ${parentId}`,
    command => {
      // ---- Remember state for the redo
      command.memo = scrap.parentId$.value
      return promise$(() =>
        firestoreCollageScrap(collage, scrap).update({
          ...fieldifyDeletable('parent_id', parentId as string|null)
        }))
        .pipe(map(_ => scrap))
      },
    command => promise$(() =>
      firestoreCollageScrap(collage, scrap).update({
        ...fieldifyDeletable('parent_id', command.memo as string|null)
      }))
      .pipe(map(_ => scrap))
  )
}

export function commandTrashScrap(scrap: Scrap, isTrashed: boolean)
  : Command<Scrap, Scrap> {
  return new Command(
    `commandTrashScrap(${scrap.id})`,
    command => updateScrapIsTrashed(scrap, isTrashed),
    command => updateScrapIsTrashed(scrap, !isTrashed)
  )
}

export function commandUpdateScrapSizeBase(collage: Collage, scrap: Scrap, size: Size)
  : Command<Size, Size> {
  return new Command(
    `commandUpdateScrapSizeBase(${collage.id}, ${scrap.id}, ${size.toString()})`,
    command => {
      command.memo = scrap.sizeBase$.value
      const struct: Partial<Struct_Scrap> = {
        size_base: [size.width, size.height],
      }
      console.log("++++ commandUpdateScrapSizeBase execute", size, struct)
      return promise$(() => firestoreCollageScrap(collage, scrap).update(struct)).pipe(
        map(_ => size)
      )
    },
    command => {
      const size = command.memo
      if (!size)
        return EMPTY
      const struct: Partial<Struct_Scrap> = {
        size_base: [size.width, size.height],
      }
      console.log("++++ commandUpdateScrapSizeBase unexecute", size, struct)
      return promise$(() => firestoreCollageScrap(collage, scrap).update(struct)).pipe(
        map(_ => size)
      )
    },
  )
}

export function commandUpdateScrapLinkUrl(scrap: Scrap,
                                          url: COLLAGE_URL|null)
  : Command<COLLAGE_URL|null, COLLAGE_URL|null>
{
  const collage = scrap.contexter.use(CollageContext).collage

  function update(url: COLLAGE_URL|null) {
    const struct: Partial<Struct_Scrap> = {
      ...fieldifyDeletable('link_url', url as string|null)
    }
    return promise$(() => firestoreCollageScrap(collage, scrap).update(struct)).pipe(
      map(_ => url),
    )
  }

  return new Command(
    `commandUpdateScrapLinkUrl(${scrap.id}, url})`,
    command => {
      command.memo = scrap.linkUrl$.value
      return update(url)
    },
    command => {
      const url = command.memo
      if (url === undefined)
        return EMPTY
      return update(url)
    },
  )

}

export function commandUpdateScrapPositioning(scrap: Scrap,
                                              p: Partial<Positioning>)
  : Command<Partial<Positioning>, Partial<Struct_Positioning>>
{

  const collage = scrap.contexter.use(CollageContext).collage

  function mapPositioning(p: Partial<Struct_Positioning>) {
    const ret: { [k: string]: any } = {}
    for (let k in p) {
      ret[ 'positioning.' + k] = (p as any)[k]
    }
    return ret
  }


  console.log("++++ commandUpdateScrapPositioning", scrap, p)
  return new Command(
    `commandUpdateScrapPositioning(${collage.id}, ${scrap.id})`,
    // ---- Execute
    command => {
      const prev = scrap.positioning$.value
      command.memo = {
        ...fieldifyIf(p.point,    fieldifyPoint("point",  prev.point)),
        ...fieldifyIf(p.rotation, fieldify("rotation",    prev.rotation)),
        ...fieldifyIf(p.scale,    fieldify("scale",       prev.scale)),
        ...fieldifyIf(p.z,        fieldify("z",          p.z)),
      }
      const struct: Partial<Struct_Positioning> = {
        ...fieldifyPoint("point", p.point),
        ...fieldify("rotation",   p.rotation),
        ...fieldify("scale",      p.scale),
        ...fieldify("z",          p.z),
      }
      console.log("++++ commandUpdateScrapPositioning execute data ", struct)
      return promise$(() => firestoreCollageScrap(collage, scrap)
        .update(mapPositioning(struct))).pipe(
          map(_ => p)
        )
    },
    // ---- Unexecute
    command => {
      console.log("++++ commandUpdateScrapPositioning unexecute", command.memo)
      const prev = command.memo
      if (!prev)
        return EMPTY
      return promise$(() => firestoreCollageScrap(collage, scrap)
        .update(mapPositioning(prev))).pipe(
          map(_ => p)
        )
    })
}

export function commandUpdateImageScrap<PROPS extends {
  image_source_url?:        COLLAGE_URL,
  clipping_path?:           Path   | null,
  clipping_path_timestamp?: number | null,
}>
(
  imageScrap: ImageScrap,
  props: PROPS
)
  : Command<ImageScrap, Partial<Struct_ImageScrap>>
{
  const collage = imageScrap.contexter.use(CollageContext).collage

  function update(d: Partial<Struct_ImageScrap>) {
    console.log("++++ commandUpdateImageScrap", imageScrap, d)
    return promise$(() => firestoreCollageScrap(collage, imageScrap).update(d)).pipe(
      map(_ => imageScrap),
    )
  }

  return new Command(
    `commandUpdateImageScrap(${imageScrap.id}, url})`,
    command => {
      // ---- Remember state of the redo
      command.memo = {
        ...( !!props.image_source_url &&
          fieldify('image_source_url', imageScrap.imageSourceUrl$.value) ),
        ...( !!props.clipping_path &&
          fieldifyDeletable('clipping_path', imageScrap.clippingPath$.value && pathToArray(imageScrap.clippingPath$.value)) ),
        ...( !!props.clipping_path_timestamp &&
          fieldifyDeletable('clipping_path_timestamp', imageScrap.clippingPathTimestamp$.value) ),
      }
      // ---- Update
      return update({
        ...fieldify('image_source_url', props.image_source_url),
        ...fieldifyDeletable('clipping_path',    props.clipping_path && pathToArray(props.clipping_path)),
        ...fieldifyDeletable('clipping_path_timestamp', props.clipping_path_timestamp),
      })
    },
    command => {
      // ---- Unexecute
      return command.memo ? update(command.memo) : EMPTY
    })
}

export function commandUpdateTextScrap(textScrap: TextScrap, textSpec: TextSpec)
  : Command<TextSpec, Partial<Struct_TextScrap>>
{
  const collage = textScrap.contexter.use(CollageContext).collage

  function update(s: Partial<Struct_TextScrap>) {
    console.log("++++ commandUpdateTextScrap", textScrap, s)
    return promise$(() => firestoreCollageScrap(collage, textScrap).update(s))
  }

  return new Command(
    `commandUpdateImageScrap(${textScrap.id}, url})`,
    command => {
      // ---- Remember state for the redo
      command.memo = {
        ...fieldifyTextSpec('spec', textScrap.spec$.value),
      }
      // ---- Update
      return update({
        ...fieldifyTextSpec('spec', textSpec),
      }).pipe(
        map(_ => textSpec)
      )
    },
    command => {
      // ---- Unexecute
      return !command.memo ? EMPTY :
        update(command.memo).pipe(
          map(_ => textSpec)
        )
    })

}

// ---- Local utility functions

function updateScrapIsTrashed<S extends Scrap>(scrap: S, isTrashed: boolean)
  : Observable<S> {
  const collage = scrap.contexter.use(CollageContext).collage
  return promise$(() =>
    firestoreCollageScraps(collage).doc(scrap.id as string)
      .update({is_trashed: isTrashed})
  ).pipe(map(_ => scrap))
}