import {Widget} from "@piccollage/cbjs";
import {BehaviorSubject, Subject} from "rxjs";
import {COLLAGE_URL} from "../../collage_editor/models/collage";

export class LinkEditorWidget extends Widget
{
  linkUrl$: BehaviorSubject<COLLAGE_URL|null>

  // ---- Inputs
  tappedDone$ = new Subject<boolean>()

  constructor(url: COLLAGE_URL|null) {
    super();
    this.linkUrl$      = new BehaviorSubject<COLLAGE_URL|null>(url)

  }


}