import * as React from "react";
import {RefObject} from "react";
import cx from "classnames";
import {StickerChooserWidget} from "../ui/sticker_chooser_widget";
import {ChooserItemWidget, ChooserWidget} from "../../toolkit/lib/chooser";
import {Sticker, StickerCategory} from "../../collage_editor/models/stickers";
import {GridListTile} from "@material-ui/core";
import {useBehaviorSubject, useRefStore} from "@piccollage/cbjs";
import styles from "./sticker_chooser_view.module.scss";
import {BehaviorSubject} from "rxjs";

// LEARNED: Consistent naming for CSS styles

export function StickerChooserView(props: { widget: StickerChooserWidget }) {
  console.log("++++ StickerChooserView render");

  // LEARNED: It's not about the Object/data organization, it is about
  // *segregating* functionality. Separation of concerns.
  // OOP is about grouping bits of data. FP is about "this does this",
  // "this does that".

  const ref = useRefStore(props.widget);

  return (
    <div ref={ref as RefObject<HTMLDivElement>}
         className={ cx(styles.StickerChooserView) } >
      <StickersView widget={props.widget.stickerChooser} />
      <StickerCategoryView
        widget={props.widget.categoryChooser}
        chosen$={props.widget.chosenCategory$}
      />
    </div>
  );
}

function StickersView(props: { widget: ChooserWidget<Sticker> }) {
  const items = useBehaviorSubject(props.widget.items$);
  console.log("++++ StickersView render", items)
  return (
    <div className={styles.StickersView}>
      {items.map(item => (
        <StickerView key={item.id} widget={item} />
      ))}
    </div>
  );
}

function StickerView(props: { widget: ChooserItemWidget<Sticker> }) {
  const { widget } = props;
  const ref = useRefStore(widget);

  return (
    <GridListTile
      ref={ref}
      onClick={ e => widget.tapped$.next(true) }
      className={styles.StickerView}
    >
      <img
        alt="sticker"
        src={(widget.model.image && widget.model.image.toString()) || undefined}
      />
    </GridListTile>
  );
}

function StickerCategoryView(props: {
  widget: ChooserWidget<StickerCategory>;
  chosen$: BehaviorSubject<StickerCategory | null>;
})
{
  const items = useBehaviorSubject(props.widget.items$)
  const chosen = useBehaviorSubject(props.chosen$)

  console.log("++++ StickerCategoryView render")

  return (
    <div className={ styles.StickerCategoryView } >
      { items.map(item => (

          <div key={ item.id } >
            <StickerCategoryContent
              title={item.model.title || item.model.name || ""}
              onClick={() => item.tapped$.next(true)}
              active={!!chosen && item.model.name === chosen.name}
              thumbnail={(item.model.thumbnail as string | null) || undefined}
            />
          </div>

      )) }
    </div>
  );
}

// LEARN: Design CSS for change, not hard-code width of Tabs

function StickerCategoryContent({
                      title,
                      active = false,
                      onClick,
                      thumbnail,
                    }: {
                      title?: string,
                      active?: boolean,
                      onClick?: () => any,
                      thumbnail?: string,
                    }) {


  console.log("++++ StickerCategoryContent render")
  return (
    <div
      onClick={onClick}
      className={ cx(styles.StickerCategoryContent, { [styles.StickerCategoryActive]: active })}
    >
      { thumbnail &&
        <img alt={ title } src={ thumbnail } />
      }
      <p className={ styles.StickerCategoryLabel }>
        { title }
      </p>
    </div>
  );
};

