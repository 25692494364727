import * as React from "react";
import {HomeWidget} from "../ui/home_widget";
import {Subject} from "rxjs";
import {convertMouseToTouchEvent, TTap} from "@piccollage/cbjs";
import styles from "./home_view_variant_1.module.scss";


export const HomeViewVariant1 = (props: { widget: HomeWidget }) => {
  const onClick = (output$: Subject<TTap>) => (e: React.MouseEvent) =>
    output$.next(new TTap(convertMouseToTouchEvent(e).touches[0]))

  return (
    <main className={styles.HomeViewVariant1}>
      <div
        className={styles.button_login}
        onClick={onClick(props.widget.loginTapped$)}
        data-tracking="FB - Get Started"
      >
        LOGIN
      </div>
    </main>
  )
}