import * as firebase from "firebase/app";
import {defer, Observable} from "rxjs/index";
import {Collage} from "../../collage_editor/models/collage";
import {AppWidget} from "../ui/app_widget";
import {flatMap, tap} from "rxjs/operators";
import {manipulateCaptureCollage} from "../../collage_editor/manipulators/manipulate_capture_collage";
import {EditorWidget} from "../../collage_editor/ui/editor_widget";
import {manipulateNavigateCollageShare} from "./manipulate_navigate_collage_share";
import {filterFirst, taplog} from "@piccollage/cbjs";

export function manipulateShareCollage(
  appWidget: AppWidget,
  editorWidget: EditorWidget,
): Observable<Collage> {
  console.log("++++ manipulateShareCollage");

  return defer(() => {
    appWidget.bulletin$.next("Capturing collage...")
    return manipulateCaptureCollage(editorWidget).pipe(
      taplog("++++ Captured collage"),
      tap(collage => firebase.analytics().logEvent("collage_shared", { collage_id: collage.id })),
      flatMap(collage =>
        manipulateNavigateCollageShare(appWidget, collage.id).pipe(
          filterFirst(),
          tap(collage => appWidget.bulletin$.next(null)),
        )
      ),
    )
  })

}
