import {EditorWidget} from "../ui/editor_widget";
import {EMPTY, of} from "rxjs";
import {flatMap} from "rxjs/operators";
import {taplog} from "@piccollage/cbjs";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateUndo(editorWidget: EditorWidget) {
  console.log("++++ manipulateUndo")
  return of(editorWidget.undoContext.popUndo()).pipe(
    taplog("++++ manipulateUndo command"),
    flatMap(command =>
      (command === undefined || command.unexecution === undefined) ?
        EMPTY :
        command
          .unexecution()
          .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
    ),

  )
}

