import {TextAlignment, TextSpec} from "../collage_editor/models/text_spec";
import * as _ from "lodash";
import {List} from "immutable";
import {Font, FontProtocol} from "../toolkit/models/font";
import {Positioning} from "../toolkit/models/positioning";
import {Color} from "../toolkit/models/color";
import {fieldToNumber, fieldToPoint, fieldToString, filterObject, includedOrDefault, Point} from "@piccollage/cbjs";
import {Stroke} from "../toolkit/models/stroke";

export function fieldToPositioning<D>(field: any, _default: D)
  : Positioning | D {
  if (typeof field == "object") {
    const point = fieldToPoint(field["point"], Point.ZERO)
    const rotation = fieldToNumber(field["rotation"], 0)
    const scale = fieldToNumber(field["scale"], 1)
    const z = fieldToNumber(field["z"], 0)
    return new Positioning(point, rotation, scale, z)
  }
  return _default
}

export function fieldToPath<D>(field: any, _default: D)
  : List<Point> | D {
  if (typeof field === "object" && typeof field.length === "number") {
    const a = field as Array<any>
    let aOut = List<Point>()
    for (let i = 0; i < a.length - 1; i += 2) {
      const x = a[i]
      const y = a[i + 1]
      if (typeof x === "number" && _.isFinite(x) &&
        typeof y === "number" && _.isFinite(y)) {
        aOut = aOut.push(new Point(x, y))
      }
      // else continue
    }
    return aOut
  }
  return _default
}

function fieldToFont<D>(field: any, _default: D)
  : Font | D {
  if (typeof field == "object") {
    const family = fieldToString(field["family"], undefined)
    const variation = fieldToString(field["variation"], null)
    const protocol = includedOrDefault(
                       fieldToString(field["protocol"], undefined),
                       Object.values(FontProtocol),
                       FontProtocol.web
                     ) as FontProtocol
    if (family)
      return new Font(family, family, protocol, variation)
  }
  return _default
}

function fieldToColor<D>(field: any, _default: D)
  : Color | D {
  if (typeof field == "string") {
    return new Color(field)
  }
  return _default
}

type HASH = { [k: string]: any }

export function fieldToTextSpec<D>(field: any, _default: D)
  : TextSpec | D {
  if (typeof field == "object") {
    const t =               fieldToString(field["t"], undefined)
    const font =            fieldToFont(field["font"], undefined)
    const alignment =       includedOrDefault(
                              fieldToString(field["alignment"], undefined),
                              Object.values(TextAlignment),
                              undefined
                              ) as TextAlignment|undefined
    const color =           fieldToColor(field["color"], undefined)
    const colorStroke =     fieldToColor(field["color_stroke"], undefined)
    const colorBackground = fieldToColor(field["color_background"], undefined)
    const widthStroke =     fieldToNumber(field["width_stroke"], undefined)

    return new TextSpec({
      ...filterObject({
        t,
        font,
        alignment,
        color,
        colorStroke,
        colorBackground,
        widthStroke,
      }, v => v  !== undefined)
    })
  }
  return _default
}

export function fieldToStrokes<D>(field: any, _default: D)
  : Array<Stroke> | D {
  if (typeof field === "object" && typeof field.length === "number") {
    const a = field as Array<any>
    let aOut = []
    for (let i = 0; i < a.length; i += 1) {
      const stroke = fieldToStroke(a[i], null)
      if (stroke)
        aOut.push(stroke)
    }
    return aOut
  }
  return _default
}

export function fieldToStroke<D>(field: any, _default: D)
  : Stroke|D
{
  if (typeof field == "object") {
    const color     = fieldToColor(field["color"], new Color("#000000"))
    const pathWidth = fieldToNumber(field["path_width"], 0.01)
    const path      = fieldToPath(field["path"], List())

    return new Stroke(color, pathWidth, path)
  }
  return _default

}
