import {BehaviorSubject} from "rxjs";
import {Widget} from "@piccollage/cbjs";
import {TextSpec} from "../../collage_editor/models/text_spec";

export abstract class TextPanelWidget extends Widget {
  abstract key: string
  spec$: BehaviorSubject<TextSpec>

  constructor(spec: TextSpec) {
    super();
    this.spec$ = new BehaviorSubject<TextSpec>(spec)

  }

}

