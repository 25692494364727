import {Context, ContextedDomainer, generateId, ID, Size} from "@piccollage/cbjs"
import {BehaviorSubject} from "rxjs"
import {Scrap} from "./scrap";
import {Z} from "../../toolkit/models/positioning";


export type  COLLAGE_URL = string
export const COLLAGE_URL_DEFAULT: COLLAGE_URL = ""
export const COLLAGE_DEFAULT_SIZE = new Size(2048, 2048)

export class CollageContext implements Context {
  // eslint-disable-next-line no-useless-constructor
  constructor(readonly collage: Collage) {}

}



export class Collage extends ContextedDomainer {

  // ---- Properties
  size$: BehaviorSubject<Size>
  scraps$: BehaviorSubject<Scrap[]> = new BehaviorSubject<Scrap[]>([])
  collageURL$ = new BehaviorSubject<COLLAGE_URL|null>(null)
  backgroundURL$ = new BehaviorSubject<COLLAGE_URL|null>(null)
  thumbnailURL$ = new BehaviorSubject<COLLAGE_URL|null>(null)
  ownerId: string | null
  isTrashed$: BehaviorSubject<Boolean | null>

  // ---- Lifecycle
  constructor(readonly id: ID = generateId(),
              size = COLLAGE_DEFAULT_SIZE,
              ownerId: string | null = null,
              isTrashed: boolean = false)
  {
    super()
    console.log("++++ Collage constructor")

    // ---- Setup context
    this.contexter.prepend(new CollageContext(this))

    // ---- Initialize
    this.size$ = new BehaviorSubject<Size>(size)
    this.isTrashed$ = new BehaviorSubject<Boolean | null>(isTrashed);
    this.ownerId = ownerId;


  }

  // ---- Scrap operations
  scrapZMax(): Z {
    const scraps = this.scraps$.value
    if (scraps.length === 0) return 0
    return Math.max(...scraps.map(s => s.positioning$.value.z))
  }

  scrapZMin(): Z {
    const scraps = this.scraps$.value
    if (scraps.length === 0) return 0
    return Math.min(...scraps.map(s => s.positioning$.value.z))
  }
}

