import {PathEditorWidget} from "../../path_editor/ui/path_editor_widget";
import {$P, Size, Widget} from "@piccollage/cbjs";
import {List} from "immutable";

export class TrialWidget extends Widget {
  pathEditorWidget: PathEditorWidget
  constructor() {
    super();
    console.log("++++ TrialWidget")
    const path = List([
      $P(0, 0),
      $P(0.4,0.8),
      $P(0.2,0.9),
    ])
    this.pathEditorWidget = new PathEditorWidget('/trial/bird.jpg',
                                                 new Size(3024, 4032),
                                                 path)
  }
}