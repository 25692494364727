import {Observable, zip} from "rxjs";
import {ScrapWidget} from "../ui/scrap_widget";
import {first, flatMap, map} from "rxjs/operators";
import {Point, Size, UndoContext} from "@piccollage/cbjs";
import {commandUpdateScrapPositioning} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulatePositioningForPadding(scrapWidget: ScrapWidget,
                                                toPoint: Point,
                                                toSize: Size)
  : Observable<ScrapWidget>
{
  return zip(
    scrapWidget.positioning.value$,
    scrapWidget.sizeBase$,
    scrapWidget.padding$,
  )
  .pipe(
    first(),
    flatMap(([pos, sizeBase, padding]) => {

      // ---- Adjust for padding or no padding
      const scaleTo  = toSize.width /
        ((padding ? padding.size.width : 1.0) * sizeBase.width)
      const scaleNew = Math.max(scaleTo, pos.scale)
        // Only make bigger, not smaller

      let center
      if (!padding) {
        center = toPoint
      }
      else {
        const pPadding = padding.center
          .subtract(Point.HALF).scale(sizeBase)
          .rotate(pos.rotation).scale(scaleNew)
        center = toPoint.subtract(pPadding)
      }

      // ---- Update the scrap
      return commandUpdateScrapPositioning(scrapWidget.scrap,
                                           { point: center, scale: scaleNew })
        .tap(c => scrapWidget.contexter.get(UndoContext)?.push(c))
        .execution()
        .pipe(EditorContext.findFrom(scrapWidget)?.modifies() || NOOP)
    }),
    map(_ => scrapWidget)
  )
}