import {TTap, Widget} from "@piccollage/cbjs";
import {Collage} from "../../collage_editor/models/collage";
import {manipulateNavigateCollageCreator} from "../../app/manipulators/manipulate_navigate_collage_creator";
import {AppWidgetContext} from "../../app/ui/app_widget";
import {BehaviorSubject, combineLatest, Subject} from "rxjs";
import {manipulateTrashCollage} from "../manipulators/manipulate_trash_collage";
import {manipulateUntrashCollage} from "../manipulators/manipulate_untrash_collage";
import {manipulateDeleteCollage} from "../manipulators/manipulate_delete_collage";
import {distinctUntilChanged, map} from "rxjs/operators";
import placeholder from "../../app/assets/images/no-image-placeholder.jpg";


export class CollageTileWidget extends Widget {
  tappedEdit$ = new Subject<TTap>();
  tappedTrash$ = new Subject<TTap>();
  tappedUntrash$ = new Subject<TTap>();
  tappedDeleteForever$ = new Subject<TTap>();

  imageURL$ = new BehaviorSubject<string|null>(null);

  isTrashed$ = new BehaviorSubject<Boolean | null>(null);

  constructor(readonly collage: Collage) {
    super();

    console.log("++++ CollageTileWidget constructor");

    this.connecting(collage.isTrashed$,
      this.isTrashed$);

    this.connecting(combineLatest(
      [collage.thumbnailURL$,
        collage.backgroundURL$,
        collage.collageURL$])
      .pipe(
        map(([thumbnailURL, collageURL, backgroundURL]) =>
          thumbnailURL  ||
          collageURL    ||
          backgroundURL ||
          placeholder
        ),
        distinctUntilChanged()
    ), this.imageURL$);

    this.triggering(this.tappedEdit$,
      _ =>
        manipulateNavigateCollageCreator(
          this.contexter.use(AppWidgetContext).appWidget,
          this.collage.id
        )
    )

    this.triggering(this.tappedTrash$,
      _ => manipulateTrashCollage(collage)
    )

    this.triggering(this.tappedUntrash$,
      _ =>
        manipulateUntrashCollage(collage)
    )

    this.triggering(this.tappedDeleteForever$,
      _ =>
        manipulateDeleteCollage(
          this.contexter.use(AppWidgetContext).appWidget,
          collage
        )
    )
  }
}
