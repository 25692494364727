import React from "react";
import {TextEditorWidget} from "../ui/text_editor_widget";
import {useBehaviorSubject} from "@piccollage/cbjs";
import styles from "./text_editor_view.module.scss";
import {Tab, Tabs} from "@material-ui/core";
import {TextPanelThumbnailView, TextPanelView} from "./text_panel_view";

export function TextEditorView(props: { widget: TextEditorWidget })
{
  const panel = useBehaviorSubject(props.widget.panelActive$)
  const panels = props.widget.panels
  const panelIndex = useBehaviorSubject(props.widget.panelIndex$)

  return (
    <div className={styles.TextEditorView}>

      {/* ---- Panel selector ---- */}
      <Tabs value={panelIndex} centered={true} indicatorColor="primary"
            onChange={ (_, index) => props.widget.panelIndex$.next(index) } >
        {
            panels.map((panel, index) => (
            <Tab key={panel.key} value={index} className={ styles.Tab }
                 label={(<TextPanelThumbnailView widget={panel} />)}
            />
          ))
        }
      </Tabs>

      {/* ---- Active panel ---- */}
      <TextPanelView widget={ panel } />

    </div>
  )
}