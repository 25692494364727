import {defer, Observable} from "rxjs/index"
import {Collage} from "../../collage_editor/models/collage";
import {AppWidget} from "../ui/app_widget";
import {flatMap, tap} from "rxjs/operators";
import {filterFirst} from "@piccollage/cbjs";
import {commandCloneCollage, commandLoadCollage} from "../../collage_firebase/command_firestore";
import {manipulateNavigateCollageCreator} from "./manipulate_navigate_collage_creator";
import {firestoreCollages} from "../../collage_firebase/collage_firestore";

export function manipulateStartNewCollage(appWidget: AppWidget)
  : Observable<Collage>
{
  console.log("++++ manipulateStartNewCollage")

  const template = firestoreCollages().doc('_TEMPLATE_0')

  // ---- Create a new Collage and edit it
  return defer(() => {
    appWidget.bulletin$.next("Creating new collage...")
    return commandCloneCollage(template, appWidget.currentPerson$.value).execution().pipe(
      flatMap(collageRef => commandLoadCollage(collageRef.id).execution()),
      filterFirst(),
      tap(collage => appWidget.bulletin$.next(null)),
      flatMap(collage => manipulateNavigateCollageCreator(appWidget, collage.id))
    )
  })
}
