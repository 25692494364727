import * as React from "react";
import styles from "./header_view.module.scss";
import {HeaderWidget} from "../ui/header_widget";
import {Subject} from "rxjs";
import {convertMouseToTouchEvent, TTap, useBehaviorSubject} from "@piccollage/cbjs";
import {SyntheticEvent} from "react";

export function HeaderView(props: {widget: HeaderWidget}) {
  const loginButtonWidget = useBehaviorSubject(props.widget.loginButtonWidget$);
  const logoutButtonWidget = useBehaviorSubject(props.widget.logoutButtonWidget$);

  return (
    <div className={styles.HeaderView}>
      <div className={styles._left}><span style={{fontWeight: 900, fontSize: 20, letterSpacing: 2}}>DECK THE CARD</span></div>
      <div className={styles._right}>
        {loginButtonWidget && <LoginButton widget={props.widget} />}
        {logoutButtonWidget && <LogoutButton widget={props.widget} />}
      </div>
    </div>
  )
}

export function LoginButton(props: {widget: HeaderWidget}) {
  const onClick = (output$: Subject<TTap>) => (e: React.MouseEvent) =>
    output$.next(new TTap(convertMouseToTouchEvent(e).touches[0]))

  return (
    <div
      className={styles._buttonLogin}
      onClick={onClick(props.widget.loginButtonTapped$)}
      data-tracking="FB - Get Started"
    >
      LOGIN
    </div>
  );
}

export function LogoutButton(props: {widget: HeaderWidget}) {
  const currentPerson = useBehaviorSubject(props.widget.currentPerson$);

  const onClick = (output$: Subject<TTap>) => (e: React.MouseEvent) =>
    output$.next(new TTap(convertMouseToTouchEvent(e).touches[0]));

  const handleBrokenImage = (event: SyntheticEvent) => {
    const target = event.target as HTMLImageElement;
    target.style.display = "none";
  }


  return (
    <div
      className={currentPerson?.imageUrl$.value ? styles._buttonLogout : styles._buttonLogout__noImage}
      onClick={onClick(props.widget.logoutButtonTapped$)}
      data-tracking="FB - Get Started"
    >
      {currentPerson && currentPerson.imageUrl$.value &&
        <img
            id={"img"}
            className={styles._image}
            src={
              currentPerson.imageUrl$.value === null ?
                undefined :
                currentPerson.imageUrl$.value
            }
            alt={"Profile"}
            onError={handleBrokenImage}
        />
      }
      LOGOUT
    </div>
  )
}
