import * as React from "react";
import {TrashedCollagesWidget} from "../ui/trashed_collages_widget";
import {useBehaviorSubject} from "@piccollage/cbjs";
import styles from "./trashed_collages_view.module.scss";
import {CollageTileView} from "./collage_tile_view";

export function TrashedCollagesView(props: {widget: TrashedCollagesWidget}) {
  const trashedCollages = useBehaviorSubject(props.widget.trashedCollages$);

  return (
    <div className={styles.TrashedCollagesView}>
      {trashedCollages.map(widget => <CollageTileView key={widget.collage.id} widget={widget}/>)}
    </div>
  );
}