import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import './index.css'
import * as firebase from 'firebase/app'
import {AppWidget} from "./app/ui/app_widget"
import {AppView} from "./app/views/app_view"
import * as React from 'react'

import {install as installResizeObserver} from 'resize-observer'
import {zip} from "rxjs";
import {promise$} from "@piccollage/cbjs";
import * as faceapi from "face-api.js";
import Cookies from "js-cookie";

if (typeof window['ResizeObserver' as any] !== 'function')
  installResizeObserver()

// ---------------------------------------------------------------------
// Initialize Firebase
// These are set via CircleCI "Contexts" (see https://ui.circleci.com/settings/organization/github/cardinalblue/contexts?return-to=https%3A%2F%2Fcircleci.com%2Fdashboard).
//
console.log("==== process.env", process.env)
const firebaseConfig = {
  apiKey:             process.env['REACT_APP_FIREBASE_API_KEY'],
  authDomain:         process.env['REACT_APP_FIREBASE_AUTH_DOMAIN'],
  databaseURL:        process.env['REACT_APP_FIREBASE_DATABASE_URL'],
  projectId:          process.env['REACT_APP_FIREBASE_PROJECT_ID'],
  storageBucket:      process.env['REACT_APP_FIREBASE_STORAGE_BUCKET'],
  messagingSenderId:  process.env['REACT_APP_FIREBASE_MESSAGING_SENDER_ID'],
  appId:              process.env['REACT_APP_FIREBASE_APP_ID'],
  measurementId:      process.env['REACT_APP_FIREBASE_MEASUREMENT_ID'],
}

firebase.initializeApp(firebaseConfig)
console.log("++++ Firebase initialized!")

// --------------------------------------------------------------------------
// Initialize tags for Google Tag Manager
//
const tagManagerArgs = {
    gtmId: 'GTM-N2FDJ7H'
}
TagManager.initialize(tagManagerArgs)

// --------------------------------------------------------------------------
// Initialize face-api.js
//
const FACEAPI_MODELS_URL = '/faceapi/weights'
export let faceapi_is_loaded = false
zip(
  promise$(() => faceapi.nets.ssdMobilenetv1.loadFromUri(FACEAPI_MODELS_URL)),
  promise$(() => faceapi.nets.faceLandmark68Net.loadFromUri(FACEAPI_MODELS_URL))
).subscribe(
  output => {
    console.log("++++ face-api.js loaded models", output)
    faceapi_is_loaded = true
  },
  error =>  console.error("Error loading face-api.js models", error)
)


// ---------------------------------------------------------------------
// Main render

const root = document.getElementById('root')
const path = (window && window.location.pathname) || '/'

// ---- Configuration
//
//      Go to the URL `deckthecard.com/config/face/true`
//      to set/unset `config-face` and other flags.
//
const segs = path.split('/')
if (segs[1] === 'config') {
  const [name, value] = segs.slice(2)
  const key = 'config-' + name
  Cookies.set(key, value)
  ReactDOM.render((
    <span>{ `Config ${key} => "${Cookies.get(key)}"` }</span>
  ), root)
}
else {
  // ---- Main rendering
  console.log("++++ index path", path)
  ReactDOM.render((
    <AppView widget={ new AppWidget(path) }/>
  ), root)
}



// ---------------------------------------------------------------------
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()