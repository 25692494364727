import {Stroke} from "../models/stroke";
import React, {RefObject, useEffect, useRef} from "react";
import {Size, useBehaviorSubject} from "@piccollage/cbjs";
import styles from "./stroke_view.module.scss"
import cx from "classnames";
import {BehaviorSubject} from "rxjs";


export function StrokeView(props: { stroke: Stroke,
                                    size$: BehaviorSubject<Size>,
                                    className?: string })
{
  const canvasRef: RefObject<HTMLCanvasElement> = useRef(null)

  const size = useBehaviorSubject(props.size$)
  const stroke = props.stroke

  useEffect(() => {
    const canvas: HTMLCanvasElement|null = canvasRef.current
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, size.width, size.height)

        if (path) {

          // ---- Setup line
          ctx.beginPath()
          ctx.lineWidth   = size.width * pathWidth
          ctx.lineJoin    = "round"
          ctx.lineCap     = "round"
          ctx.strokeStyle = color.code
          ctx.fillStyle   = color.code

          // ---- Draw path
          path.forEach((p, i) => {
            const n = p.scale(size)
            if (i === 0)
              ctx.moveTo(n.x, n.y)
            else
              ctx.lineTo(n.x, n.y)
          })

          ctx.stroke()
        }
      }
    }
  })

  if (!stroke)
    return null

  const { color, path, pathWidth } = stroke
  return (
    <canvas ref={canvasRef} className={cx(props.className, styles.canvas)}
            width={size.width} height={size.height}
    >
    </canvas>
  )

}