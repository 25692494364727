import firebase from 'firebase'
import {defer, Observable} from 'rxjs'
import {finalize, takeUntil} from 'rxjs/operators'
import {filterDefined, firebaseCurrentUser$, LoginWidget, taplog} from "@piccollage/cbjs"
import {AppWidget} from "../ui/app_widget";

export function manipulateLogin(appWidget: AppWidget)
  : Observable<firebase.User>
{
  return defer(() => {
    console.log("++++ manipulate login")

    // ---- Create the login widget
    const loginWidget = new LoginWidget()
    appWidget.loginWidget$.next(loginWidget)

    // ---- Take until we have a user
    const currentUser$ = firebaseCurrentUser$().pipe(
      filterDefined()
    )

    return loginWidget.loginRequest.firebaseUser$.pipe(
      taplog("++++ manipulate login firebaseUser$"),
      // ---- Remove if for some external reason or we already have a user
      takeUntil(currentUser$),
      // ---- Remove the loginWidget when done
      finalize(() => appWidget.loginWidget$.next(null))
    )
  })
}

