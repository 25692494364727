import {EditorWidget, mapScrapToScrapWidget} from "../ui/editor_widget"
import {merge, Observable, of} from "rxjs"
import {finalize, flatMap, map, tap} from "rxjs/operators"
import {commandAddImageScrap} from "../../collage_firebase/command_firestore_scrap"
import {manageProgressWidget} from "../../toolkit/manipulators/manipulators"
import {Positioning} from "../../toolkit/models/positioning";
import {firestoreUploadImage, Point} from "@piccollage/cbjs";
import {ImageScrapWidget} from "../ui/image_scrap_widget";
import {manipulateRecognizeFaces} from "./manipulate_recognize_faces";
import {SCRAP_DEFAULT_BASE_SIZE} from "../models/scrap";
import {commandUpdateCollage} from "../../collage_firebase/command_firestore";
import {Collage} from "../models/collage";
import {EditorContext, NOOP} from "../ui/editor_context";

const FIRESTORE_SCRAPS_FOLDER   = 'scraps'

// LEARN: Make sure nothing happens until Observable is subscribed to.
// LEARN: if adding `complete()` below it removes the widget as soon as
// it completes (because subscription removes, but we don't want to do that
// until we get the files or it stops.

export function manipulateAddFiles(editorWidget: EditorWidget,
                                   files: File[],
                                   isBackground: boolean = false,
                                   p: Positioning|Point|null = null,
                                   isFace: boolean = false
)
  : Observable<ImageScrapWidget|Collage>
{

  console.log("++++ manipulateAddFiles", p, files)

  const collage = editorWidget.collage
  const backdropP = editorWidget.backdropWidget.positioning.value$.value
  const positioning =
    p instanceof Positioning ? p :
    new Positioning(p || collage.size$.value.scale(0.5),
      0,
      1/backdropP.scale,
      collage.scrapZMax() + 1
    )
  console.log("++++ manipulateUploadImages positioningCollage", positioning)

  // ---- Progress
  const pointProgress  = backdropP.point.add(
    positioning.point.scale(backdropP.scale).rotate(backdropP.rotation)
  )
  const [progressStart, progressStop] = manageProgressWidget(editorWidget, pointProgress)

  // ---- Create and setup the UploadWidget and get file$ from it
  return of(files).pipe(

    // ---- Track starting when we get the files
    tap(progressStart),

    // ---- Upload all the files and get a sequence of URLs
    flatMap(files =>
      merge(...files.map(file =>
        firestoreUploadImage(file, FIRESTORE_SCRAPS_FOLDER)
      ))
    ),

    flatMap(url => {

      if (isBackground) {

        // ---- Update the background
        return commandUpdateCollage(collage, {background_url: url})
          .push(editorWidget.undoContext)
          .execution()
          .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
      }
      else {

        // ---- Command to add ImageScrap with url
        return commandAddImageScrap(collage,
          positioning,
          SCRAP_DEFAULT_BASE_SIZE,
          url)
          .push(editorWidget.undoContext)
          .execution()
          .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
          .pipe(

            // ---- Map to ImageScrapWidget
            mapScrapToScrapWidget<ImageScrapWidget>(editorWidget),

            // ---- Process faces if needed
            isFace ?
              flatMap(imageScrapWidget => manipulateRecognizeFaces(imageScrapWidget)) :
              map(_ => _),

            // ---- Manipulate the widget so it gets the Magic Dot
            tap(imageScrapWidget =>
              editorWidget.currentlyManipulatedScrap$.next(of(imageScrapWidget))),
          )
      }
    }),


    // ---- Done!
    finalize(progressStop)
  )

}

