import React from "react";
import styles from "./text_panel_view.module.scss";
import {Button, Slider} from "@material-ui/core";
import {useBehaviorSubject} from "@piccollage/cbjs";
import cx from "classnames";

import {TextAlignment} from "../../collage_editor/models/text_spec";

import {TextAlignmentPanelWidget} from "../ui/text_alignment_panel_widget";
import {TextPanelWidget} from "../ui/text_panel_widget";
import {TextEntryPanelWidget} from "../ui/text_entry_panel_widget";
import {TextFontPanelWidget} from "../ui/text_font_panel_widget";
import {
  TextColorBackgroundPanelWidget,
  TextColorPanelWidget,
  TextStrokePanelWidget
} from "../ui/text_color_panel_widgets";
import {TextEntryPanelView} from "./text_entry_panel_view";
import {TextFontPanelView} from "./text_font_panel_view";

import FontDownload from "@material-ui/icons/FontDownload";
import Keyboard from "@material-ui/icons/Keyboard";
import Note from "@material-ui/icons/Note";
import FontDownloadOutlined from "@material-ui/icons/FontDownloadOutlined";
import FormatAlignJustify from "@material-ui/icons/FormatAlignJustify";
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRight from "@material-ui/icons/FormatAlignRight";
import FormatAlignCenter from "@material-ui/icons/FormatAlignCenter";
import TextFields from "@material-ui/icons/TextFields";
import {Color} from "../../toolkit/models/color";
import {BehaviorSubject} from "rxjs";

export function TextPanelThumbnailView(props: { widget: TextPanelWidget })
{
  const panel = props.widget
  return panel instanceof TextEntryPanelWidget ?
           (<Keyboard />) :
         panel instanceof TextFontPanelWidget ?
           (<TextFields />) :
         panel instanceof TextAlignmentPanelWidget ?
           textAlignmentToIcon(panel.alignment$.value) :
         panel instanceof TextColorPanelWidget ?
           (<FontDownload />) :
         panel instanceof TextStrokePanelWidget ?
           (<FontDownloadOutlined />) :
         panel instanceof TextColorBackgroundPanelWidget ?
           (<Note />) :
         (<span>?</span>)
}

export function TextPanelView(props: { widget: TextPanelWidget })
{
  const panel = props.widget
  return panel instanceof TextEntryPanelWidget ?
           (<TextEntryPanelView widget={ panel }/>) :
         panel instanceof TextFontPanelWidget ?
           (<TextFontPanelView widget={ panel }/>) :
         panel instanceof TextAlignmentPanelWidget ?
           (<TextAlignmentPanelView widget={ panel }/>) :
         panel instanceof TextColorPanelWidget ?
           (<TextColorPanelView widget={ panel }/>) :
         panel instanceof TextStrokePanelWidget ?
           (<TextStrokePanelView widget={ panel }/>) :
         panel instanceof TextColorBackgroundPanelWidget ?
           (<TextColorPanelView widget={ panel }/>) :
         (<span>?</span>)
}

function textAlignmentToIcon(alignment: TextAlignment) {
  return alignment === TextAlignment.Left ?
           (<FormatAlignLeft />) :
         alignment === TextAlignment.Right ?
           (<FormatAlignRight />) :
         alignment === TextAlignment.Center ?
           (<FormatAlignCenter />) :
         <FormatAlignJustify />
}
export function TextAlignmentPanelView(props: { widget: TextAlignmentPanelWidget })
{
  const panel = props.widget
  const alignment = useBehaviorSubject(panel.alignment$)
  const onClick = (alignment: TextAlignment) => (_: any) => panel.alignment$.next(alignment)
  const alignments = [
    TextAlignment.Left,
    TextAlignment.Center,
    TextAlignment.Right
  ]
  return (
    <div className={styles.TextAlignmentPanelView}>
      {
        alignments.map(a =>
          (<Button onClick={ onClick(a) }
                   key={ a }
                   color={ alignment === a   ? "primary" : "default" }>
            { textAlignmentToIcon(a) }
          </Button>)
        )
      }
    </div>
  )
}

export function SliderPanelView(props: { number$: BehaviorSubject<number> }) {
  const n = useBehaviorSubject(props.number$)
  const scale = 100
  return (
    <div className={ styles.Slider }>
      <Slider orientation="vertical"
              onChange={ (_, value) => {
                props.number$.next((value as number) / scale)
              }}
              value={ n * scale }
      />
    </div>
  )
}

export function TextStrokePanelView(props: { widget: TextStrokePanelWidget })
{
  const panel = props.widget
  return (
    <div className={ styles.TextStrokePanelView }>
      <SliderPanelView number$={ panel.width$ } />
      <TextColorPanelView widget={ panel }/>
    </div>
  )
}
export function TextColorPanelView(props: { widget: TextColorPanelWidget })
{
  const color = useBehaviorSubject(props.widget.color$)
  const colors = Array(props.widget.colorsLength())
    .fill(null)
    .map((_, i) => props.widget.color(i))
  const onClick = (color: Color|null) => (_: any) => props.widget.color$.next(color)
  const colorWhite = new Color('#ffffff')
  const isSelected = (c: Color|null) => c === color || (color && color.isEqual(c))


  return (
    <div className={styles.TextColorPanelView}>
      {[
        (<div key={ 'null' }
              className={ cx(styles.color, styles.checkered, isSelected(null) && styles.isSelected) }
              onClick={ onClick(null)
              }
        />),
        (<div key={ 'pinned_' + colorWhite.code }
              className={ cx(styles.color, isSelected(colorWhite) && styles.isSelected) }
              onClick={ onClick(colorWhite) }
        />),
        ...colors.map(color => (
          <div key={ color.code }
               style={{ backgroundColor: color.code }}
               className={ cx(styles.color, isSelected(color) && styles.isSelected) }
               onClick={ onClick(color) }
          />
        ))

      ]}
    </div>
  )
}