import {TTap, Widget} from "@piccollage/cbjs";
import {BehaviorSubject, Subject} from "rxjs";
import {manipulateCurrentTab} from "../manipulators/manipulate_current_tab";
import {MyCollagesWidget} from "./my_collages_widget";
import {AllCollagesWidget} from "./all_collages_widget";
import {TrashedCollagesWidget} from "./trashed_collages_widget";

export class SideBarWidget extends Widget {

  // ---- Inputs
  tappedAllCollagesTab$ = new Subject<TTap>();
  tappedTrashTab$ = new Subject<TTap>();
  tappedFavoritesTab$ = new Subject<TTap>();
  currentTabWidget$ = new BehaviorSubject<Widget | null>(null);

  // ---- Outputs

  constructor(myCollagesWidget: MyCollagesWidget) {
    super();

    this.connecting(
      myCollagesWidget.currentTabWidget$,
      this.currentTabWidget$
    )

    this.triggering(this.tappedAllCollagesTab$,
      _ => manipulateCurrentTab(
        myCollagesWidget,
        myCollagesWidget.legate(() => new AllCollagesWidget())
      )
    )

    this.triggering(this.tappedTrashTab$,
      _ => manipulateCurrentTab(
        myCollagesWidget,
        myCollagesWidget.legate(() => new TrashedCollagesWidget())
      )
    )

    // this.triggering(this.tappedFavoritesTab$,
    //   _ =>
    //     )
    // )
  }


}