import * as React from "react";
import {BehaviorSubject, fromEvent} from "rxjs";
import {LoginView, useBehaviorSubject} from "@piccollage/cbjs";
import {APP_VARIANT, APP_VARIANTS, AppWidget} from "../ui/app_widget";
import {ShareWidget} from "../ui/share_widget";
import {CreatorWidget} from "../ui/creator_widget";
import {CreatorView} from "./creator_view";
import ShareView from "./share_view";
import HomeView from "./home_view";
import './styles/global.scss'
import {CircularProgress, createMuiTheme, Dialog, DialogTitle} from "@material-ui/core";
import {HomeWidget} from "../ui/home_widget";
import {TrialWidget} from "../ui/trial_widget";
import {TrialView} from "./trial_view";
import {ThemeProvider} from '@material-ui/styles';
import {ViewerWidget} from "../../collage_editor/ui/viewer_widget";
import {ViewerView} from "./viewer_view";
import {HomeViewVariant1} from "./home_view_variant_1";
import {HeaderView} from "./header_view";
import {MyCollagesView} from "../../my_collages/views/my_collages_view";
import {MyCollagesWidget} from "../../my_collages/ui/my_collages_widget";
import {AuthOverlayView} from "./auth_overlay_view";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f66161',
      contrastText: '#eeeeee',
    },
    secondary: {
      main: '#eeeeee',
      contrastText: '#f66161',
    },
    action: {
      // active: '#ff00ff',
      // hover: '#00ff00',
      // selected: '#0000ff',
      // disabled: '#333333',
      hoverOpacity: 0.5,
      disabledBackground: '#aaaaaa',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }

})


export function AppView(props: { widget: AppWidget }) {

  // ---- Top widget state
  const topWidget = useBehaviorSubject(props.widget.topWidget$);
  const loginWidget = useBehaviorSubject(props.widget.loginWidget$)
  const headerWidget = useBehaviorSubject(props.widget.headerWidget$)

  console.log("++++ AppView render - topWidget", topWidget)

  // ---- Update browser's history with path
  React.useEffect(() => {
    const subs = props.widget.path$.subscribe(path => {
      console.log("++++ AppView render path$", path)
      if (window.location.pathname !== path)
        window.history.pushState({}, "PicCollage", path)
    })
    return () => subs.unsubscribe()
  });

  // ----- Update path from browser's location
  React.useEffect(() => {
    const subs = fromEvent(window, "popstate").subscribe(e => {
      console.log("++++ AppView render popstate event", e)
      props.widget.path$.next(window.location.pathname)
    })
    return () => subs.unsubscribe()
  });

  return (
    <ThemeProvider theme={theme}>
      <div>

        <AuthOverlayView widget={ props.widget.authOverlayWidget } />

        {
          headerWidget &&
          <HeaderView widget={ headerWidget } />
        }

        <BulletinView bulletin$={ props.widget.bulletin$ } />
        {
          (topWidget instanceof HomeWidget) ? (
            APP_VARIANT === APP_VARIANTS.APP_PICCLASSROOM ?
              (<HomeViewVariant1 widget={topWidget}/>) :
              (<HomeView widget={topWidget}/>)
          ) :
          (topWidget instanceof MyCollagesWidget) ?
            (<MyCollagesView widget={topWidget}/>) :
          (topWidget instanceof CreatorWidget) ?
            (<CreatorView widget={topWidget}/>) :
          (topWidget instanceof ViewerWidget) ?
            (<ViewerView widget={topWidget}/>) :
          (topWidget instanceof ShareWidget) ?
            (<ShareView widget={topWidget}/>) :
          (topWidget instanceof TrialWidget) ?
            (<TrialView widget={topWidget}/>) :
          null
        }
      </div>

      {/* ============ Floating widgets ============ */}
      { loginWidget && (
        <Dialog open={true} onBackdropClick={_ => loginWidget && loginWidget.close()}>
          <LoginView widget={loginWidget} />
        </Dialog>
      ) }
    </ThemeProvider>
  );
}

export function BulletinView(props: { bulletin$: BehaviorSubject<string|null> })
{
  const bulletin = useBehaviorSubject(props.bulletin$)
  return (
    <div>
      {
        bulletin && (
          <Dialog open={true}>
            <DialogTitle>
              { bulletin }
            </DialogTitle>
            <div style={{ margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress size={60} />
            </div>
          </Dialog>
        )
      }
    </div>
  )
}
