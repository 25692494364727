import * as _ from "lodash"
import {of} from "rxjs/index"
import {filter, flatMap, map, shareReplay, tap} from "rxjs/operators"
import {
  lastOrEmpty,
  Point,
  postponeUntil,
  scan2,
  tapScan,
  Transform,
  transformsFromGestureWithEvents,
  TTouchEvent,
  TTouchGesture
} from "@piccollage/cbjs"
import {Sticker} from "../models/stickers";
import {Collage} from "../models/collage";
import {FloaterWidget} from "../../toolkit/ui/floater_widget";
import {EditorWidget} from "../ui/editor_widget";
import {BackdropWidget} from "../ui/backdrop_widget";
import {StickerChooserWidget} from "../../sticker_chooser/ui/sticker_chooser_widget";
import {repositionPoint} from "../../toolkit/models/positioning_util";
import {manipulateAddSticker} from "./manipulate_add_sticker";
import {ImageScrapWidget} from "../ui/image_scrap_widget";
import {SCRAP_DEFAULT_BASE_SIZE} from "../models/scrap";
import {Positioning} from "../../toolkit/models/positioning";


const MINIMUM_STICKER_VERTICAL = 40

export function manipulateNewStickerTransform(gesture: TTouchGesture,
                                              editorWidget: EditorWidget,
                                              collage: Collage,
                                              sticker: Sticker)
//  : Observable<ImageScrapWidget|Collage>
{

  console.log("++++ manipulateNewStickerTransform", gesture, sticker)

  function createFloater(touchEvent1: TTouchEvent) {
    console.log("++++ manipulateNewStickerTransform creating FloaterWidget")
    const url = sticker.image.toString()
    const size = SCRAP_DEFAULT_BASE_SIZE
    const center = Point.centroid(...touchEvent1.touches.map(transform => transform.point))
    const positioning = new Positioning(center)
    const floaterWidget = editorWidget.legate(() =>
      new FloaterWidget(url, size, positioning)
    )

    // ---- Add the shade while dragging
    floaterWidget.shade$.next(true)

    // ---- Setup the floater
    editorWidget.floaterWidget$.next(floaterWidget)

    return floaterWidget
  }

  // LEARN: How to pass down "state" by passing down POJOs

  return gesture.pipe(

    // ---- Commit if not over the StickerChooser (if any touches are on
    //      top of the chooser)
    tap(touchEvent => {
      if (!touchEvent.touches.flatMap(t => t.targetings)
          .find(t => t.target instanceof StickerChooserWidget)) {
        TTouchEvent.commit(touchEvent)
      }
    }),

    // ---- Calculate transforms
    transformsFromGestureWithEvents(),

    // ---- Create and maintain the "floater" widget
    scan2({}, (state: { transform?: Transform,
                        touchEvent1?: TTouchEvent,
                        floaterWidget?: FloaterWidget },
               [transform, touchEvent1]) => {
      if (!state.floaterWidget)
        state.floaterWidget = createFloater(touchEvent1)
      return { ...state, transform, touchEvent1 }
    }),
    shareReplay(),

    // ---- Check total accumulated transform
    tapScan(Point.ZERO, (p: Point, { transform, floaterWidget, touchEvent1 }) => {
      const move = p.add(transform!!.move)
      if (floaterWidget) {
        const isVertical = Math.abs(move.y) > MINIMUM_STICKER_VERTICAL
        const isBackdrop = !!touchEvent1.touches
          .flatMap(t => t.targetings)
          .find(t => t.target instanceof BackdropWidget)
        floaterWidget.isVisible$.next(isVertical || isBackdrop)
      }
      return move
    }),

    // ---- Convert to positioning
    map(state => {
      const pCur = state.floaterWidget!!.positioning$.value
      const repivot = state.transform.repivot(Point.ZERO, pCur.point)
      const positioning = pCur.transform(repivot)
      state.floaterWidget!!.positioning$.next(positioning)
      return { ...state, positioning }
    }),

    // ---- Finally, when gesture is over
    lastOrEmpty(),

    // ---- Create the new Scrap
    flatMap(state => {

      // ---- Filter touches that are all outside Collage
      //      (for now, only check that at least one finger is on top of the
      //      backdrop). Later on can do like a corner area check.
      //
      const targets = _.flatMap(state.touchEvent1.touches, t => t.targetings.map(t => t.target))
      const onBackdrop =
        targets.some(t => t instanceof BackdropWidget)
        // && targets.every(t => !(t instanceof StickerChooserWidget))

      // ---- Remove shade
      const floaterWidget = state.floaterWidget
      floaterWidget && floaterWidget.shade$.next(false)

      // ----- Check if event was inside the Backdrop
      if (onBackdrop) {

        // ---- Convert positioning from the offset by the backdrop and Z to max
        const backdrop = editorWidget.backdropWidget.positioning.value$.value
        const p = state.positioning
        const pos = new Positioning(
          repositionPoint(p.point, backdrop),
          p.rotation - backdrop.rotation,
          p.scale / backdrop.scale,
          collage.scrapZMax() + 1,
        )

        // ---- Add the new sticker
        return manipulateAddSticker(editorWidget, collage, sticker, pos).pipe(
          map(model => ({ ...state, model }) )
        )
      }
      else {
        return of({ ...state, model: null })
      }
    }),

    // ---- Need to insert a delay if we added a new Sticker (but not if Collage)
    flatMap(state => {
      const model = state.model
      if (model && (model instanceof ImageScrapWidget))
        return of(state).pipe(
          postponeUntil(model.isLoaded$.pipe(filter(_ => _)))
        )
      return of(state)
    }),

    // ---- Cleanup the FloaterWidget (do after delay so less blink)
    tap(state => {
      if (editorWidget.floaterWidget$.value !== state.floaterWidget)
        console.error("++++ Accidental floaterWidget state", state)
      editorWidget.floaterWidget$.next(null)
    }),
  )
}

