import {TrialWidget} from "../ui/trial_widget";
import {Dialog} from "@material-ui/core";
import {PathEditorView} from "../../path_editor/views/path_editor_view";
import * as React from "react";

export function TrialView(props: { widget: TrialWidget }) {
  console.log("++++ TrialView render")
  return (
    <Dialog fullScreen open={true}
            onClose={ _ => props.widget.pathEditorWidget.tappedDone$.next(true) }>
      <PathEditorView widget={ props.widget.pathEditorWidget }/>
    </Dialog>
  )
}