import {Observable, of} from "rxjs";
import {ChooserItem} from "../../toolkit/lib/chooser";
import {ID} from "@piccollage/cbjs";

export interface StickerRepo {
  categories$(): Observable<StickerCategory[]>
}

export class StickerCategory implements ChooserItem {

  // ---- ChooserItem implementation
  get id()    { return this.name as ID }

  // LEARN: Change `stickers$` from a `BehaviorSubject` to an `Observable` so we
  // can fetch it on demand.
  // LEARN: Easy way to do lazy loading!
  // LEARN: Have to share/cache

  // eslint-disable-next-line no-useless-constructor
  constructor(readonly name: string,
              readonly stickers$: Observable<Sticker[]>,
              readonly title: string|null = null,
              readonly thumbnail: URL|null = null,
              readonly priority: number = 0,
              ) {
  }
}

export class Sticker implements ChooserItem {

  // ---- ChooserItem implementation
  get id()    { return this.name as ID }
  get title() { return this.name }

// eslint-disable-next-line no-useless-constructor
  constructor(readonly name: string,
              readonly image: URL,
              readonly thumbnail: URL|null = null) {

  }
}

export class TestStickerRepo implements StickerRepo {
  categories$(): Observable<StickerCategory[]> {
    return of([
      new StickerCategory("People", of([
        new Sticker("sticker1", new URL("https://firebasestorage.googleapis.com/v0/b/pic-collage-live.appspot.com/o/stickers%2Fpeople%2Fp2.png?alt=media&token=92476f0a-5b52-4978-8f8c-4db8aa150790")),
        new Sticker("sticker2", new URL("https://firebasestorage.googleapis.com/v0/b/pic-collage-live.appspot.com/o/stickers%2Fpeople%2Fp3.png?alt=media&token=f9646b8d-5027-4ecc-8b11-7b2d60d45e84")),
        ])),
      new StickerCategory("Furniture", of([
        new Sticker("sticker1", new URL("https://firebasestorage.googleapis.com/v0/b/pic-collage-live.appspot.com/o/stickers%2Ffurniture%2Fst_bonvoyage_20_beachchair.png?alt=media&token=4e3acac5-520a-4590-8ce7-66ff5f778b9f")),
        new Sticker("sticker2", new URL("https://firebasestorage.googleapis.com/v0/b/pic-collage-live.appspot.com/o/stickers%2Ffurniture%2Fst_treatyoself_13_chair.png?alt=media&token=b22a5541-eacb-41c1-b66b-9ee4773175ad")),
        ])),
    ])
  }

}
