import {BehaviorSubject, combineLatest} from "rxjs"
import {map, pairwise} from "rxjs/operators"
import {
  Animatorer,
  BaseAnimation,
  convertPointFromBoundingBox,
  MultiAnimation,
  Point,
  Rect,
  Size,
  Transform,
  Widget
} from "@piccollage/cbjs"
import {DotWidget} from "./dot_widget"
import {Positioning} from "../../toolkit/models/positioning";
import {Scrap} from "../models/scrap";
import {EditorContext} from "./editor_context";
import {COLLAGE_URL} from "../models/collage";

export class MagicDotWidget   extends DotWidget {}
export class TrashDotWidget   extends DotWidget {}
export class PanningDotWidget extends DotWidget {}
export class LinkDotWidget    extends DotWidget {}

export class ScrapWidget extends Widget {

  // ---- Output properties
  sizeBase$     = new BehaviorSubject(Size.ZERO)          // Size from the Scrap
  positioning   : Animatorer<Positioning>
  padding$      = new BehaviorSubject<Rect|null>(null)
  linkUrl$      = new BehaviorSubject<COLLAGE_URL|null>(null)
  z$            = new BehaviorSubject(0);  // Convenience Z derived from Positioning

  captureMode$  = new BehaviorSubject(false)
  isFocused$    = new BehaviorSubject<boolean>(false)

  dotWidgets$   = new BehaviorSubject<DotWidget[]>([])

  // ---- Input properties
  sizeActual$   = new BehaviorSubject(Size.ZERO)          // Size received from the view

  // ---- Parenthood
  parentWidget$ = new BehaviorSubject<ScrapWidget|null>(null)

  // ---- Overridable
  protected createDotWidgets(): DotWidget[] {
    return this.legate(() => [
      new MagicDotWidget(this),
      new TrashDotWidget(this),
      new LinkDotWidget(this),
      // new PanningDotWidget(this),    // Panning dot is useful if doing stroke by
                                        // default, but remove if doing panning by default
                                        // on Scraps.
    ])
  }

  // ---- Lifecycle
  constructor(readonly scrap: Scrap) {
    super()
    console.log("++++ ScrapWidget constructor")

    const editorContext = this.contexter.get(EditorContext)

    // ---- Connect to model properties
    this.connecting(this.scrap.sizeBase$, this.sizeBase$)
    this.connecting(this.scrap.padding$, this.padding$)
    this.connecting(this.scrap.linkUrl$, this.linkUrl$)

    // ---- Connect focus
    if (editorContext) {
      this.connecting(
        editorContext.focusScrapWidget$.pipe(
          map(scrapWidget => scrapWidget === this),
        ),
        this.isFocused$
      )
    }

    // ---- Connect to model positioning (thru Animatorer)
    this.positioning = new Animatorer(this.scrap.positioning$.value)
    this.connecting(
      this.scrap.positioning$.pipe(
        pairwise(),
        map(([p0, p1]) => {

          // See if there was a change in Z
          if (p0.z === p1.z)
            return new BaseAnimation<Positioning>((_) => p1, 500)

          // Else pop or shrink
          const scale = (p1.z > p0.z) ? 1.3 : 0.7
          return new MultiAnimation(
            new BaseAnimation<Positioning>((_) => p1.transform(new Transform({ scale })), 250),
            new BaseAnimation<Positioning>((_) => p1, 250),
          )

        }),
      ),
      this.positioning.animation$
    )

    // ---- Connect to update z$
    this.connecting(
      this.positioning.output$.pipe(map(([p, animation]) => p.z)),
      this.z$
    )

    // ---- Connect to magic dot and trash dot
    this.connecting(
      this.isFocused$.pipe(
        map(b => b ? this.createDotWidgets() : []),
      ),
      this.dotWidgets$
    )

    // ---- Connect capture mode
    if (editorContext)
      this.connecting(editorContext.captureMode$,
                      this.captureMode$)

    // ---- Connect parent
    if (editorContext) {
      this.connecting(
        combineLatest([this.scrap.parent$, editorContext.scrapWidgets$]).pipe(
          map(([parent, scrapWidgets]) =>
            parent && (scrapWidgets.find(widget => widget.scrap.id === parent.id)
                       || null)
          )
        ),
        this.parentWidget$
      )
    }
  }

  // ---- More precise isTarget to account for positioning and size
  isTargetPrecise(p: Point, rect: Rect, distance: number = 1): boolean {

    // ---- Convert to normalized rotated coordinates
    const size = this.sizeActual$.value
    const rotation = this.positioning.value$.value.rotation
    const scrapP = convertPointFromBoundingBox(p, rect,
      rotation,
      size
    ).normalizeTo(size.x, size.y)
    console.log(">>>> ScrapWidget isTargetPrecise scrapP", p, rect, scrapP)

    // ---- Bounds check
    return Rect.ONE.contains(scrapP)
  }

}

