import * as _ from "lodash"
import {Point, Size, Transform} from "@piccollage/cbjs"

export type Z = number
export const Z_DEFAULT = 0

export class Positioning {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    readonly point: Point = Point.ZERO,
    readonly rotation: number = 0,
    readonly scale: number = 1,
    readonly z: Z = Z_DEFAULT,
  ) {
  }

  static ZERO = new Positioning()

  toString(): string {
    return `Positioning(point: ${
      this.point ? this.point.toString() : ""
    }, rotation: ${
      this.rotation ? this.rotation : ""
    }, scale: ${
      this.scale ? this.scale : ""
    }, z: ${
      this.z
    })`
  }

  transform(t: Transform): Positioning {
    return new Positioning(
      this.point.add(t.move),
      this.rotation + t.rotate,
      this.scale * t.scale,
      this.z
    )
  }

  isEqual(p: Positioning) {
    return this.point.isEqual(p.point) &&
           _.isEqual(this.scale, p.scale) &&
           _.isEqual(this.rotation, p.rotation) &&
           _.isEqual(this.z, p.z)
  }

  invert() {
    return new Positioning(
      this.point.scale(-1),
      -this.rotation,
      1/this.scale,
      this.z
    )
  }

  addPoint(point: Point): Point {
    const offset = point
      .scale(this.scale)
      .rotate(this.rotation)
    return this.point.add(offset)
  }

}

export function aspectFill(from: Size, to: Size): Positioning {
  const ratioX = from.width / from.height
  const w = (to.width / ratioX < to.height) ?
    to.height * ratioX :
    to.width
  return new Positioning(
    new Point(to.x / 2, to.y / 2),
    0,
    w / from.width
  )
}
