import {cachedArrayMapper, TTap, Widget} from "@piccollage/cbjs";
import {BehaviorSubject, Subject} from "rxjs";
import {CollageTileWidget} from "./collage_tile_widget";
import {manipulateStartNewCollage} from "../../app/manipulators/manipulate_start_new_collage";
import {AppWidgetContext} from "../../app/ui/app_widget";
import {map} from "rxjs/operators";
import {Collage} from "../../collage_editor/models/collage";

export class AllCollagesWidget extends Widget {

  // ---- Inputs
  tappedNewCollage$ = new Subject<TTap>();

  // ---- Outputs
  collageTileWidgets$ = new BehaviorSubject<CollageTileWidget[]>([]);

  constructor() {
    super();

    const currentPersonCollages$ = this.contexter.use(AppWidgetContext).appWidget.currentPersonCollages$;

    const collageTileWidgetsMapper: (collages: Collage[]) => CollageTileWidget[] =
      cachedArrayMapper(
        (collage ) => collage.id,
        (collage: Collage) => this.legate(() => new CollageTileWidget(collage))
      );


    // ---- Get updates for MyCollages
    this.connecting(
      currentPersonCollages$.pipe(
        map(collageTileWidgetsMapper)
      ),
      this.collageTileWidgets$
    )


    // ---- Trigger create new collage
    this.triggering(this.tappedNewCollage$,
      _ =>
        manipulateStartNewCollage(
          this.contexter.use(AppWidgetContext).appWidget
        )
    )
  }


}