import {EditorWidget} from "../../collage_editor/ui/editor_widget"
import {arraySubjectAdd, arraySubjectRemove, doOnSubscribe, Point} from "@piccollage/cbjs"
import {Observable} from "rxjs"
import {ProgressWidget} from "../ui/progress_widget"
import {finalize, map} from "rxjs/operators"

export class Manipulator {

}


export function manageProgressWidget(editorWidget: EditorWidget,
                                     point: Point)
  : [() => any, () => any]
{
  let progressWidget: ProgressWidget | null = null

  function doStart() {
    if (!progressWidget) {
      progressWidget = editorWidget.legate(() => new ProgressWidget(point))
      arraySubjectAdd(editorWidget.progressWidgets$, progressWidget)
    }
  }
  function doStop() {
    // We remove the widget when the first data happens
    if (progressWidget)
      arraySubjectRemove(editorWidget.progressWidgets$, progressWidget)
  }

  return [doStart, doStop]
}

export enum TrackWhen {
  Subscribed,
  First
}

export function observingProgressWidget<T>(editorWidget: EditorWidget,
                                           point: Point,
                                           trackWhen: TrackWhen = TrackWhen.Subscribed)
  : (source: Observable<T>) => Observable<T> {

  return (source: Observable<T>) => {
    const [doStart, doStop] = manageProgressWidget(editorWidget, point)
    return source.pipe(
      doOnSubscribe(() => {
        if (trackWhen === TrackWhen.Subscribed)
          doStart()
      }),
      map((_, index) => {
        if (trackWhen === TrackWhen.First && index === 0)
          doStart()
        return _
      }),
      finalize(doStop)
    )
  }
}