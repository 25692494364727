import {PathEditorWidget} from "./path_editor_widget";
import {Path} from "../../toolkit/models/path";
import {Command} from "@piccollage/cbjs";
import {of} from "rxjs";
import {tap} from "rxjs/operators";

export function commandUpdatePath(pathEditorWidget: PathEditorWidget, pathNew: Path|null)
  : Command<Path|null, Path|null>
{
  // LEARN: save the state during execution, not during creation
  return new Command(
    `commandUpdatePath(${pathNew && pathNew.size})`,
    command => {
      command.memo = pathEditorWidget.path$.value
      return of(pathNew).pipe(
        tap(path => pathEditorWidget.path$.next(path))
      )
    },
    command => {
      const path = command.memo || null
      return of(path).pipe(
        tap(path => pathEditorWidget.path$.next(path))
      )
    })

}