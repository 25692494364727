import {Point, RefStore, Size, Widget} from "@piccollage/cbjs";
import {ScrapWidget} from "../../collage_editor/ui/scrap_widget";
import {BackdropWidget} from "../../collage_editor/ui/backdrop_widget";
import {aspectFill, Positioning} from "../../toolkit/models/positioning";
import {RefObject} from "react";
import {ImageScrapWidget} from "../../collage_editor/ui/image_scrap_widget";
import {Path} from "../../toolkit/models/path";
import {TextScrapWidget} from "../../collage_editor/ui/text_scrap_widget";
import {TextSpec} from "../../collage_editor/models/text_spec";
import {
  TEXT_SCRAP_BACKGROUND_RADIUS,
  TEXT_SCRAP_FONT_SIZE,
  TEXT_SCRAP_STROKE_SIZE
} from "../../collage_editor/views/text_scrap_view";
import canvasTxt from "canvas-txt";

export function viewCaptureCollage(size: Size, refStore: RefStore<Widget, HTMLElement>)
  : HTMLCanvasElement
{
  console.log("++++ viewCaptureCollage", size, refStore)
  const canvas = document.createElement('canvas')
  canvas.width  = size.width
  canvas.height = size.height
  const root = document.body
  root.insertBefore(canvas, root.firstChild)

  viewCaptureCollageToCanvas(canvas, refStore)

  root.removeChild(canvas)
  return canvas

}
export function viewCaptureCollageToCanvas(canvas: HTMLCanvasElement,
                                           refStore: RefStore<Widget, HTMLElement>)
{
  const ctx = canvas.getContext('2d')
  if (ctx === null)
    throw new Error("Unable to get context from canvas")

  // ---- Get the BackdropWidget
  const { key: backdropWidget, ref: backdropRef } = (
    refStore.stored.find(
      ({ key }) => key instanceof BackdropWidget
      ) || {}
    ) as { key: BackdropWidget, ref: RefObject<HTMLElement>}
  const collage = backdropWidget && backdropWidget.collage

  // ---- Figure out scaling
  const scale = new Size(canvas.width  / collage.size$.value.width,
                         canvas.height / collage.size$.value.height)
  ctx.scale(scale.x, scale.y)

  // ==========================================================================
  // ---- Find and render the backdrop (aspect fill)

  const backgroundImg = backdropRef.current &&
    backdropRef.current.querySelector('img#BACKGROUND_IMG') as HTMLImageElement
  if (backgroundImg) {
    const imgSize = new Size(backgroundImg.naturalWidth, backgroundImg.naturalHeight)
    const pos = aspectFill(imgSize, collage.size$.value)
    drawImage(ctx, backgroundImg, pos, imgSize)
  }

  // ==========================================================================
  // ---- Go find the views associated with ImageScrapWidgets

  const refWidgets = refStore.stored
    .filter(({ key }) =>
      // key instanceof ImageScrapWidget
      // && key.imageInfo$.value.isLoaded
      key instanceof ScrapWidget
    )

  // ---- Sort by z
  const z = (w: ScrapWidget) => w.scrap.positioning$.value.z
  refWidgets.sort((a, b) =>
    z(a.key as ScrapWidget) - z(b.key as ScrapWidget))

  // ---- Render all
  refWidgets.forEach(({ref, key: widget}) => {

    // -----------------------------------------------------------------------
    if (widget instanceof ImageScrapWidget)  {

      // ---- Insert image into context
      const dom  = ref.current
      if (dom) {
        const img = dom.querySelector('img') as HTMLImageElement
        if (img) {
          drawImage(ctx, img,
            widget.imageScrap.positioning$.value,
            widget.imageScrap.sizeBase$.value,
            widget.imageScrap.clippingPath$.value)
        }
      }
    }
    else if (widget instanceof TextScrapWidget) {
      drawText(ctx,
        widget.textScrap.spec$.value,
        widget.textScrap.positioning$.value,
        widget.sizeActual$.value)
    }

  })

}

function drawImage(ctx: CanvasRenderingContext2D,
                   img: HTMLImageElement,
                   pos: Positioning,
                   size: Size,
                   clippingPath: Path|null = null)
{
  ctx.save()

  // ---- Position
  ctx.translate(pos.point.x, pos.point.y)
  ctx.rotate(pos.rotation)
  ctx.scale(pos.scale, pos.scale)

  // ---- Clip
  if (clippingPath) {
    drawPath(ctx, size.scale(-0.5), size, clippingPath)
    ctx.clip()
  }

  // ---- Draw
  ctx.drawImage(img,
    -size.width /2, -size.height/2,
    size.width,      size.height)

  // ---- Done
  ctx.restore()
}

function drawPath(ctx: CanvasRenderingContext2D,
                  offset: Point, size: Size,
                  path: Path)
{
  function place(p: Point): Point {
    return p.scale(size).add(offset)
  }
  ctx.beginPath()
  path.forEach((p: Point, i: number) => {
    const pc = place(p)
    if (i === 0)
      ctx.moveTo(pc.x, pc.y)
    else
      ctx.lineTo(pc.x, pc.y)
  })
  ctx.closePath()
}

function drawText(ctx: CanvasRenderingContext2D,
                  textSpec: TextSpec,
                  pos: Positioning,
                  size: Size) {
  if (textSpec.t && textSpec.t.length > 0 && textSpec.font) {
    console.log("++++ drawText", textSpec, pos, size)



    ctx.save()

    ctx.textBaseline = "bottom"   // Closest thing to HTML/CSS rendering

    // ---- Position
    ctx.translate(pos.point.x, pos.point.y)
    ctx.rotate(pos.rotation)
    ctx.scale(pos.scale, pos.scale)

    // ---- Draw background
    const colorBackground = textSpec.colorBackground
    if (colorBackground) {
      ctx.save()

      const cornerRadius = TEXT_SCRAP_BACKGROUND_RADIUS
      ctx.lineJoin = "round"
      ctx.lineWidth = cornerRadius
      ctx.fillStyle = colorBackground.code
      ctx.fillRect(-size.width/2,     // + (cornerRadius / 2),
                   -size.height/2,    // + (cornerRadius / 2),
                   size.width,        // - cornerRadius,
                   size.height,       //  - cornerRadius
                   );

      ctx.restore()
    }

    // ---- Draw text
    canvasTxt.textSize    = TEXT_SCRAP_FONT_SIZE
    canvasTxt.align       = textSpec.alignment || "center"
    canvasTxt.font        = (textSpec.font && textSpec.font.family) || "serif"
    canvasTxt.fillStyle   = (textSpec.color && textSpec.color.code) || 'black'
    canvasTxt.strokeStyle = (textSpec.colorStroke && textSpec.colorStroke.code) || null
    canvasTxt.lineWidth   = (textSpec.widthStroke || 0) * TEXT_SCRAP_STROKE_SIZE
    canvasTxt.drawText(ctx,
                       textSpec.t,
                       -size.width/2, -size.height/2,
                       size.width, size.height)

    // ---- Done
    ctx.restore()
  }
}

