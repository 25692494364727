import {Point, Transform, transformsFromDirectedGesture, TTouchGesture, UndoContext} from "@piccollage/cbjs"
import {flatMap, last, map, tap} from "rxjs/operators"
import {Positioning} from "../../toolkit/models/positioning";
import {Collage} from "../models/collage";
import {repositionTouchEvent} from "../../toolkit/models/positioning_util";
import {TextDotWidget} from "../ui/text_scrap_widget";
import {commandUpdateScrapSizeBase} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

// Note that Gesture must have only ONE touch, must be filtered beforehand.
export function manipulateTextDotTransform(gesture: TTouchGesture,
                                           collageOffset: Positioning,
                                           collage: Collage,
                                           textDotWidget: TextDotWidget)

{
  console.log("++++ manipulateTextDotTransform", gesture)

  const scrapWidget = textDotWidget.scrapWidget
  const pInitial    = scrapWidget.scrap.positioning$.value
  const sInitial    = scrapWidget.scrap.sizeBase$.value
  const pivot       = pInitial.point
  const direction   = new Point(1,0).rotate(pInitial.rotation)

  return gesture.pipe(

    // ---- Convert gesture to collage coordinates
    map(event => repositionTouchEvent(event, collageOffset)),

    // ---- Calculate transform
    //      LEARN: by breaking down operations further, better reuse
    transformsFromDirectedGesture(pivot, direction),

    // ---- Apply to widget
    tap((t: Transform) => {
      scrapWidget.sizeBase$.next(sInitial.scale(t.scale))
    }),

    // ---- When finished, issue command
    last(null, Transform.ZERO),
    flatMap((t: Transform) =>
      commandUpdateScrapSizeBase(collage, scrapWidget.scrap, sInitial.scale(t.scale))
      .tap(c => textDotWidget.contexter.get(UndoContext)?.push(c))
      .execution()
      .pipe(EditorContext.findFrom(textDotWidget)?.modifies() || NOOP)

    )
  )
}