import * as firebase from "firebase";
import {TextSpec} from "../collage_editor/models/text_spec";
import {Font} from "../toolkit/models/font";
import {Point} from "@piccollage/cbjs";
import {Color} from "../toolkit/models/color"
import {Stroke} from "../toolkit/models/stroke";
import {pathToArray} from "../toolkit/models/path";

export function fieldify<N extends string, V>(name: N,
                                              value: V|undefined | (() => V|undefined) )
  : { [key in N]?: V } {
  if (typeof value === 'function')
    value = (value as () => V|undefined)()
  return value === undefined ?
    {} : {
      // Strangely we need the `any` here to get past compiler
      [name as any]: value
    }
}

export function fieldifyDeletable<N extends string, V>(name: N,
                                              value: V|undefined|null | (() => V|undefined|null))
  : { [key in N]?: V | firebase.firestore.FieldValue } {
  if (typeof value === 'function')
    value = (value as () => V|undefined|null)()
  return value === undefined ?
    {} : {
      // Strangely we need the `any` here to get past compiler
      [name as any]:
        value === null ?
          firebase.firestore.FieldValue.delete() : value
    }
}

export function fieldifyIf<SIGNAL, V>(signal: SIGNAL, v: V): V|{} {
  return signal ? v : {}
}

export function fieldifyPoint<N extends string>(name: N,
                                                point: Point | undefined)
{
  if (!point) return {}
  return {
    [name as N]: [point.x, point.y]
  }
}

export function fieldifyTextSpec<N extends string>(name: N,
                                                   spec: TextSpec | undefined)
{
  if (!spec) return {}
  return {
    [name as N]: {
      ...fieldify(     't',                spec.t),
      ...fieldify(     'alignment',        spec.alignment),
      ...fieldifyFont( 'font',             spec.font),
      ...fieldifyColor('color',            spec.color),
      ...fieldifyColor('color_stroke',     spec.colorStroke),
      ...fieldifyColor('color_background', spec.colorBackground),
      ...fieldify(     'width_stroke',     spec.widthStroke),
    }
  }
}

export function fieldifyFont<N extends string>(name: N,
                                               font: Font | undefined)
{
  if (!font) return {}
  return {
    [name as string]: {
      ...fieldify('family',     font.family),
      ...fieldify('protocol',   font.protocol),
      ...fieldify('variation',  font.variation || undefined)
    }
  }
}

export function fieldifyColor<N extends string>(name: N,
                                                color: Color|null|undefined)
{
  if (!color) return {}
  return {
    [name as string]: color.code
  }
}

export function fieldifyStroke<N extends string>(name: N,
                                                 stroke: Stroke|null|undefined)
{
  if (!stroke) return {}
  return {
    [name as string]: fieldStroke(stroke)
  }
}

export function fieldStroke(stroke: Stroke)
{
  return {
    ...fieldifyColor('color', stroke.color),
    ...fieldify('path_width', stroke.pathWidth),
    ...fieldify('path', pathToArray(stroke.path))
  }
}




