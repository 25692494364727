import React from "react";
import styles from "./home_view.module.scss";
import {HomeWidget} from "../ui/home_widget";
import {Subject} from "rxjs";
import {convertMouseToTouchEvent, TTap} from "@piccollage/cbjs";

import imageLeft from "../assets/images/homepage-left.png";
import imageRight from "../assets/images/homepage-right.png";
import imageHeading from "../assets/images/homepage-heading.png";
import imageBottom from "../assets/images/homepage-bottom.png";

export const HomeView = (props: { widget: HomeWidget }) => {
  const onClick = (output$: Subject<TTap>) => (e: React.MouseEvent) =>
    output$.next(new TTap(convertMouseToTouchEvent(e).touches[0]));

  return (
    <main className={styles.HomeView}>
      <section className={styles._section}>

        <img alt="hero" src={imageHeading} className={styles.img_heading} />

        <p className={styles._desc}>
          Sweet greetings with a personal touch.
          Add our illustrations and your own photos to make this card into the
          perfect sentiment!
        </p>

        <button
          className={styles.button_start}
          onClick={onClick(props.widget.loginTapped$)}
          data-tracking="FB - Get Started"
        >
          LOGIN
        </button>

      </section>

      <img alt="left"   src={imageLeft}   className={styles.img_left} />
      <img alt="right"  src={imageRight}  className={styles.img_right} />
      <img alt="bottom" src={imageBottom} className={styles.img_bottom} />

      <div className={styles._version}>
        {/* See https://stackoverflow.com/a/50759238/304734
                https://stackoverflow.com/a/50822003/304734 */}
        Version: { process.env.REACT_APP_NPM_PACKAGE_VERSION || "?" }
      </div>
    </main>
  );
};

export default HomeView;
