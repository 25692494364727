import {Command, promise$} from "@piccollage/cbjs";
import firebase from "firebase";
import {flatMap} from "rxjs/operators";
import {Person} from "../models/person";
import {firestorePersons, PersonMapper} from "../firebase/person_firestore";

export function commandCreatePerson(personId: string, name: string | null, imageUrl: string | null): Command<Person> {
  return new Command('commandCreatePerson', () => {
    const data = {
      created_on: firebase.firestore.FieldValue.serverTimestamp(),
      name,
      imageUrl,
    }
    return promise$(() =>
      firestorePersons()
        .doc(personId)
        .set(data)
    ).pipe(flatMap(_ => PersonMapper(personId)))
  })
}