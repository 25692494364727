import {Collage} from "../../collage_editor/models/collage";
import {Observable} from "rxjs";
import {AppWidget} from "../ui/app_widget";
import {CreatorWidget} from "../ui/creator_widget";
import {filterFirst, ID} from "@piccollage/cbjs";
import {commandLoadCollage} from "../../collage_firebase/command_firestore";
import {flatMap, map} from "rxjs/operators";
import {manipulateNavigateTopWidget} from "./manipulate_navigate_top_widget";

export function manipulateNavigateCollageCreator(appWidget: AppWidget, collageID: ID)
  : Observable<Collage>
{
  console.log("++++ manipulateNavigateCollageCreator")

  // ---- Get the Collage and edit it
  return commandLoadCollage(collageID)
    .execution()
    .pipe(
      filterFirst(),
      flatMap(collage => {
        const widget = appWidget.legate(() => new CreatorWidget(collage))
        return manipulateNavigateTopWidget(appWidget, widget).pipe(
          map(_ => collage)
        )
      })
  )
}
