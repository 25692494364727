import {defer, Observable, of} from "rxjs";
import {AppWidget} from "../ui/app_widget";
import {Widget} from "@piccollage/cbjs"

export function manipulateNavigateTopWidget(appWidget: AppWidget, topWidget: Widget)
  : Observable<Widget>
{

  return defer(() => {
    console.log("++++ manipulateNavigateTopWidget", topWidget)
    appWidget.topWidget$.next(topWidget)
    return of(topWidget)
  })
}
