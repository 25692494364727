import {BehaviorSubject} from "rxjs";
import {TextAlignment, TextSpec} from "../../collage_editor/models/text_spec";
import {map} from "rxjs/operators";
import {TextPanelWidget} from "./text_panel_widget";

export class TextAlignmentPanelWidget extends TextPanelWidget {
  key = 'alignment'

  // ---- Outputs
  alignment$: BehaviorSubject<TextAlignment>

  constructor(spec: TextSpec) {
    super({t: spec.t});
    this.alignment$ = new BehaviorSubject<TextAlignment>(spec.alignment || TextAlignment.Center)

    this.connecting(
      this.alignment$.pipe(map(alignment => new TextSpec({alignment}))),
      this.spec$
    )
  }
}