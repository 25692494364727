import * as firebase from "firebase/app"
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import {
  cachedArrayMapper,
  combineLatestWithEmpty,
  Contexter,
  DocRef,
  DocSnap,
  fieldToNumber,
  fieldToSize,
  fieldToString,
  filterDefined,
  firestoreSyncCollectionArray,
  firestoreSyncDocument,
  log$,
  swapMap,
} from "@piccollage/cbjs"
import {Collage, COLLAGE_DEFAULT_SIZE, COLLAGE_URL} from "../collage_editor/models/collage"
import {merge, Observable, of} from "rxjs"
import {distinctUntilChanged, flatMap, map, shareReplay, tap,} from "rxjs/operators"
import * as _ from "lodash"
import {Positioning} from "../toolkit/models/positioning";
import {Scrap, SCRAP_DEFAULT_BASE_SIZE} from "../collage_editor/models/scrap";
import {ImageScrap} from "../collage_editor/models/image_scrap";
import {TextScrap} from "../collage_editor/models/text_scrap";
import {fieldToPath, fieldToPositioning, fieldToStrokes, fieldToTextSpec} from "./collage_firestore_fields";
import {SketchScrap} from "../collage_editor/models/sketch_scrap";
import {Stroke} from "../toolkit/models/stroke";

type DocumentData         = firebase.firestore.DocumentData;
type CollectionReference  = firebase.firestore.CollectionReference;
type UploadTaskSnapshot   = firebase.storage.UploadTaskSnapshot

// -----------------------------------------------
// Reference shortcuts

export function firestoreCollages() {
  return firebase.firestore()
    .collection("collages")
}

export function firestoreCollage(collage: Collage|DocRef) {
  return firestoreCollages()
    .doc(collage.id)
}

export function firestoreCollageScraps(collage: Collage|DocRef) {
  if (collage instanceof Collage)
    collage = firestoreCollage(collage)
  return collage.collection("scraps")
}

export function firestoreCollageScrap(collage: Collage|DocRef, scrap: Scrap) {
  return firestoreCollageScraps(collage)
    .doc(scrap.id as string)
}

// ---------------------------------------------------------------------
//

export function syncedCollage$(collageRef: DocRef, shouldSyncScraps = true)
  : Observable<Collage>
{

  // LEARNED: Should it start per subscription or call to this function?
  //          It should be PER Observable returned (therefore per call).
  //          So, multiple subscriptions should return the same Collage.
  // LEARNED: `zip` below on Scraps, un-subscribes and re-subscribes to the
  //          Scrap, which creates a new one every time.
  // LEARNED: It is tricky to use `scan` to return consistently the same object,
  //          so we currently try using a closure `let`.
  // LEARNED: Make sure nothing happens until subscription, so there should
  //          be no "internal" subscribes.

  console.log("++++ syncedCollage$")

  let collage: Collage|null = null
  return firestoreSyncDocument(collageRef).pipe(
    flatMap((collageSnap: DocSnap) => {

      const data          = collageSnap.data() || {}
      const size          = fieldToSize(data.size, COLLAGE_DEFAULT_SIZE)
      const collageURL    = fieldToString(data.collage_url, null)
      const backgroundURL = fieldToString(data.background_url, null)
      const thumbnailURL  = fieldToString(data.thumbnail_url, null);
      const ownerId       = fieldToString(data.owner_id, null);
      const isTrashed     = !!data.is_trashed;

      console.log("++++ syncedCollage$ snap:", collageSnap.id, data)

      // -----------------------------------------------------
      // ---- Create a new Collage the first time
      let ret: Observable<Collage>
      if (collage !== null)
        ret = of(collage)
      else {
        collage = new Collage(collageSnap.id, size, ownerId, isTrashed);

        if (!shouldSyncScraps)
          ret = of(collage);
        else {
          // Sync scraps and put in Collage
          const s$ =
            syncedScraps$(collageRef.collection("scraps"), collage.contexter).pipe(
              distinctUntilChanged(_.isEqual),
              tap(scraps => collage!!.scraps$.next(scraps)),
            )

          // ---- Merge into the Collage output stream (with `as Collage`)
          ret = merge(of(collage), s$.pipe(map(_ => collage as Collage)))
        }
      }


      // -----------------------------------------------------
      // ---- Update the Collage properties
      if (!_.isEqual(collageURL, collage.collageURL$.value))
        collage.collageURL$.next(collageURL)
      if (!size.isEqual(collage.size$.value))
        collage.size$.next(size)
      if (!_.isEqual(backgroundURL, collage.backgroundURL$.value))
        collage.backgroundURL$.next(backgroundURL)
      if (!_.isEqual(thumbnailURL, collage.thumbnailURL$.value))
        collage.thumbnailURL$.next(thumbnailURL);
      if (!_.isEqual(isTrashed, collage.isTrashed$.value))
        collage.isTrashed$.next(isTrashed);

      // ---- Done!
      return ret
    }),
  )
}

export function syncedScraps$(scrapsRef: CollectionReference,
                              contexter: Contexter = Contexter.DEFAULT)
  : Observable<Scrap[]>
{
  const scrapsMapper = cachedArrayMapper(
    scrapSnap => scrapSnap.id,
    (scrapSnap: DocSnap) =>
      syncedScrap$(scrapSnap.ref, contexter).pipe(
        distinctUntilChanged(),
        shareReplay(1),         // Need a `shareReplay` so that `combineLatest`
                                // works below and gets a value for all Scraps when
                                // a new one is updated.
      )
  )
  return firestoreSyncCollectionArray(scrapsRef).pipe(

    // ---- Filter trashed ones (TODO: filter from the Firestore query, but migration issues)
    map(scrapSnaps =>
      scrapSnaps.filter(scrapSnap => {
        const data = scrapSnap.data()
        return data && !data.is_trashed
      })
    ),

    // ---- Map to Scrap models
    map(scrapsMapper),

    // ---- Combine.
    //      LEARN: but need to combine in a way that doesn't unsubscribe -> swapMap!
    //      LEARN: swapMap works!!
    //
    swapMap(arrayObs => combineLatestWithEmpty(arrayObs)),
  )
}

export function syncedScrap$(scrapRef: DocRef, contexter: Contexter = Contexter.DEFAULT)
  : Observable<Scrap>
{
  console.log("++++ syncedScrap$")
  let scrap: Scrap|null = null
  return firestoreSyncDocument(scrapRef).pipe(
    log$("++++ syncedScrap$ snap"),
    map(scrapSnap => {

      // -----------------------------------------------------
      // ---- Base properties
      const id       = fieldToString(scrapSnap.id, "DEFAULT_ID")
      const type     = fieldToString(scrapSnap.get("type"), null)
      const parentId = fieldToString(scrapSnap.get("parent_id"), null)

      // -----------------------------------------------------
      // ---- Create Scrap
      if (scrap === null) {
        if (type === "text")
          scrap = contexter.legate(() => new TextScrap(id))
        else if (type === "image")
          scrap = contexter.legate(() => new ImageScrap(id))
        else if (type === "sketch")
          scrap = contexter.legate(() => new SketchScrap(id))
        else
          return null
      }

      // -----------------------------------------------------
      // ---- Update Scrap properties

      if (parentId !== scrap.parentId$.value)
        scrap.parentId$.next(parentId)

      const sizeBase = fieldToSize(scrapSnap.get("size_base"),
                       fieldToSize(scrapSnap.get("baseSize"),   // Legacy value
                       SCRAP_DEFAULT_BASE_SIZE))
      if (sizeBase && !sizeBase.isEqual(scrap.sizeBase$.value))
        scrap.sizeBase$.next(sizeBase)

      const positioning = fieldToPositioning(scrapSnap.get("positioning"), Positioning.ZERO)
      if (positioning && !positioning.isEqual(scrap.positioning$.value))
        scrap.positioning$.next(positioning)

      const linkUrl = fieldToString(scrapSnap.get("link_url"), null) as COLLAGE_URL|null
      if (!_.isEqual(linkUrl, scrap.linkUrl$.value))
        scrap.linkUrl$.next(linkUrl)

      // -----------------------------------------------------
      // ---- Update TextScrap properties
      if (scrap instanceof TextScrap) {
        const spec = fieldToTextSpec(scrapSnap.get("spec"), undefined)
        if (spec && !_.isEqual(spec, scrap.spec$.value))
          scrap.spec$.next(spec)
      }

      // -----------------------------------------------------
      // ---- Update ImageScrap properties
      if (scrap instanceof ImageScrap) {

        // ---- ImageSourceUrl
        const imageSourceUrl = fieldToString(scrapSnap.get("image_source_url"), "")
        scrap.imageSourceUrl$.next(imageSourceUrl)

        // ---- Clipping path check if timestamp is different before parsing
        const clippingPathTimestamp = fieldToNumber(scrapSnap.get("clipping_path_timestamp"), null)
        if (clippingPathTimestamp !== scrap.clippingPathTimestamp$.value) {
          const clippingPath = fieldToPath(scrapSnap.get("clipping_path"), null)
          scrap.clippingPath$.next(clippingPath)
          scrap.clippingPathTimestamp$.next(clippingPathTimestamp)
        }
      }

      // -----------------------------------------------------
      // ---- Update SketchScrap properties
      if (scrap instanceof SketchScrap) {

        const strokesTimestamp = fieldToNumber(scrapSnap.get("strokes_timestamp"), null)
        if (strokesTimestamp !== scrap.strokesTimestamp$.value) {
          const strokes = fieldToStrokes(scrapSnap.get("strokes"), new Array<Stroke>())
          scrap.strokes$.next(strokes)
        }

      }

      return scrap as Scrap
    }),
    filterDefined(),
  )
}
