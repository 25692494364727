import {
  Animatorer,
  BaseAnimation,
  BUTTON_SECONDARY,
  filterDefined,
  filterObservable,
  flipBoolean,
  hasButton$,
  isPinch$,
  Size,
  taplog,
  Transform,
  TTouchGesture,
  UndoContext,
  Widget
} from "@piccollage/cbjs";
import {COLLAGE_URL} from "../../collage_editor/models/collage";
import {Path} from "../../toolkit/models/path";
import {BehaviorSubject, merge, pipe, Subject} from "rxjs";
import {distinctUntilChanged, filter, map} from "rxjs/operators";
import {positionSizeWithin} from "../../toolkit/models/positioning_util";
import {
  manipulateFinish,
  manipulatePath,
  manipulatePathGesture,
  manipulateRedo,
  manipulateTransformCanvas,
  manipulateUndo,
  manipulateZoom,
} from "./path_editor_widget_manipulations";
import _ from "lodash";
import {Positioning} from "../../toolkit/models/positioning";

export class PathEditorWidget extends Widget {

  // ---- State/output
  path$:    BehaviorSubject<Path|null>  // Will Rx complete when the user finalizes Path
  pathNew$: BehaviorSubject<Path|null>

  // ---- Inputs
  gesture$  = new Subject<TTouchGesture>()   // In canvas coordinates
  viewSize$ = new BehaviorSubject<Size|null>(null)

  tappedZoomIn$   = new Subject<boolean>()
  tappedZoomOut$  = new Subject<boolean>()
  tappedReset$    = new Subject<boolean>()
  tappedUndo$     = new Subject<boolean>()
  tappedRedo$     = new Subject<boolean>()
  tappedDone$     = new Subject<boolean>()

  // ---- Undo
  undoContext = new UndoContext()

  // ---- Subwidgets
  montage: MontageWidget

  // ---- Lifecycle
  constructor(readonly imageURL: COLLAGE_URL,
              readonly imageSize: Size,
              readonly pathInitial: Path|null,
              rotation: number = 0
  ) {
    super()

    this.montage  = new MontageWidget(imageSize)

    this.pathNew$ = new BehaviorSubject<Path|null>(null)
    this.path$    = new BehaviorSubject<Path|null>(pathInitial)

    // ---- Connect positioning
    this.connecting(
      this.viewSize$.pipe(
        distinctUntilChanged(_.isEqual),
        filterDefined(),
        map(size => positionSizeWithin(size, this.montage.size)),
        map(pos => pos.transform(new Transform({ rotate: rotation }))),
        map(pos => new BaseAnimation<Positioning>(_ => pos, 200)),
      ),
      this.montage.positioning.animation$)

    // ---- Connect gestures
    this.connectGestures()

    // ---- Connect buttons
    this.triggering(this.tappedDone$,    () => manipulateFinish(this))
    this.triggering(this.tappedZoomIn$,  () => manipulateZoom(this, 1.2))
    this.triggering(this.tappedZoomOut$, () => manipulateZoom(this, 0.8))
    this.triggering(this.tappedReset$,   () => manipulatePath(this, null))
    this.triggering(this.tappedUndo$,    () => manipulateUndo(this))
    this.triggering(this.tappedRedo$,    () => manipulateRedo(this))


  }

  connectGestures() {

    // LEARN: functional composition easier and bflip

    const PINCH_TIMEOUT = 100

    // ---- Trigger panning/transform if (1) multiple fingers (2) right button
    //      (see https://stackoverflow.com/a/8875522/304734)
    const isCanvasGesture = (source: TTouchGesture) => merge(
      isPinch$(PINCH_TIMEOUT)(source).pipe(taplog(">>>> path isPinch$")),
      hasButton$(BUTTON_SECONDARY)(source).pipe(filter(_ => _), taplog(">>>> path hasButton$")),    // Filter true -- LEARN: RxJS for logic
      source.pipe(map(e => e.shiftKey)).pipe(filter(_ => _), taplog(">>>> path shiftKey")),    // Filter true
    )
    this.triggering(
      this.gesture$.pipe(
        filterObservable(isCanvasGesture),
        distinctUntilChanged()),                                    // TODO: Why gestures show up twice?
      gesture => manipulateTransformCanvas(this, gesture)
    )

    // ---- Trigger path drawing
    this.triggering(
      this.gesture$.pipe(
        filterObservable(pipe(isCanvasGesture, flipBoolean())),      // Flip boolean
        distinctUntilChanged()),                                    // TODO: Why gestures show up twice?
      gesture => manipulatePathGesture(this, gesture)
    )

  }

}

export class MontageWidget extends Widget {
  // ---- Output
  positioning: Animatorer<Positioning>

  constructor(readonly size: Size) {
    super()
    this.positioning = new Animatorer(Positioning.ZERO)
  }
}

