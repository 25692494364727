import * as firebase from "firebase/app"
import React, {useState} from "react";
import copy from "clipboard-copy";
import {convertMouseToTouchEvent, isMobile, TTap, useBehaviorSubject} from "@piccollage/cbjs";
import {ShareWidget} from "../ui/share_widget";
import Head from "./head_view";
import styles from "./share_view.module.scss";
import {downloadResource} from "../../toolkit/lib/share";
import Icon from "../../toolkit/views/Icon";
import {Snackbar} from "@material-ui/core";
import {Subject} from "rxjs";

import shareLogo from "../assets/images/share-heading.png";

export const ShareView = (props: { widget: ShareWidget }) => {
  const collageURL = useBehaviorSubject(props.widget.collage.collageURL$);
  const currentUrl = window.location.href;

  const [snackForCopy, snackForCopy_set] = useState(false);
  const [snackForSave, snackForSave_set] = useState(false);

  const handleShareFB = () => {
    firebase.analytics().logEvent("collage_shared_fb", { collage_id: props.widget.collage.id, url: currentUrl })
    window.FB.ui({
      method: "share",
      href: currentUrl
    });
  };

  const handleCopyUrl = () => {
    firebase.analytics().logEvent("collage_shared_copy_url", { collage_id: props.widget.collage.id, url: currentUrl })
    copy(currentUrl);
    snackForCopy_set(true);
  };

  const handleDownloadImage = () => {
    firebase.analytics().logEvent("collage_shared_download", { collage_id: props.widget.collage.id, is_mobile: isMobile })
    if (isMobile) {
      snackForSave_set(true);
    } else {
      downloadResource(collageURL!);
    }
  };

  const onClick = (output$: Subject<TTap>) => (e: React.MouseEvent) =>
    output$.next(new TTap(convertMouseToTouchEvent(e).touches[0]));

  return (
    <section className={styles.root}>
      {collageURL && <Head image={collageURL} />}

      <header className={styles.header}>
        <img
          alt="shareLogo"
          src={shareLogo}
          className={styles.logo}
          onClick={onClick(props.widget.homeTapped$)}
          data-tracking="FB - Home Logo"
        />
      </header>

      <main className={styles.collageWrapper}>
        {collageURL && (
          <img alt="capture" src={collageURL} className={styles.collage} />
        )}
      </main>

      <footer className={styles.footer}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackForCopy}
          autoHideDuration={3000}
          onClose={_ => snackForCopy_set(false)}
          message={"Copied link!"}
        />

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackForSave}
          autoHideDuration={10000}
          onClose={_ => snackForSave_set(false)}
          message={
            "On a mobile device, please long press the image above to save."
          }
        />

        <div className={styles.actionWrapper}>
          <div className={styles.socialIconWrapper}>
            <div
              className={styles.iconWrapper}
              onClick={handleDownloadImage}
              data-tracking="FB - Save as Local Image"
            >
              <Icon
                name="icon-download"
                size={isMobile ? 24 : 36}
                dataTracking="FB - Save as Local Image"
              />
            </div>
            <div
              className={styles.iconWrapper}
              onClick={handleShareFB}
              data-tracking="FB - Share on FB"
            >
              <Icon
                name="icon-fb"
                size={isMobile ? 24 : 36}
                dataTracking="FB - Share on FB"
              />
            </div>
            <div
              className={styles.iconWrapper}
              onClick={handleCopyUrl}
              data-tracking="FB - Copy Link"
            >
              <Icon
                name="icon-copy-link"
                size={isMobile ? 24 : 36}
                dataTracking="FB - Copy Link"
              />
            </div>
          </div>
          <button
            className={styles.restartBtn}
            onClick={onClick(props.widget.newCollageTapped$)}
            data-tracking="FB - Create More"
          >
            CREATE MORE
          </button>
        </div>
      </footer>
    </section>
  );
};

export default ShareView;
