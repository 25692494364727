import {LinkDotWidget, MagicDotWidget, PanningDotWidget, ScrapWidget, TrashDotWidget} from "../ui/scrap_widget"
import {findInstanceOf, iff, Point, Size, useBehaviorSubject, useRefStore, useRefStores} from "@piccollage/cbjs"
import React, {useEffect} from "react"
import {positioningToCSS, useSpringProperties} from "../../app/views/view_util"
import {animated} from 'react-spring'
import {ScrapDecorationView} from "./scrap_decoration_view";
import styles from "./scrap_view.module.scss";
// ---- There seems to be a bug in Chrome (not in Safari) where using the Icon
//      component doesn't render or load the contents. So we load these directly
//      instead.
//
import icon_scissors from "../../toolkit/assets/icons/icon-scissors.svg";
import icon_close from "../../toolkit/assets/icons/icon-close.svg";
import icon_scale from "../../toolkit/assets/icons/icon-scale.svg";
import {DotWidget} from "../ui/dot_widget";
import {TextDotWidget} from "../ui/text_scrap_widget";
import {ClipDotWidget} from "../ui/image_scrap_widget";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import OpenWithIcon from "@material-ui/icons/OpenWith";

type ReactElement = React.ReactElement<any>

export function ScrapView(props: { widget: ScrapWidget,
                                   children: ReactElement[]|ReactElement }) {

  const ref = useRefStore<ScrapWidget, HTMLDivElement>(props.widget)

  const sizeBase            = useBehaviorSubject(props.widget.sizeBase$)
  const [pos, posAnimation] = useBehaviorSubject(props.widget.positioning.output$)

  console.log("++++ ScrapView render", props.widget.scrap.id, sizeBase, pos, posAnimation)

  const width = `${sizeBase.width}px`
  const spring = useSpringProperties(
    positioningToCSS(pos, true),
    positioningToCSS(posAnimation.valueTo(pos), true),
    { duration: posAnimation.t },
  )

  useEffect(() => {
    if (ref.current) {
      const size = new Size(ref.current.clientWidth, ref.current.clientHeight)
      props.widget.sizeActual$.next(size)
    }
  })

  const isFocused           = useBehaviorSubject(props.widget.isFocused$)
  return (
    <div>

      <animated.div ref={ref} style={ { width, ...spring } }
                    className={styles.ScrapView}>

        { isFocused && (<ScrapFocusBackground widget={props.widget} />) }
        {props.children}
      </animated.div>

      <ScrapDecorationsView widget={props.widget} />

    </div>
  )
}

export function ScrapFocusBackground(props: { widget: ScrapWidget })
{
  const padding = useBehaviorSubject(props.widget.padding$)
  const style = padding ?
    {
      top:    padding.origin.y * 100 + '%',
      left:   padding.origin.x * 100 + '%',
      bottom: (1 - padding.size.height - padding.origin.y) * 100 + '%',
      right:  (1 - padding.size.width  - padding.origin.x) * 100 + '%',
    } :
    {
      top:    0,
      bottom: 0,
      left:   0,
      right:  0,
    }
  return (<div style={style} className={styles.focusBackground} />)
}

export function ScrapDecorationsView(props: { widget: ScrapWidget })
{
  console.log("++++ ScrapDecorationsView render")

  const captureMode         = useBehaviorSubject(props.widget.captureMode$)

  const dotWidgets          = useBehaviorSubject(props.widget.dotWidgets$)
  const dotWidgetRefs       = useRefStores<DotWidget, any>(dotWidgets)

  const linkUrl             = useBehaviorSubject(props.widget.linkUrl$)

  return (
    <div id="decorators">
    {
      !captureMode && (
        <ScrapDecorationView scrapWidget={props.widget}
                             placement={new Point(0.5, -0.5)} >
          {
            iff(findInstanceOf(dotWidgets, MagicDotWidget), dotWidget => (
              <img alt="Resize" className={styles.scrapDot}
                   ref={dotWidgetRefs.get(dotWidget)!!}
                   src={icon_scale} />
            ))
          }
          {
            iff(findInstanceOf(dotWidgets, ClipDotWidget), dotWidget => (
              <img alt="Clip" className={styles.scrapDot}
                   ref={dotWidgetRefs.get(dotWidget)!!}
                   src={icon_scissors} />
            ))
          }
          {
            iff(findInstanceOf(dotWidgets, LinkDotWidget), dotWidget => (
              <div className={styles.scrapDot}
                   ref={dotWidgetRefs.get(dotWidget)!!}>
                {
                  linkUrl ?
                    <LinkIcon className={styles.scrapDotIcon}/> :
                    <LinkOffIcon className={styles.scrapDotIcon}/>
                }
              </div>
            ))
          }
        </ScrapDecorationView>
      )
    }

    {
      !captureMode && (
        <ScrapDecorationView scrapWidget={props.widget}
                             placement={new Point(0,0)}>
          {
            iff(findInstanceOf(dotWidgets, PanningDotWidget), dotWidget => (
              <div className={styles.scrapDot}
                   ref={dotWidgetRefs.get(dotWidget)!!}>
                 <OpenWithIcon className={styles.scrapDotIcon}/> :
              </div>
            ))
          }
        </ScrapDecorationView>
      )
    }

    {
      !captureMode && (
        <ScrapDecorationView scrapWidget={props.widget}
                             placement={new Point(-0.5, +0.5)} >
          {
            iff(findInstanceOf(dotWidgets, TrashDotWidget), dotWidget => (
              <img alt="Trash" className={styles.scrapDot}
                   ref={dotWidgetRefs.get(dotWidget)!!}
                   src={icon_close} />
            ))
          }
        </ScrapDecorationView>
      )
    }

    {
      !captureMode && (
        <ScrapDecorationView scrapWidget={props.widget}
                             placement={new Point(0.5, 0.5)} >
          {
            iff(findInstanceOf(dotWidgets, TextDotWidget), dotWidget => (
              <div className={styles.scrapBar}
                ref={ dotWidgetRefs.get(dotWidget) }
                >

              </div>
            ))
          }
        </ScrapDecorationView>
      )
    }

    </div>
  )
}
