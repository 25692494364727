import {ScrapWidget} from "../ui/scrap_widget"
import {EMPTY, Observable} from "rxjs"
import {Size} from "@piccollage/cbjs"
import {CollageContext} from "../models/collage";
import {commandUpdateScrapSizeBase} from "../../collage_firebase/command_firestore_scrap";

export function manipulateAdjustSizeBase(scrapWidget: ScrapWidget, sizeNew: Size)
  : Observable<any> {

  const scrap    = scrapWidget.scrap
  const collage  = scrap.contexter.use(CollageContext).collage
  const sizeCur  = scrap.sizeBase$.value
  const ratioNew = sizeNew.width / sizeNew.height
  const sizeAdj  = new Size(sizeCur.width, sizeCur.width / ratioNew)

  if (sizeAdj.isEqual(sizeCur)) {
    return EMPTY
  } else {
    console.log("++++ manipulateAdjustSizeBase scale", sizeCur, sizeAdj)
    return commandUpdateScrapSizeBase(collage, scrap, sizeAdj)
      .execution()
  }
}

