import {EditorWidget} from "../ui/editor_widget";
import {Observable} from "rxjs";
import {Collage} from "../models/collage";
import {blobToFile, firestoreUploadImage, Size, taplog} from "@piccollage/cbjs";
import {flatMap, map} from "rxjs/operators";
import {commandUpdateCollage} from "../../collage_firebase/command_firestore";

const FIRESTORE_THUMBNAILS_FOLDER = 'thumbnails'
const THUMBNAIL_SIZE = new Size(512, 256)

export function manipulateUpdateThumbnail(editorWidget: EditorWidget)
  : Observable<Collage>
{
  const filename = editorWidget.collage.id + "-thumbnail.png"

  return editorWidget.capture(THUMBNAIL_SIZE).pipe(
    map(blob => blobToFile(blob,  filename)),
    flatMap(file => firestoreUploadImage(file, FIRESTORE_THUMBNAILS_FOLDER, filename)),
    // ---- Update the collageURL on the model
    flatMap(collage_url =>
      commandUpdateCollage(
        editorWidget.collage,
        {
          thumbnail_url: collage_url
        }
      )
        .execution()
    ),
    taplog("++++ manipulateUpdateThumbnail finished"),
  )
}