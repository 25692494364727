import {Subject} from "rxjs";
import {TTap, Widget} from "@piccollage/cbjs";
import {AppWidgetContext} from "./app_widget";
import {manipulateLogin} from "../manipulators/manipulate_login";
import {manipulateStartNewCollage} from "../manipulators/manipulate_start_new_collage";


export class HomeWidget extends Widget {
  loginTapped$ = new Subject<TTap>()

  // ---- from original HomeView
  newCollageTapped$ = new Subject<TTap>()
  
  constructor() {
    super()

    const appWidget = this.contexter.use(AppWidgetContext).appWidget;

    this.triggering(this.loginTapped$,
      _ => manipulateLogin(appWidget)
    )

    this.triggering(this.newCollageTapped$,
      _ => manipulateStartNewCollage(appWidget)
    )
  }
}