import {EditorWidget} from "../ui/editor_widget";
import {next} from "@piccollage/cbjs";
import {Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";
import {Color} from "../../toolkit/models/color";

export function manipulateSketchColor(editorWidget: EditorWidget)
  : Observable<Color>
{
  const colors = EditorWidget.colors
  return of(editorWidget.curSketchColor$.value).pipe(
    map(color => next(colors, color) || colors[0]),
    tap(color => editorWidget.curSketchColor$.next(color))
  )
}