import {Observable, of} from "rxjs";
import {EditorWidget} from "../ui/editor_widget";
import {filterDefined, now, Point, Rect, scan2, Size, TTouchGesture} from "@piccollage/cbjs";
import {filter, finalize, last, map, switchMap, tap} from "rxjs/operators";
import {repositionPoint} from "../../toolkit/models/positioning_util";
import {List} from "immutable";
import {Stroke} from "../../toolkit/models/stroke";
import {pathMinMax, pathReframe} from "../../toolkit/models/path";
import {SketchScrap} from "../models/sketch_scrap";
import {commandAddSketchScrap} from "../../collage_firebase/command_firestore_scrap";
import {Positioning} from "../../toolkit/models/positioning";
import {EditorContext, NOOP} from "../ui/editor_context";

const STROKE_PATH_WIDTH = 0.007
const STROKE_THROTTLE_TIME = 20

export function manipulateSketchStroke(editorWidget: EditorWidget,
                                       gesture: TTouchGesture)
  : Observable<SketchScrap|null>
{
  console.log("++++ manipulateSketchStroke", now())

  const collage         = editorWidget.collage
  const collageOffset   = editorWidget.collageOffset()
  const collageSize     = editorWidget.collage.size$.value
  const color           = editorWidget.curSketchColor$.value

  // Scaled stroke width
  const strokePathWidth = STROKE_PATH_WIDTH /
    (editorWidget.backdropWidget.positioning.value$.value?.scale || 1.0)

  return gesture.pipe(
    // throttleTime(STROKE_THROTTLE_TIME),      // TODO: decide whether to throttle

    // ---- Get gesture point
    map(e => e.touches[0]),
    filterDefined(),
    map(touch => touch.point),
    map(point => repositionPoint(point, collageOffset)),

    // ---- Normalize
    map(point => point.scale(1/collageSize.width, 1/collageSize.height)),

    // ---- Accumulate into Path, then Stroke
    scan2<Point, List<Point>, List<Point>>(List(), (acc, p) => acc.push(p)),
    filter(points => points.count() >= 2),
    map(points => new Stroke(color, strokePathWidth, points)),

    // ---- Set the new temporary Stroke
    tap(stroke => editorWidget.curSketchStroke$.next(stroke)),

    // ---- Remove temporary
    finalize(() => editorWidget.curSketchStroke$.next(null)),

    // ---- Get the last one
    last(),


    // ----- Convert to SketchScrap
    switchMap(strokeOrig => {

      // ---- Calculate the bounding rectangle of the path
      const rectMinMax = pathMinMax(strokeOrig.path)
      if (!rectMinMax)
        return of(null)

      // ---- Pad the rectangle a bit based on stroke width
      const w = strokeOrig.pathWidth
      const rect = new Rect(
        rectMinMax.origin.subtract(new Point(w, w)),
        new Size(rectMinMax.size.width + w * 2, rectMinMax.size.height + w * 2)
      )

      // ---- Calculate new position and size
      const point = rect.center.scale(collageSize)
      const size  = rect.size.scale(collageSize.width, collageSize.height)

      // ---- Reframe the stroke
      const path      = pathReframe(strokeOrig.path, rect)
      const pathWidth = strokeOrig.pathWidth / rect.size.width
      console.log(">>>> manipulateSketchStroke path reframed", path.toArray())
      const strokeReframed = new Stroke(strokeOrig.color, pathWidth, path)

      return commandAddSketchScrap(
          collage,
          new Positioning(point, 0, 1, collage.scrapZMax() + 1),
          size,
          [strokeReframed]
        )
        .push(editorWidget.undoContext)
        .execution()
        .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)

    })
  ) as Observable<SketchScrap|null>


}
