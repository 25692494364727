import {Observable} from "rxjs/index"
import {Collage} from "../models/collage"
import {flatMap, map} from "rxjs/operators"
import {EditorWidget} from "../ui/editor_widget"
import {blobToFile, firestoreUploadImage, Size, taplog} from "@piccollage/cbjs"
import {commandUpdateCollage} from "../../collage_firebase/command_firestore"

const FIRESTORE_CAPTURES_FOLDER = 'captures'
const CAPTURE_SIZE = new Size(2048, 1024)

export function manipulateCaptureCollage(editorWidget: EditorWidget)
  : Observable<Collage>
{
  console.log("++++ manipulateCaptureCollage")

  const filename = editorWidget.collage.id + "-capture.png"

  return editorWidget.capture(CAPTURE_SIZE).pipe(
      map(blob => blobToFile(blob, filename)),
      flatMap(file => firestoreUploadImage(file, FIRESTORE_CAPTURES_FOLDER, filename)),
      // ---- Update the collageURL on the model
      flatMap(collage_url =>
        commandUpdateCollage(
          editorWidget.collage,
            {
              collage_url,
              collage_fb_url: null,  // Reset the generated Facebook capture
            }
        )
          .execution()
      ),
      taplog("++++ manipulateCaptureCollage finished"),
  )
}