import {ScrapWidget} from "../ui/scrap_widget";
import {TextScrapWidget} from "../ui/text_scrap_widget";
import {TextScrapView} from "./text_scrap_view";
import {ImageScrapWidget} from "../ui/image_scrap_widget";
import {ImageScrapView} from "./image_scrap_view";
import {SketchScrapWidget} from "../ui/sketch_scrap_widget";
import {SketchScrapView} from "./sketch_scrap_view";
import * as React from "react";
import {BehaviorSubject} from "rxjs";
import {sortingMap, useObservable, compareArray} from "@piccollage/cbjs";
import {distinctUntilChanged, map} from "rxjs/operators";
import _ from "lodash";

function viewsForScrapWidget(scrapWidget: ScrapWidget): JSX.Element[] {
  if (scrapWidget instanceof TextScrapWidget) {
    return ([
      <TextScrapView key={scrapWidget.scrap.id} widget={scrapWidget}/>
    ])
  } else if (scrapWidget instanceof ImageScrapWidget) {
    return ([
      <ImageScrapView key={scrapWidget.scrap.id} widget={scrapWidget}/>
    ])
  } else if (scrapWidget instanceof SketchScrapWidget) {
    return ([
      <SketchScrapView key={scrapWidget.scrap.id} widget={scrapWidget}/>
    ])
  }
  return []
}


export function ScrapWidgetViews(props: { scrapWidgets$: BehaviorSubject<ScrapWidget[]> })
{
  // ---- Sort the ScrapWidgets and map them to ScrapViews
  const scrapWidgetsSorted$ = props.scrapWidgets$.pipe(
    sortingMap(
      w => w.scrap.zeta$.pipe(distinctUntilChanged()),
      compareArray
    ),
  )
  const widgets = useObservable(scrapWidgetsSorted$, [], [props.scrapWidgets$])
  return (
    <>
    { _.flatten(widgets.map(viewsForScrapWidget)) }
    </>
  )
}