import {BehaviorSubject} from "rxjs";
import {TextSpec} from "../../collage_editor/models/text_spec";
import {map} from "rxjs/operators";
import {TextPanelWidget} from "./text_panel_widget";

export class TextEntryPanelWidget extends TextPanelWidget {
  key = 'entry'

  // ---- Outputs
  t$: BehaviorSubject<string>

  constructor(spec: TextSpec) {
    super({t: spec.t});
    this.t$ = new BehaviorSubject<string>(spec.t || "")

    this.connecting(
      this.t$.pipe(map(t => new TextSpec({t}))),
      this.spec$
    )
  }
}