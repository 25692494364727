import {Collage} from "../models/collage"
import {Animatorer, Point, Size, Widget} from "@piccollage/cbjs"
import {Positioning} from "../../toolkit/models/positioning";
import {BehaviorSubject, Subject} from "rxjs";

export class BackdropWidget extends Widget {

  // ---- Properties
  size$: BehaviorSubject<Size>
  positioning: Animatorer<Positioning>

  // ---- Input
  draggedFiles$: Subject<{ point: Point, files: File[] }>

  constructor(readonly collage: Collage, positioning: Positioning = new Positioning()) {
    super()
    console.log("++++ BackdropWidget constructor")

    this.size$ = new BehaviorSubject(collage.size$.value)
    this.positioning = new Animatorer<Positioning>(positioning)
    this.draggedFiles$ = new Subject()

    this.connecting(collage.size$, this.size$)
  }

}
