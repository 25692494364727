import {ProgressWidget} from "../ui/progress_widget"
import {CircularProgress} from "@material-ui/core"
import React, {CSSProperties} from "react"

export function ProgressView(props: { progressWidget: ProgressWidget })
{
  const size = 40
  const style: CSSProperties = {
    position: 'absolute',
    left: props.progressWidget.point.x - size/2,
    top:  props.progressWidget.point.y - size/2,
  }
  return (
    <div style={style}>
      <CircularProgress size={size} />
    </div>
  )
}