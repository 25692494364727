import * as React from "react";
import AuthOverlayWidget from "../ui/auth_overlay_widget";
import {LoginView, useBehaviorSubject} from "@piccollage/cbjs";
import {Dialog} from "@material-ui/core";

export function AuthOverlayView(props: {widget: AuthOverlayWidget}) {
  const shouldDisplay = useBehaviorSubject(props.widget.shouldDisplay$);
  const loginWidget = props.widget.loginWidget;

  return (
    <div>
      {shouldDisplay &&
        <Dialog open={true} onBackdropClick={_ => loginWidget && loginWidget.loginRequest.firebaseUser$.complete()}>
            <LoginView widget={loginWidget} />
        </Dialog>
      }
    </div>
  );
}
