import * as React from "react";
import {useCallback, useRef} from "react";
import {ViewerWidget} from "../../collage_editor/ui/viewer_widget";
import {CollagerCoreView} from "../../collage_editor/views/collage_core_view";
import styles from "./viewer_view.module.scss";
import {RefStoreContext, TTouchGesture, useGestures, useNewRefStore, useObserving, Widget} from "@piccollage/cbjs";
import {refCountDelay} from "rxjs-etc/operators";
import {publishReplay} from "rxjs/operators";
import {addTargetsToGesture} from "./view_util";

export function ViewerView(props: { widget: ViewerWidget }) {
  console.log("++++ ViewerView render")

  const refStore = useNewRefStore<Widget, HTMLElement>()
  const refViewer = useRef<HTMLDivElement>(null)

  // ---- Setup user gestures, inserting Widget information from the refStore
  const gesture$ = useCallback((gesture: TTouchGesture): void => {
    const g$ = gesture.pipe(
      addTargetsToGesture(refStore),
      publishReplay(),
      refCountDelay(100),   // Use a delayed freeing for additional subscriptions
    )
    props.widget.gesture$.next(g$)
  }, [props.widget, refStore])
  useGestures(refViewer, gesture$)

  // ---- Open links
  useObserving(props.widget.linkUrl$, url => {
    window.open(url, "_blank")
  })


  return (
    <RefStoreContext.Provider value={refStore}>
      <div ref={refViewer} className={ styles.ViewerView }>
        <CollagerCoreView widget={props.widget.collageCoreWidget} />
      </div>
    </RefStoreContext.Provider>
  )

}
