import * as React from "react";
import styles from "./side_bar_view.module.scss";
import {Subject} from "rxjs";
import {convertMouseToTouchEvent, TTap, useBehaviorSubject} from "@piccollage/cbjs";
import {SideBarWidget} from "../ui/side_bar_widget";

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import {AllCollagesWidget} from "../ui/all_collages_widget";
import {TrashedCollagesWidget} from "../ui/trashed_collages_widget";

export function SideBarView(props: {widget: SideBarWidget}) {
  const currentTabWidget = useBehaviorSubject(props.widget.currentTabWidget$)

  return (
    <div className={styles.SideBarView}>
      <SideBarTab
        title={"All Cards"}
        icon={<AllInboxIcon className={styles._icon}/>}
        isSelected={currentTabWidget instanceof AllCollagesWidget}
        subject={props.widget.tappedAllCollagesTab$}
      />
      {/*<SideBarTab*/}
      {/*  title={"Favorites"}*/}
      {/*  icon={<FavoriteBorderIcon className={styles._icon}/>}*/}
      {/*  isSelected={false}*/}
      {/*  subject={props.widget.tappedFavoritesTab$}*/}
      {/*/>*/}

     <SideBarTab
       title={"Trash"}
       icon={<DeleteOutlineIcon className={styles._icon}/>}
       isSelected={currentTabWidget instanceof TrashedCollagesWidget}
       subject={props.widget.tappedTrashTab$}
     />
    </div>
  )
}

export function SideBarTab(props: {
                              title: string,
                              icon: JSX.Element,
                              isSelected: boolean,
                              subject: Subject<TTap>})
{
  const onClick = (output$: Subject<TTap>) => (e: React.MouseEvent) => {
    return output$.next(new TTap(convertMouseToTouchEvent(e).touches[0]));
  }

  return (
    <div
      className={
        props.isSelected ?
          styles._tab__selected :
          styles._tab
      }
      onClick={onClick(props.subject)}
    >
      {props.icon}
      <div>
        {props.title}
      </div>
    </div>
  )
}
