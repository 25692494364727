import {Subject} from "rxjs";
import {EditorWidget} from "../../collage_editor/ui/editor_widget";
import {Collage} from "../../collage_editor/models/collage";
import {manipulateStartNewCollage} from "../manipulators/manipulate_start_new_collage";
import {AppWidgetContext} from "./app_widget";
import {manipulateShareCollage} from "../manipulators/manipulate_share_collage";
import {manipulateUploadImages} from "../../collage_editor/manipulators/manipulate_upload_images";
import {Widget} from "@piccollage/cbjs";
import {manipulateAddText} from "../../collage_editor/manipulators/manipulate_add_text";
import {manipulateToggleStickers} from "../../collage_editor/manipulators/manipulate_toggle_stickers";
import {manipulateSketchColor} from "../../collage_editor/manipulators/manipulate_sketch_color";
import {manipulateNavigateCollageViewer} from "../manipulators/manipulate_navigate_collage_viewer";
import {Accessible} from "./accessible";
import {Person} from "../models/person";
import {manipulateNavigateTopWidget} from "../manipulators/manipulate_navigate_top_widget";
import {MyCollagesWidget} from "../../my_collages/ui/my_collages_widget";

export class CreatorWidget extends Widget implements Accessible {

  // ---- Interface - Accessible
  isAccessible(currentPerson: Person | null) {
    return !!currentPerson && this.collage.ownerId === currentPerson.id;
  }

  // ---- Inputs
  tappedNewCollage$  = new Subject<boolean>()
  tappedSaveCollage$ = new Subject<boolean>()
  tappedViewCollage$ = new Subject<boolean>()
  tappedUploadScrap$ = new Subject<boolean>()
  tappedUploadFace$  = new Subject<boolean>()
  tappedAddText$     = new Subject<boolean>()
  tappedStickers$    = new Subject<boolean>()
  tappedBackground$  = new Subject<boolean>()
  tappedBackButton$ = new Subject<boolean>()

  // ---- Properties
  editorWidget: EditorWidget

  // ---- Sketch
  tappedSketchColor$ = new Subject<boolean>()

  // ---- Lifecycle
  constructor(readonly collage: Collage) {
    super()
    console.log("++++ CreatorWidget constructor")

    this.editorWidget = this.legate(() => new EditorWidget(collage))
    
    // ---- Create the new EditorWidget and connect it
    this.triggering(this.tappedNewCollage$,
      _ => manipulateStartNewCollage(
        this.contexter.use(AppWidgetContext).appWidget)
    )
    this.triggering(this.tappedSaveCollage$,
      _ =>
        manipulateShareCollage(
          this.contexter.use(AppWidgetContext).appWidget,
          this.editorWidget)
    )
    this.triggering(this.tappedViewCollage$,
      _ =>
        manipulateNavigateCollageViewer(
          this.contexter.use(AppWidgetContext).appWidget,
          collage.id)
    )
    this.triggering(this.tappedUploadScrap$,
      _ => manipulateUploadImages(this.editorWidget, false, null, false))
    this.triggering(this.tappedUploadFace$,
      _ => manipulateUploadImages(this.editorWidget, false, null, true))
    this.triggering(this.tappedAddText$,
      _ => manipulateAddText(this.editorWidget,
        this.editorWidget.collage.size$.value.toPoint().scale(0.5))
    )
    this.triggering(this.tappedStickers$,
      _ => manipulateToggleStickers(this.editorWidget))
    this.triggering(this.tappedBackground$,
      _ => manipulateUploadImages(this.editorWidget, true))

    this.triggering(this.tappedSketchColor$,
      _ => manipulateSketchColor(this.editorWidget))

    this.triggering(this.tappedBackButton$,
      _ => manipulateNavigateTopWidget(
        this.contexter.use(AppWidgetContext).appWidget,
        this.contexter.use(AppWidgetContext).appWidget.legate(() => new MyCollagesWidget())
      )
    )
  }
}