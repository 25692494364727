import {BehaviorSubject, combineLatest, merge, of} from "rxjs";
import {cachedArrayMapper, Widget} from "@piccollage/cbjs";
import {Sticker, StickerCategory, StickerRepo} from "../../collage_editor/models/stickers";
import {flatMap, map, switchMap} from "rxjs/operators";
import {ChooserItemWidget, ChooserWidget} from "../../toolkit/lib/chooser";

export class StickerChooserWidget extends Widget {

  // LEARN: Abstract
  // (1) getting data network
  // (2) model
  // (3) ux representation model
  // (4) ux *interaction*
  // (5) view implementation

  // ---- Sub-choosers
  categoryChooser = this.legate(() => new ChooserWidget<StickerCategory>())
  stickerChooser  = this.legate(() => new ChooserWidget<Sticker>())

  // ---- Chosen
  chosenCategory$ = new BehaviorSubject<StickerCategory|null>(null)

  // --------------------------------------------------------------------------
  // ---- Constructor
  constructor(readonly stickerRepo: StickerRepo) {
    super()

    // ---- Connect categoryChooser items
    const categoryMapper = cachedArrayMapper<StickerCategory, StickerCategory, ChooserItemWidget<StickerCategory>>(
      category => category,
      category => this.legate(() => new ChooserItemWidget(this.categoryChooser, category)),
    )
    this.connecting(
      stickerRepo.categories$().pipe(
        map(categoryMapper)),
      this.categoryChooser.items$
    )

    // ---- Connect items$ tapped
    this.connecting(
      this.categoryChooser.items$.pipe(
        flatMap(widgets =>
          merge(...widgets.map(w =>
            w.tapped$.pipe(map(_ => [w]))
          ))
        ),
      ),
      this.categoryChooser.itemsChosen$
    )

    // ---- Connect chosenCategory$.
    //      Combine so we can choose the first one if none are chosen
    //
    this.connecting(
      combineLatest([
        this.categoryChooser.itemsChosen$,
        this.categoryChooser.items$
      ]).pipe(
        map(([itemsChosen, items]) =>
          itemsChosen[0] || items[0] || null),
        map(categoriesWidget => categoriesWidget && categoriesWidget.model),
      ),
      this.chosenCategory$
    )

    // ---- Connect stickerChooser items
    const stickerMapper = cachedArrayMapper<Sticker, Sticker, ChooserItemWidget<Sticker>>(
      sticker => sticker,
      sticker => this.legate(() => new ChooserItemWidget(this.stickerChooser, sticker)),
    )
    this.connecting(
      this.chosenCategory$.pipe(
        switchMap(category => {
          return category ? category.stickers$ : of([])
        }),
        map(stickerMapper),
      ),
      this.stickerChooser.items$
    )


  }

}


// ==========================================================================

