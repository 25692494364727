import {Collage} from "../../collage_editor/models/collage";
import {commandDeleteCollage} from "../../collage_firebase/command_firestore";
import {AppWidget} from "../../app/ui/app_widget";
import {finalize} from "rxjs/operators";

export function manipulateDeleteCollage(appWidget: AppWidget, collage: Collage) {
  appWidget.bulletin$.next("Deleting collage...");
  return commandDeleteCollage(collage)
    .execution()
    .pipe(
      finalize(() => appWidget.bulletin$.next(null)),
    )
}