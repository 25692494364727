import {EditorWidget} from "../ui/editor_widget"
import {defer, Observable} from "rxjs"
import {flatMap, take, tap} from "rxjs/operators"
import {UploadWidget} from "../../toolkit/ui/upload_widget"
import {Point} from "@piccollage/cbjs";
import {ImageScrapWidget} from "../ui/image_scrap_widget";
import {manipulateAddFiles} from "./manipulate_add_files";
import {Positioning} from "../../toolkit/models/positioning";
import {Collage} from "../models/collage";

export function manipulateUploadImages(editorWidget: EditorWidget,
                                       isBackground: boolean = false,
                                       p: Positioning|Point|null = null,
                                       isFace: boolean = false)
  : Observable<ImageScrapWidget|Collage> {

  console.log("++++ manipulateUploadImages uploadWidget", p)

  // IMPORTANT: Because the UploadWidget under certain conditions (e.g. no
  // file uploaded) doesn't complete the `files$` there is a possiblity
  // that this manipulator doesn't complete.

  // ---- Create and setup the UploadWidget and get file$ from it
  let uploadWidget: UploadWidget|null = null
  return defer(() => {
    uploadWidget = editorWidget.legate(() => new UploadWidget())
    editorWidget.uploadWidget$.next(uploadWidget)
    return uploadWidget.files$
  }).pipe(

    // ---- Make sure manipulator stops when we get one set
    //      of files (so progress gets stopped).
    take(1),

    // ---- When finished, get rid of UploadWidget
    tap(() => {
      if (editorWidget.uploadWidget$.value === uploadWidget)
        editorWidget.uploadWidget$.next(null)
      uploadWidget = null
    }),

    // ---- Upload and create ImageScraps
    flatMap(files => manipulateAddFiles(editorWidget, files, isBackground, p, isFace)),

  )

}

