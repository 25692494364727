import {combineLatest, Observable, of} from "rxjs";
import {catchError, distinctUntilChanged, first, map, shareReplay} from "rxjs/operators";
import {commandCreatePerson} from "../commands/commands_firestore";
import {ID, Person} from "../models/person";
import {
  cachedArrayMapper,
  cachedMapper, combineLatestWithEmpty,
  DocRef,
  DocSnap,
  fieldToString,
  firestoreSyncCollectionArray,
  firestoreSyncDocument,
  swapMap,
  taplog
} from "@piccollage/cbjs";
import firebase from "firebase";
import {Collage} from "../../collage_editor/models/collage";
import {firestoreCollages, syncedCollage$} from "../../collage_firebase/collage_firestore";

export type User = firebase.User;

// -----------------------------------------------------------------------------
// Reference shortcuts

export function firestorePersons() {
  return firebase.firestore().collection('persons')
}


// -----------------------------------------------------------------------------
// Mappers

export const PersonMapper: (personId: ID) => Observable<Person> =
  cachedMapper((personId: ID) => personId,
    (personId: ID) =>
      syncedPerson$(firestorePersons().doc(personId)).pipe(
        shareReplay(1),
      )
  )


export function firebaseUserToPersonMapper()
  : (user: User) => Observable<Person> {
  return ((user: User) => {
    const personId = `f:${user.uid}`
    const person$ = PersonMapper(personId)
    return person$.pipe(
      catchError(error => {
        console.error("++++ PersonMapper failed", error)
        return commandCreatePerson(personId, user.displayName, user.photoURL).execution()
      })
    )
  })
}


// -----------------------------------------------------------------------------

export function syncedPerson$(ref: DocRef) {

  console.log("++++ syncedPerson$", ref)

  return firestoreSyncDocument(ref).pipe(
    // ---- Extract data
    map((snap: DocSnap) => {
      const data = snap.data() || {}
      console.log("++++ syncedPerson$ data", data)

      const name = fieldToString(data.name, undefined) || null
      const imageUrl = fieldToString(data.imageUrl, undefined) || null

      return {snap, name, imageUrl}
    }),

    // ---- Create a model from the first set of data and recombine
    source => {
      source = source.pipe(shareReplay(1))
      return source.pipe(
        first(),
        map(({snap, name, imageUrl}) => {
          const model = new Person(snap.id, name, imageUrl)

          model.updating(
            source.pipe(map(_ => _.name)),
            model.displayName$
          )
          model.updating(
            source.pipe(map(_ => _.imageUrl)),
            model.imageUrl$
          )

          return model
        })
      )
    },
    // ---- Share (otherwise different invocations return different instances
    shareReplay(1)
  )
}

export function syncedPersons$(personIds: ID[])
  : Observable<Person[]> {
  const personIds$ = personIds.map(PersonMapper)
  return personIds$.length > 0 ? combineLatest(personIds$) : of([])
}

const collagesMapper = cachedArrayMapper(
  collageSnap => collageSnap.id,
  (collageSnap: DocSnap) =>
    syncedCollage$(collageSnap.ref, false).pipe(
      distinctUntilChanged(),
      shareReplay(1)

    )
)

export function syncedPersonCollages$(personId: ID, is_trashed: boolean = false)
  : Observable<Collage[]>
{
  console.log("++++ syncedPersonCollages$")

  const query =  (firestoreCollages()
    .where("owner_id", "==", personId)
    .where("is_trashed", "==", is_trashed)
    .orderBy(is_trashed? "trashed_at" : "modified_at", "desc"))

  return firestoreSyncCollectionArray(query).pipe(
    taplog(">>>> firestoreSyncCollectionArray ---- "),
    map(collagesMapper),
    swapMap(arrayObs => combineLatestWithEmpty(arrayObs))
  );
}