import {defer, of} from "rxjs";
import {MyCollagesWidget} from "../ui/my_collages_widget";
import {Widget} from "@piccollage/cbjs";

export function manipulateCurrentTab(myCollagesWidget: MyCollagesWidget, tabWidget: Widget) {
  console.log("++++ manipulateCurrentTab", tabWidget);

  return defer(() => {
    myCollagesWidget.currentTabWidget$.next(tabWidget)
    return of(tabWidget)
  })
}