import React, {FunctionComponent} from "react";
import cx from "classnames";
import sprite from "../assets/icons/sprites/symbol/svg/sprite.symbol.svg";
import styles from "./Icon.module.scss";

interface IconProps {
  name: string;
  size?: number;
  fill?: string;
  title?: string;
  className?: string;
  dataTracking?: string;
}

const Icon: FunctionComponent<IconProps> = ({
  name,
  size,
  fill,
  title,
  className,
  dataTracking
}) => {
  return (
    <svg
      className={cx(styles.root, className)}
      width={size}
      height={size}
      fill={fill}
      role="img"
      aria-label={title}
      data-tracking={dataTracking}
    >
      {title && <title>{title}</title>}
      <use className={styles.use} xlinkHref={`${sprite}#${name}`} />
    </svg>
  );
};

Icon.defaultProps = {
  size: 40,
  fill: "#fff",
  title: ""
};

export default Icon;
