import {generateId, Widget} from "@piccollage/cbjs"
import {Subject} from "rxjs"

export class UploadWidget extends Widget {
  id = generateId()
  files$ = new Subject<File[]>()
  opened = false

  constructor() {
    super()
    console.log("++++ UploadWidget constructor")
  }

}