import {BehaviorSubject, combineLatest, of} from "rxjs";
import {ContextedDomainer, generateId, ID, Rect, Size} from "@piccollage/cbjs";
import {Positioning} from "../../toolkit/models/positioning";
import {COLLAGE_URL, CollageContext} from "./collage";
import {map, switchMap} from "rxjs/operators";
import {ZETA, ZETA_DEFAULT} from "../../toolkit/lib/zeta";

export const SCRAP_DEFAULT_BASE_SIZE = new Size(200, 200)

export class Scrap extends ContextedDomainer {

  // ---- Properties
  positioning$: BehaviorSubject<Positioning> = new BehaviorSubject(new Positioning())
  sizeBase$: BehaviorSubject<Size> = new BehaviorSubject<Size>(Size.ZERO)
    // Note that for most Scraps, only the *width* is used to determine the
    // dimensions, and the height is adjusted accordingly.
  padding$ = new BehaviorSubject<Rect|null>(null)
    // Normalized 0-1.0, sub-area of the Scrap that's actually used
  linkUrl$ = new BehaviorSubject<COLLAGE_URL|null>(null)

  zeta$     = new BehaviorSubject<ZETA>(ZETA_DEFAULT)
  parentId$ = new BehaviorSubject<ID|null>(null)
  parent$   = new BehaviorSubject<Scrap|null>(null)

  // ---- Object lifecycle
  constructor(
    readonly id: ID = generateId(),
    sizeBase: Size = Size.ZERO,
    positioning: Positioning = new Positioning()
  ) {
    super()
    console.log("++++ Scrap constructor")
    this.sizeBase$.next(sizeBase)
    this.positioning$.next(positioning)

    // ---- Connect for parent
    const collageContext = this.contexter.get(CollageContext)
    console.log(">>>> collageContext", collageContext)
    if (collageContext) {
      this.connecting(
        combineLatest([this.parentId$, collageContext.collage.scraps$]).pipe(
          map(([parentId, scraps]) =>
            scraps.find(scrap => scrap.id === parentId) || null)
        ),
        this.parent$
      )
    }

    // ---- Connect for Z and ZETA
    const zetaParent$ = this.parent$.pipe(
      switchMap(parent => parent ? parent.zeta$ : of(ZETA_DEFAULT))
    )
    this.connecting(
      combineLatest([ zetaParent$, this.positioning$.pipe(map(p => p.z))]).pipe(
        map(([ zetaParent, z]) => [...zetaParent, z])
      ),
      this.zeta$
    )

  }

  toString(): string {
    return `Scrap(sizeBase: ${this.sizeBase$.value}, positioning: ${this.positioning$.value})`
  }
}