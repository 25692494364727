import {ScrapWidget} from "../ui/scrap_widget"
import {Observable} from "rxjs"
import {map} from "rxjs/operators"
import {Collage} from "../models/collage";
import {UndoContext} from "@piccollage/cbjs";
import {commandUpdateScrapPositioning} from "../../collage_firebase/command_firestore_scrap";
import {Z} from "../../toolkit/models/positioning";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateScrapZToFront(collage: Collage, scrapWidget: ScrapWidget)
  : Observable<Z> {

  // ---- Change model
  const z = collage.scrapZMax() + 1
  console.log("++++ manipulateScrapZToFront", z)

  return commandUpdateScrapPositioning(scrapWidget.scrap, { z })
    .tap(c => scrapWidget.contexter.get(UndoContext)?.push(c))
    .execution()
    .pipe(EditorContext.findFrom(scrapWidget)?.modifies() || NOOP)
    .pipe(map(_ => z))
}