import {taplog, Widget} from "@piccollage/cbjs";
import {BehaviorSubject, combineLatest, merge, Subject} from "rxjs";
import {Font, FontProtocol} from "../../toolkit/models/font";
import {TextSpec} from "../../collage_editor/models/text_spec";
import {flatMap, map} from "rxjs/operators";
import {TextPanelWidget} from "./text_panel_widget";

export const Fonts: Font[] = [
  new Font("Arial"),
  new Font("Courier"),
  new Font("Verdana"),
  new Font("Palatino"),
  new Font("Georgia"),
  new Font("Kaushan Script",          "Kaushan",        FontProtocol.google),
  new Font("Playfair Display",        "Playfair",       FontProtocol.google, ":italic"),
  new Font("Libre Baskerville",       "Baskerville",    FontProtocol.google),
  new Font("Bebas Neue",              "BebasNeue",     FontProtocol.google),
  new Font("Shrikhand",               "Shrikhand",      FontProtocol.google),
  new Font("Oswald",                  "Oswald",         FontProtocol.google),
  new Font("Dawning of a New Day",    "DawningNewDay",  FontProtocol.google),
  new Font("Satisfy",                 "Satisfy",        FontProtocol.google),
  new Font("IM Fell English",         "FellEnglish",   FontProtocol.google),
  new Font("IM Fell English SC",      "FellEnglish",   FontProtocol.google),
  new Font("Pacifico",                "Pacifico",       FontProtocol.google),
  new Font("Quicksand",               "Quicksand",      FontProtocol.google),
  new Font("Lovers Quarrel",          "LoversQuarrel", FontProtocol.google),
  new Font("Barrio",                  "Barrio",         FontProtocol.google),
  new Font("Amatic SC",               "AmaticSC",      FontProtocol.google),
  new Font("Chelsea Market",          "ChelseaMkt",     FontProtocol.google),
  new Font("Bangers",                 "Bangers",        FontProtocol.google),
  new Font("Montserrat",              "Montserrat",     FontProtocol.google),
  new Font("Yeseva One",              "Yeseva One",     FontProtocol.google),
  new Font("Lobster",                 "Lobster",        FontProtocol.google),
  new Font("Dancing Script",          "DancingScript", FontProtocol.google),
  new Font("Special Elite",           "SpecialElite",   FontProtocol.google),
  new Font("Cinzel",                  "Cinzel",         FontProtocol.google),
  new Font("Rock Salt",               "RockSalt",      FontProtocol.google),
  new Font("Rochester",               "Rochester",      FontProtocol.google),
  new Font("Chewy",                   "Chewy",          FontProtocol.google),
  new Font("Boogaloo",                "Boogaloo",       FontProtocol.google),
  new Font("Graduate",                "Graduate",       FontProtocol.google),
  new Font("Grand Hotel",             "GrandHotel",    FontProtocol.google),
  new Font("Rye",                     "Rye",            FontProtocol.google),
  new Font("Limelight",               "Limelight",      FontProtocol.google),
  new Font("Ewert",                   "Ewert",          FontProtocol.google),
  new Font("Bevan",                   "Bevan",          FontProtocol.google),
  new Font("Caesar Dressing",         "CaesarDressing", FontProtocol.google),
  new Font("Waiting for the Sunrise", "Sunrise",        FontProtocol.google),
  new Font("Londrina Shadow",         "Londrina",       FontProtocol.google),
  new Font("Fredericka the Great",    "Fredericka",     FontProtocol.google),
  new Font("UnifrakturMaguntia",      "Unifraktur",     FontProtocol.google),
  new Font("Clicker Script",          "ClickerScript", FontProtocol.google),
  new Font("Bungee Shade",            "Bungee",         FontProtocol.google),
  new Font("Eagle Lake",              "EagleLake",      FontProtocol.google),
  new Font("Anton",                   "Anton",          FontProtocol.google),

  // ---- These are not really websafe (not on Safari iOS)
  // new Font("Roboto"),
  // new Font("Garamond"),
  // new Font("Times New Roman", "Times"),
  // new Font("Comic Sans MS", "Comic"),
  // new Font("Impact"),
]

// =======================================================================

export class TextFontPanelWidget extends TextPanelWidget {
  key = 'font'

  // ---- Outputs
  font$: BehaviorSubject<Font | undefined>
  fontWidgets$: BehaviorSubject<TextFontWidget[]>
  fontWidgetSelected$ = new BehaviorSubject<TextFontWidget | null>(null)

  // ---- Fonts

  constructor(spec: TextSpec) {
    super({font: spec.font});

    // ---- Create widgets
    this.fontWidgets$ = new BehaviorSubject(
      Fonts.map(f =>
        this.legate(() => new TextFontWidget(f))
      )
    )

    // ---- Output
    this.font$ = new BehaviorSubject<Font | undefined>(spec.font)
    this.connecting(
      this.font$.pipe(map(font => new TextSpec({font}))),
      this.spec$
    )

    // ---- Connect widgets and their Fonts to output when tapped
    this.connecting(
      this.fontWidgets$.pipe(
        flatMap(fontWidgets =>
          merge(...fontWidgets.map(fontWidget =>
            fontWidget.tapped$.pipe(
              map(_ => fontWidget.font)
            )
          ))
        ),
        taplog("++++ TextPanelWidget font"),
      ),
      this.font$
    )

    // ---- Connect selected
    this.connecting(
      combineLatest([this.font$, this.fontWidgets$]).pipe(
        map(([font, fontWidgets]) =>
          fontWidgets.find(w => w.font.isEqual(font)) || null
        )
      ),
      this.fontWidgetSelected$
    )

  }
}

export class TextFontWidget extends Widget {
  tapped$ = new Subject<boolean>()

  constructor(readonly font: Font) {
    super()
  }
}