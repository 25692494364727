import {TextPanelWidget} from "./text_panel_widget";
import {BehaviorSubject, combineLatest} from "rxjs";
import {Color} from "../../toolkit/models/color";
import {TextColorDefault, TextSpec} from "../../collage_editor/models/text_spec";
import {map} from "rxjs/operators";

// const colorValues = [ '00', '44', '88', 'cc', 'ff' ]
const colorValues = [ '00', '66', 'cc', 'ff' ]
function shift(n: number, mod: number = 0) {
  const L = colorValues.length
  const v = Math.floor((n / (L ** mod)) % L)
  return colorValues[v]
}

abstract class TextColorPanelBase extends TextPanelWidget {

  // ---- Outputs
  color$ = new BehaviorSubject<Color|null>(null)

  colorsLength() { return colorValues.length ** 3 }
  color(index: number): Color {
    return new Color(`#${ shift(index, 0) }${ shift(index, 1) }${ shift(index, 2) }`)
  }
}

export class TextColorPanelWidget extends TextColorPanelBase {
  key = 'color'

  constructor(spec: TextSpec) {
    super(spec);
    this.color$.next(spec.color || TextColorDefault)
    this.connecting(this.color$.pipe(
      map(color => new TextSpec({ color: color ? color : Color.TRANSPARENT })),
      ),
      this.spec$)
  }
}

export class TextStrokePanelWidget extends TextColorPanelBase {
  key = 'stroke'

  width$ = new BehaviorSubject(0)

  constructor(spec: TextSpec) {
    super(spec);
    this.color$.next(spec.colorStroke || null)
    this.width$.next(spec.widthStroke || 0)
    this.connecting(
      combineLatest([ this.color$, this.width$ ]).pipe(
        map(([ color, width ]) =>
          new TextSpec({
            colorStroke: color ? color : null,
            ...( width ? { widthStroke: width } : {} ),
          })
        )
      ),
      this.spec$)
  }
}

export class TextColorBackgroundPanelWidget extends TextColorPanelBase {
  key = 'color_background'

  constructor(spec: TextSpec) {
    super(spec);
    this.color$.next(spec.colorBackground || null)
    this.connecting(this.color$.pipe(
      map(color => new TextSpec({ colorBackground: color ? color : null }))
      ),
      this.spec$)
  }
}
