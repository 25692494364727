import {BehaviorSubject, Subject} from "rxjs";
import {AppWidgetContext} from "./app_widget";
import {TTap, Widget} from "@piccollage/cbjs";
import {manipulateLogin} from "../manipulators/manipulate_login";
import {manipulateLogout} from "../manipulators/manipulate_logout";
import {Person} from "../models/person";
import {ButtonWidget} from "./button_widget";
import {map} from "rxjs/operators";

export class HeaderWidget extends Widget {
  // ---- Inputs
  loginButtonTapped$ = new Subject<TTap>()
  logoutButtonTapped$ = new Subject<TTap>()
  currentPerson$ = new BehaviorSubject<Person | null>(null);

  // ---- Outputs
  loginButtonWidget$ = new BehaviorSubject<ButtonWidget | null>(null)
  logoutButtonWidget$ = new BehaviorSubject<ButtonWidget | null>(null)

  constructor() {
    super();

    this.connecting(
      this.contexter.use(AppWidgetContext).appWidget.currentPerson$,
      this.currentPerson$
    );
    this.connectAuthButtons();
    this.triggerAuthButtons();
  }

  connectAuthButtons() {
    const buttonWidget = new ButtonWidget();
    this.connecting(
      this.currentPerson$.pipe(
        map(p => (p ? null : this.legate(() => buttonWidget)))
      ),
      this.loginButtonWidget$
    );

    this.connecting(
      this.currentPerson$.pipe(
        map(p => (p ? this.legate(() => new ButtonWidget()) : null))
      ),
      this.logoutButtonWidget$
    );
  }

  triggerAuthButtons() {
    const appWidget = this.contexter.use(AppWidgetContext).appWidget;

    // ---- Create and connect the LoginWidget
    this.triggering(this.loginButtonTapped$,
      _ => manipulateLogin(appWidget)
    )

    // ---- Handle logout click
    this.triggering(this.logoutButtonTapped$,
      _ => manipulateLogout(appWidget)
    )
  }
}