import {TTap, UndoContext} from "@piccollage/cbjs"
import {ScrapWidget} from "../ui/scrap_widget"
import {Observable} from "rxjs"
import {Z} from "../../toolkit/models/positioning"
import {map} from "rxjs/operators";
import {Collage} from "../models/collage";
import {commandUpdateScrapPositioning} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateScrapZToBack(tap: TTap, collage: Collage, scrapWidget: ScrapWidget)
  : Observable<Z> {

  const z = collage.scrapZMin() - 1
  console.log("++++ manipulateScrapZToBack", z)

  // ---- Change model
  return commandUpdateScrapPositioning(scrapWidget.scrap, { z })
    .tap(c => scrapWidget.contexter.get(UndoContext)?.push(c))
    .execution()
    .pipe(EditorContext.findFrom(scrapWidget)?.modifies() || NOOP)
    .pipe(map(_ => z))
}