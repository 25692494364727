import {ScrapWidget} from "./scrap_widget";
import {BehaviorSubject, interval, Observable, Subject} from "rxjs";
import {TextScrap} from "../models/text_scrap";
import {map, switchMap, throttle} from "rxjs/operators";
import {scan2} from "@piccollage/cbjs";
import {DotWidget} from "./dot_widget";
import {TextSpec} from "../models/text_spec";


export class TextDotWidget extends DotWidget {}

class CounterSubject extends BehaviorSubject<number>
{
  constructor() { super(0) }
  count(): Observable<number> {
    return this.pipe(scan2(0, (acc, n) => acc + n))
  }
}

export class TextScrapWidget extends ScrapWidget {

  // ---- Spec (output scrap & input from manipulator)
  spec$          = new BehaviorSubject(TextSpec.DEFAULT)
  spec_in$       = new Subject<TextSpec>()

  // ---- During manipulation (don't update size during manipulation)
  manipulatorsCounter$ = new CounterSubject()

  // ---- Overrides
  protected createDotWidgets(): DotWidget[] {
    return [
      ...super.createDotWidgets(),
      this.legate(() => new TextDotWidget(this))
    ]
  }

  // ---- Lifecycle
  constructor(readonly textScrap: TextScrap) {
    super(textScrap);
    console.log("++++ TextScrapWidget constructor")

    // ---- Keep track of manipulations
    const isManipulating$ = this.manipulatorsCounter$.pipe(
      map(n => n > 0)
    )

    // ---- Connect to either scrap or manipulator
    this.connecting(
      isManipulating$.pipe(
        switchMap(isManipulating =>
          isManipulating ? this.spec_in$ : textScrap.spec$
        ),
        // Add a throttle to prevent "flashing" when we switch between model and manipulator
        throttle(_ => interval(200), { leading: false, trailing: true })
      ),
      this.spec$
    )

  }

}
