import {Point, Size, TTouch, TTouchEvent} from "@piccollage/cbjs";
import {Positioning} from "./positioning";

export const GESTURE_MIN_DRAG = 1
export const GESTURE_LONG_PRESS_TIME = 500

export
function repositionTouchEvent(event: TTouchEvent, positioning: Positioning)
  : TTouchEvent
{
  return new TTouchEvent(
    event.touches.map(t => repositionTouch(t, positioning)),
    event.t,
    event.platform
    )
}

export
function repositionTouch(touch: TTouch, positioning: Positioning)
  : TTouch
{
  return new TTouch(
    touch.identifier,
    repositionPoint(touch.point, positioning),
    touch.button,
    touch.targetings
    )
}

export
function repositionPoint(point: Point, positioning: Positioning)
  : Point
{
  return point.subtract(positioning.point)
      .rotate(-positioning.rotation)
      .scale(1/positioning.scale)
}

export function positionSizeWithin(outer: Size, inner: Size): Positioning {
  const scale = Math.min(outer.width  / inner.width,
                         outer.height / inner.height)
  const point = outer.scale(0.5).toPoint()
  return new Positioning(point, 0, scale)
}

