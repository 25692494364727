import {COLLAGE_URL} from "../../collage_editor/models/collage";
import {defer, Observable} from "rxjs";
import {ViewerWidget} from "../../collage_editor/ui/viewer_widget";

export function manipulateNavigateScrapLink(viewerWidget: ViewerWidget, url: COLLAGE_URL)
  : Observable<COLLAGE_URL>
{
  return defer(() => {
    viewerWidget.linkUrl$.next(url)
    return url
  })
}