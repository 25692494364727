import {BehaviorSubject, Observable, ReplaySubject, Subject} from "rxjs";
import {COLLAGE_URL, COLLAGE_URL_DEFAULT} from "../models/collage";
import {ImageInfo} from "../../toolkit/lib/image";
import {distinctUntilChanged, filter, map, share} from "rxjs/operators";
import {convertPointFromBoundingBox, filterDefined, Point, Rect} from "@piccollage/cbjs";
import {manipulateAdjustSizeBase} from "../manipulators/manipulate_adjust_size_base";
import {ScrapWidget} from "./scrap_widget";
import {isPointInsidePath, Path} from "../../toolkit/models/path";
import {FaceDetection, FaceLandmarks, FaceLandmarks68, WithFaceLandmarks} from "face-api.js";
import {ImageScrap} from "../models/image_scrap";
import {DotWidget} from "./dot_widget";

export class ClipDotWidget  extends DotWidget {}

export type FaceInfo = WithFaceLandmarks<{ detection: FaceDetection, landmarks: FaceLandmarks }, FaceLandmarks68>
export class FaceRequest {
  face$ = new ReplaySubject<FaceInfo>()
}

export interface Loadable {
  isLoaded$: BehaviorSubject<boolean>
}
export function isLoadable(o: any): o is Loadable {
  return o.isLoaded$ instanceof Observable
}
export class ImageScrapWidget extends ScrapWidget implements Loadable {

  // ---- Properties
  imageSourceUrl$: BehaviorSubject<COLLAGE_URL> = new BehaviorSubject<COLLAGE_URL>(COLLAGE_URL_DEFAULT)
  imageInfo$    = new BehaviorSubject<ImageInfo>({isLoaded: false, size: null})
  clippingPath$ = new BehaviorSubject<Path|null>(null)
  isLoaded$     = new BehaviorSubject<boolean>(false)

  // ---- Requests for the view to process
  faceRequest$  = new Subject<FaceRequest>()

  constructor(readonly imageScrap: ImageScrap) {
    super(imageScrap)

    // Image source input
    this.connecting(
      this.imageScrap.imageSourceUrl$,
      this.imageSourceUrl$
    )

    // Clipping path
    this.connecting(
      this.imageScrap.clippingPath$,
      this.clippingPath$
    )

    // When image is loaded, get the size, and:
    // 1. adjust base size
    // 2. set sizeActual$
    //
    const size$ = this.imageInfo$.pipe(
      distinctUntilChanged(),
      map(imageInfo => imageInfo.size),
      filterDefined(),
      share(),
    )
    this.triggering(size$, size => manipulateAdjustSizeBase(this, size))
    // this.connecting$(size$, this.sizeActual$)

    this.sizeActual$.subscribe(size =>
      console.log(">>>> ImageScrapWidget.sizeActual$", size)
    )

    // Also connect isLoaded
    this.connecting(
      this.imageInfo$.pipe(map(info => info.isLoaded), filter(_ => _)),
      this.isLoaded$
    )

  }

  // ---- Overrides
  protected createDotWidgets(): DotWidget[] {
    return [ ...super.createDotWidgets(), this.legate(() => new ClipDotWidget(this)) ]
  }

  // ---- Override isTarget to account clippingPath
  isTargetPrecise(p: Point, rect: Rect, distance: number = 1): boolean {

    // ---- Default if no clippingPath
    const clippingPath = this.clippingPath$.value
    if (!clippingPath)
      return super.isTargetPrecise(p, rect, distance)

    // ---- Convert to normalized rotated coordinates
    const size = this.sizeActual$.value
    const rotation = this.positioning.value$.value.rotation
    const scrapP = convertPointFromBoundingBox(p, rect,
      rotation,
      size
    )
    const clippingPathScaled = clippingPath.map(p => p.scale(size))
    console.log(">>>> ImageScrapWidget isTargetPrecise clippingPath", scrapP, clippingPathScaled.toArray())
    return isPointInsidePath(scrapP, clippingPathScaled)
  }


}