import {Scrap} from "./scrap";
import {BehaviorSubject} from "rxjs";
import {boundingBoxFromPath, Path} from "../../toolkit/models/path";
import {COLLAGE_URL, COLLAGE_URL_DEFAULT} from "./collage";
import {map} from "rxjs/operators";
import {generateId, ID, Size} from "@piccollage/cbjs";
import {Positioning} from "../../toolkit/models/positioning";

export class ImageScrap extends Scrap {

  // ---- Properties
  imageSourceUrl$ = new BehaviorSubject<COLLAGE_URL>(COLLAGE_URL_DEFAULT)

  clippingPathTimestamp$ = new BehaviorSubject<number|null>(null)
  clippingPath$          = new BehaviorSubject<Path | null>(null)

  constructor(
    readonly id: ID = generateId(),
    sizeBase: Size = Size.ZERO,
    positioning: Positioning = new Positioning()
  ) {
    super(id,  sizeBase, positioning);

    // ---- Calculate bounding box
    this.connecting(
      this.clippingPath$.pipe(
        map(path => path === null ?
          path : boundingBoxFromPath(path)),
      ),
      this.padding$)
  }


}