import {BehaviorSubject, Subject} from "rxjs";
import {filterDefined, scan2, Widget} from "@piccollage/cbjs";
import {TextPanelWidget} from "./text_panel_widget";
import {map, switchMap, takeUntil} from "rxjs/operators";
import {TextSpec, textSpecMerge} from "../../collage_editor/models/text_spec";
import {TextEntryPanelWidget} from "./text_entry_panel_widget";
import {TextFontPanelWidget} from "./text_font_panel_widget";
import {TextAlignmentPanelWidget} from "./text_alignment_panel_widget";
import {TextColorBackgroundPanelWidget, TextColorPanelWidget, TextStrokePanelWidget} from "./text_color_panel_widgets";

export class TextEditorWidget extends Widget
{
  // ---- Output
  spec$: BehaviorSubject<TextSpec> // Completes when we need to close Editor

  panels: TextPanelWidget[]
  panelIndex$ = new BehaviorSubject(0)              // Input/output
  panelActive$: BehaviorSubject<TextPanelWidget>    // Set via connection

  // ---- Inputs
  tappedDone$ = new Subject<boolean>()

  constructor(spec: TextSpec) {
    super();
    console.log("++++ TextEditorWidget constructor", spec)

    // ---- Panels initiation
    this.panels = [
      this.legate(() => new TextEntryPanelWidget(spec) ),
      this.legate(() => new TextFontPanelWidget(spec)  ),
      this.legate(() => new TextAlignmentPanelWidget(spec)  ),
      this.legate(() => new TextColorPanelWidget(spec)  ),
      this.legate(() => new TextStrokePanelWidget(spec)  ),
      this.legate(() => new TextColorBackgroundPanelWidget(spec)  ),
    ]
    this.panelActive$ = new BehaviorSubject<TextPanelWidget>(this.panels[0])
    this.connecting(
      this.panelIndex$.pipe(
        map(i => this.panels[i]),
        filterDefined(),
      ),
      this.panelActive$
    )

    // ---- Output spec initiation
    this.spec$    = new BehaviorSubject<TextSpec>(spec)
    const spec$ =
      // ---- Take the spec from the currently active panel
      this.panelActive$.pipe(
        switchMap(activePanel => activePanel.spec$),

        // ---- Merge it with the current one
        scan2(spec, (cur: TextSpec, activeSpec: TextSpec) => textSpecMerge(activeSpec, cur)),

        // ---- Wait until done (spec$ being closed means this widget is finished)
        takeUntil(this.tappedDone$),
      )
    this.connecting$(spec$, this.spec$)   // Use `connecting$` to stop when tappedDone$
  }
}