import * as _ from "lodash"
import {
  added,
  BaseAnimation,
  cachedArrayMapper,
  compact,
  filterDefined,
  IAnimation,
  MultiAnimation,
  Point,
  removed,
  Size,
  Transform,
  undiff,
  UndoContext,
  Widget
} from "@piccollage/cbjs";
import {BehaviorSubject, combineLatest, concat, Observable, of, ReplaySubject, Subject, zip} from "rxjs";
import {Collage} from "../models/collage";
import {BackdropWidget} from "./backdrop_widget";
import {ScrapWidget} from "./scrap_widget";
import {
  delay,
  distinctUntilChanged,
  filter,
  last,
  map,
  share,
  skip,
  startWith,
  switchMap,
  takeUntil
} from "rxjs/operators";
import {Scrap} from "../models/scrap";
import {TextScrap} from "../models/text_scrap";
import {TextScrapWidget} from "./text_scrap_widget";
import {ImageScrap} from "../models/image_scrap";
import {ImageScrapWidget, isLoadable} from "./image_scrap_widget";
import {Positioning} from "../../toolkit/models/positioning";
import {SketchScrap} from "../models/sketch_scrap";
import {SketchScrapWidget} from "./sketch_scrap_widget";
import {EditorContext} from "./editor_context";

const ADDED_ANIMATION_T       = 300
const ADDED_ANIMATION_SCALE   = 1.3
const REMOVE_ANIMATION_T      = 300
const FOCUS_T                 = 50000

export class CaptureRequest {
  captured$ = new ReplaySubject<Blob>()

  constructor(readonly size: Size) {
  }
}
export class CollagerCoreWidget extends Widget {

  // LEARN: Separate Core that does the collage structure and capturing
  //        from the rest of the editor that does gestures, actions, etc.

  // ---- Inputs
  backdropContainerSize$ = new BehaviorSubject<Size|null>(null)

  // ---- Collage subwidgets
  backdropWidget: BackdropWidget
  scrapWidgets$ = new BehaviorSubject<ScrapWidget[]>([])

  // ---- Scrap focus control
  currentlyManipulatedScrap$ = new Subject<Observable<ScrapWidget>>()
  focusScrapWidget$  = new BehaviorSubject<ScrapWidget|null>(null)

  // ---- Undo/Redo
  undoContext = new UndoContext()

  // ---- Collage Modifications
  modified$ = new Subject<any>();

  // ---- Capture requests
  captureMode$       = new BehaviorSubject(false)
  captureRequest$    = new Subject<CaptureRequest>()

  // ---- Object lifecycle
  constructor(readonly collage: Collage) {
    super()

    // ---- Setup properties and property connections
    this.backdropWidget = this.legate(() => new BackdropWidget(collage))

    // ---- Setup context
    this.contexter.prepend([
      new EditorContext(
        this.scrapWidgets$,
        this.focusScrapWidget$,
        this.captureMode$,
        this.backdropWidget,
        this.modified$
      ),
      this.undoContext
    ])

    // ---- Do connections
    this.connectScrapWidgets()
    this.connectFocus()
    this.connectBackdropPositioning()

  }

  // ---- Connect to create ScrapWidgets
  private connectScrapWidgets() {

    const self = this
    function scrapWidgetFromScrap(scrap: Scrap): ScrapWidget|null {
      if (scrap instanceof TextScrap) {
        return self.legate(() => new TextScrapWidget(scrap))
      }
      else if (scrap instanceof ImageScrap) {
        return self.legate(() => new ImageScrapWidget(scrap))
      }
      else if (scrap instanceof SketchScrap) {
        return self.legate(() => new SketchScrapWidget(scrap))
      }
      return null
    }
    const widgetsMapper: (from: Array<Scrap>) => Array<ScrapWidget|null> =
      cachedArrayMapper(
        scrap => scrap.id,
        scrap => scrapWidgetFromScrap(scrap)
      )
    const scrapsWidgetsRaw$ = this.collage.scraps$.pipe(
      map(widgetsMapper),
      map(compact),
      share(),                                // Shared since used twice below,
    )

    // ---- Separate into removed/added
    const removed$  = scrapsWidgetsRaw$.pipe(
      removed(),
      filter(removed => removed.length > 0),  // Remove no-ops
      share()                                 // Shared since used twice below
    )
    const added$    = scrapsWidgetsRaw$.pipe(
      // Have to start with an empty
      startWith<ScrapWidget[], ScrapWidget[]>([]),
      added(),
      filter(removed => removed.length > 0),  // Remove no-ops
    )

    // ---- Trigger added/removal animations
    this.triggering(removed$, animatePulseBack)
    this.triggering(added$.pipe(skip(1)), animatePulseFront)
    // Skip initial add of all the Scraps

    // ---- Finally hook up the ScrapWidgets
    const undiffed$ = undiff(added$, removed$.pipe(delay(REMOVE_ANIMATION_T)))
    this.connecting(
      undiffed$,
      this.scrapWidgets$)
  }

  // ---- Connect for ScrapWidget focus
  private connectFocus() {

    // ---- This is a stream of the current ScrapWidget that is in focus or `null`.
    this.connecting(
      this.currentlyManipulatedScrap$.pipe(
        switchMap(scrapWidget$ =>
          concat(scrapWidget$,
            of(null).pipe(delay(FOCUS_T))
          )
        ),
      ),
      this.focusScrapWidget$
    )
  }

  // ---- Connect backdropView to backdrop size
  connectBackdropPositioning() {
    const positioning$ = combineLatest([
      this.collage.size$,
      this.backdropContainerSize$.pipe(
        filterDefined(),
        distinctUntilChanged(_.isEqual),
      ),
    ]).pipe(
      map(([sFrom, sTo]) => {
        const scale = Math.min(
          sTo.width  / sFrom.width,
          sTo.height / sFrom.height
        )
        const p = new Positioning(
          new Point(
            Math.max(0, (sTo.width  - sFrom.width  * scale) / 2),
            Math.max(0, (sTo.height - sFrom.height * scale) / 2),),
          0, scale
        )
        return p
      }),
    )
    this.connecting(
      positioning$.pipe(
        map(p => new BaseAnimation(_ => p, 100) as IAnimation<Positioning>),
      ),
      this.backdropWidget.positioning.animation$
    )
  }

  // =====================================================================
  // Public Utility

  public collageOffset() {
    return this.backdropWidget.positioning.value$.value
  }

  // =====================================================================
  // Utility

  // ---- Call to declare manipulation.
  //      Takes a manipulation and passes it on, but listens for when it ends.
  //      Sends an Observable with the ScrapWidget that lasts exactly as long
  //      as the manipulation.
  //
  protected focusingManipulation(scrapWidget: ScrapWidget)
    : (manipulation: Observable<any>) => Observable<ScrapWidget>
  {
    return (manipulation: Observable<any>) => {
      const m = manipulation.pipe(share())
      const ended$ = m.pipe(last(null, true))
      const scrapWidget$ =
        new BehaviorSubject(scrapWidget).pipe(
          takeUntil(ended$),
        )
      this.currentlyManipulatedScrap$.next(scrapWidget$)
      return m
    }
  }
}

// =======================================================================
// ---- Component animations

function animatePulseBack(scrapWidgets: ScrapWidget[]): Observable<any> {
  scrapWidgets.forEach(scrapWidget => {
    const animation = new BaseAnimation<Positioning>(
      p => p.transform(new Transform({scale: 0})), REMOVE_ANIMATION_T)
    scrapWidget.positioning.animation$.next(animation)
  })
  return of(scrapWidgets)
}

function animatePulseFront(scrapWidgets: ScrapWidget[]): Observable<any> {
  return zip(...scrapWidgets.map(widget =>
    (isLoadable(widget) ? widget.isLoaded$ : of(true)).pipe(
      map(_ => {
        const p = widget.scrap.positioning$.value
        const animation = new MultiAnimation(
          new BaseAnimation<Positioning>(_ => p.transform(new Transform({ scale: ADDED_ANIMATION_SCALE })), ADDED_ANIMATION_T),
          new BaseAnimation<Positioning>(_ => p, ADDED_ANIMATION_T),
        )
        widget.positioning.animation$.next(animation)
      })
    )
  ))
}
