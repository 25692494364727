import {defer, Observable, of} from "rxjs";
import {EditorWidget} from "../ui/editor_widget";
import {BaseAnimation} from "@piccollage/cbjs";
import {Positioning} from "../../toolkit/models/positioning";

export function manipulateBackdropZoom(editorWidget: EditorWidget, zoom: number)
  : Observable<number> {

  return defer(() => {
    const p    = editorWidget.backdropWidget.positioning.value$.value
    const containerSize = editorWidget.backdropContainerSize$.value
    if (containerSize) {
      const center = containerSize.scale(0.5).toPoint()
      const r = center.subtract(p.point).scale(zoom)
      const pNew = new Positioning(
        center.subtract(r),
        p.rotation,
        p.scale * zoom)
      editorWidget.backdropWidget.positioning.animation$.next(
        new BaseAnimation(_ => pNew, 300)
      )
    }
    return of(zoom)
  })
}

