import {Scrap} from "./scrap";
import {BehaviorSubject} from "rxjs";
import {generateId, ID, Size} from "@piccollage/cbjs";
import {Positioning} from "../../toolkit/models/positioning";
import {Stroke} from "../../toolkit/models/stroke";

export class SketchScrap extends Scrap {
  strokes$: BehaviorSubject<Stroke[]>
  strokesTimestamp$ = new BehaviorSubject<number|null>(null)

  constructor(
    readonly id: ID = generateId(),
    sizeBase: Size = Size.ZERO,
    positioning: Positioning = new Positioning(),
    strokes: Stroke[] = []
  ) {
    super(id, sizeBase, positioning)
    this.strokes$ = new BehaviorSubject<Stroke[]>(strokes)

  }
}