import {EditorWidget} from "../ui/editor_widget";
import {EMPTY, of} from "rxjs";
import {flatMap} from "rxjs/operators";
import {taplog} from "@piccollage/cbjs";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateRedo(editorWidget: EditorWidget) {
  console.log("++++ manipulateRedo")
  return of(editorWidget.undoContext.popRedo()).pipe(
    taplog("++++ manipulateRedo command"),
    flatMap(command =>
      (command === undefined || command.unexecution === undefined) ?
        EMPTY :
        command
          .execution()
          .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
    )
  )
}