import * as _ from "lodash"
import {ScrapWidget} from "../ui/scrap_widget"
import {Observable} from "rxjs/index"
import {
  BaseAnimation,
  now,
  Point,
  scan2,
  taplog,
  Transform,
  transformsFromGesture,
  TTouchEvent,
  TTouchGesture,
  UndoContext
} from "@piccollage/cbjs"
import {filter, flatMap, last, map, tap} from "rxjs/operators"
import {BackdropWidget} from "../ui/backdrop_widget"
import {Positioning} from "../../toolkit/models/positioning";
import {Collage} from "../models/collage";
import {repositionTouchEvent} from "../../toolkit/models/positioning_util";

import {commandUpdateScrapPositioning} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateScrapTransform(gesture: TTouchGesture,
                                         collageOffset: Positioning,
                                         collage: Collage,
                                         scrapWidget: ScrapWidget)
  : Observable<Partial<Positioning>> {
  console.log("++++ manipulateScrapTransform", now(), scrapWidget.scrap.positioning$.value, gesture)

  const pInitial = scrapWidget.scrap.positioning$.value

  const transform$ = gesture.pipe(

    // ---- Commit to disable other effects of dragging
    tap(e => TTouchEvent.commit(e)),

    // ---- 0. Filter only ones that are over the BackdropWidget
    filter(e => _.flatMap(e.touches, t => t.targetings)
      .findIndex(t => t.target instanceof BackdropWidget) >= 0),

    // ---- 0.5 Convert gesture to collage coordinates
    map(event => repositionTouchEvent(event, collageOffset)),

    // ---- 1. Convert to transforms
    transformsFromGesture()
  )

  return transform$.pipe(

    // ---- 2. Accumulate transforms
    scan2(Transform.ZERO, (total: Transform, t: Transform) => total.plus(t)),

    // ---- 3. Calculate manipulation position
    map((t: Transform) => {
      const tRepivot: Transform = t.repivot(Point.ZERO, pInitial.point)
      return pInitial.transform(tRepivot)
    }),

    // ---- 4. Connect animation to new position
    tap((p: Positioning) => {
      scrapWidget.positioning.animation$.next(
        new BaseAnimation(_ => p, 0.0)
      )
    }),

    // ---- 5. When finished, issue command
    last(null, pInitial),
    taplog("++++ manipulateScrapTransform final P"),

    // ---- 6. Execute
    flatMap(p =>
      commandUpdateScrapPositioning(scrapWidget.scrap, p)
        .tap(c => scrapWidget.contexter.get(UndoContext)?.push(c))
        .execution()
        .pipe(EditorContext.findFrom(scrapWidget)?.modifies() || NOOP)
    ),

  )

}