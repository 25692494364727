import React, {FunctionComponent} from "react";
import {Helmet} from "react-helmet";

interface OgData {
  ["og:title"]: string;
  ["og:image"]: string;
  ["og:description"]: string;
  ["og:type"]: string;
}

interface HeadProps {
  image: string;
  description?: string;
}

const Head: FunctionComponent<HeadProps> = props => {
  const ogData: OgData = {
    "og:title": "PicClassroom",
    "og:image": props.image,
    "og:description": props.description!,
    "og:type": "website"
  };

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
      />
      <title>{ogData["og:title"]}</title>
      <meta name="description" content={ogData["og:description"]} />

      <meta property="og:title" content={ogData["og:title"]} />
      <meta property="og:description" content={ogData["og:description"]} />
      <meta property="og:image" content={ogData["og:image"]} />
      <meta property="og:type" content={ogData["og:type"]} />
    </Helmet>
  );
};

Head.defaultProps = {
  description: "tatta"
};

export default Head;
