import React from "react";
import {SketchScrapWidget} from "../ui/sketch_scrap_widget";
import {useBehaviorSubject} from "@piccollage/cbjs";
import {StrokeView} from "../../toolkit/views/stroke_view";
import {ScrapView} from "./scrap_view";
import styles from "./sketch_scrap_view.module.scss"

export function SketchScrapView(props: { widget: SketchScrapWidget })
{
  console.log("++++ SketchScrapView", props)

  const strokes = useBehaviorSubject(props.widget.strokes$)
  return (
    <ScrapView widget={props.widget}>
      {
        strokes.map((stroke, index) =>
          <StrokeView key={index}
                      stroke={stroke}
                      size$={props.widget.sizeBase$}
                      className={styles.StrokeView}
          />
        )
      }
    </ScrapView>
  )

}