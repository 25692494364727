import {Collage} from "../../collage_editor/models/collage";
import {Observable, of} from "rxjs";
import {AppWidget} from "../ui/app_widget";
import {filterFirst, ID} from "@piccollage/cbjs";
import {commandLoadCollage} from "../../collage_firebase/command_firestore";
import {flatMap, map} from "rxjs/operators";
import {manipulateNavigateTopWidget} from "./manipulate_navigate_top_widget";
import {ViewerWidget} from "../../collage_editor/ui/viewer_widget";

export function manipulateNavigateCollageViewer(appWidget: AppWidget, collage: Collage|ID)
  : Observable<Collage>
{
  console.log("++++ manipulateNavigateCollageViewer")

  const collage$ = collage instanceof Collage ?
    of(collage) :
    commandLoadCollage(collage)
      .execution()
      .pipe(
        filterFirst()
      )

  // ---- Get the Collage and edit it
  return collage$.pipe(
      flatMap(collage => {
        const widget = appWidget.legate(() => new ViewerWidget(collage))
        return manipulateNavigateTopWidget(appWidget, widget).pipe(
          map(_ => collage)
        )
      })
    )
}

