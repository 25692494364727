import * as React from 'react'
import {BaseSyntheticEvent, useCallback, useEffect, useRef} from 'react'
import {EditorWidget} from "../ui/editor_widget"
import {
  isTouchy,
  RefStoreContext,
  Size,
  TTouchGesture,
  useBehaviorSubject,
  useGestures,
  useNewRefStore,
  useObservable,
  Widget
} from "@piccollage/cbjs"
import {publishReplay} from "rxjs/operators"
import {UploadView} from "../../toolkit/views/upload_view"
import {BehaviorSubject, Observable, Subject} from "rxjs"
import _ from "lodash"
import {ProgressWidget} from "../../toolkit/ui/progress_widget"
import {ProgressView} from "../../toolkit/views/progress_view"
import {StickerChooserView} from "../../sticker_chooser/views/sticker_chooser_view"
import {addTargetsToGesture, useResize} from "../../app/views/view_util"
import {FloaterView} from "../../toolkit/views/floater_view";
import styles from './editor_view.module.scss'
import {viewCaptureCollage} from "../../app/views/view_capture";
import {refCountDelay} from "rxjs-etc/operators";
import {PathEditorView} from "../../path_editor/views/path_editor_view";
import {Dialog, Tooltip} from "@material-ui/core";
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import Button from "@material-ui/core/Button";
import {TextEditorView} from "../../text_editor/views/text_editor_view";
import Drawer from "@material-ui/core/Drawer";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import {StrokeView} from "../../toolkit/views/stroke_view";
import {Stroke} from "../../toolkit/models/stroke";
import {CollagerCoreView} from "./collage_core_view";
import {LinkEditorView} from "../../link_editor/views/link_editor_view";

const HEADER_HEIGHT = 75;

export function EditorView(props: { widget: EditorWidget, children?: any })
{
  console.log("++++ EditorView render")

  // LEARN: It is sometimes necessary to debug unnecessary emits from streams
  // for performance reasons. But it is not that hard to do so, and can be
  // done by tracing data flow.
  //

  const refStore = useNewRefStore<Widget, HTMLElement>()

  // ---- Need to programatically disable scrolling when this widget is
  //      being displayed.
  //
  useEffect(() => {
    const s = document.documentElement.style
    const prev = s.overflow
    s.overflow = 'hidden'
    return () => {
      s.overflow = prev
    }
  })

  // ---- Script to set the height to the actual height to correct
  //      for the mobile scroll height fudge.
  const refEditor = useRef<HTMLDivElement>(null)
  useResize(window, size =>
    refEditor.current &&
    (refEditor.current.style.height = `${size.height - HEADER_HEIGHT}px`)
  )

 // LEARN: When to use refCount/refCountDelay, to avoid extra subscriptions
  // and work.

  // ---- Setup user gestures, inserting Widget information from the refStore
  const gesture$ = useCallback((gesture: TTouchGesture): void => {
    const g$ = gesture.pipe(
      addTargetsToGesture(refStore),
      publishReplay(),
      refCountDelay(100),   // Use a delayed freeing for additional subscriptions
    )
    props.widget.gesture$.next(g$)
  }, [props.widget, refStore])
  useGestures(refEditor, gesture$)

  // ---- Disable the context menu (return false)
  const onContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
  };

  // ---- Canvas capture
  useEffect(() => {
    const subs = props.widget.captureRequest$.subscribe(request => {

      const canvas = viewCaptureCollage(request.size, refStore)
      canvas.toBlob(blob => blob?
          request.captured$.next(blob) :
          request.captured$.complete()
      )

    })
    return () => subs.unsubscribe()
  })

  // -----------------------------------------------------------------------
  // RENDERING
  return (
    <RefStoreContext.Provider value={refStore}>

      <div ref={refEditor} className={styles.EditorView} onContextMenu={onContextMenu}>

        {/* -------- Stickers and editor widgets */}
        <CollagerCoreView widget={props.widget} >

          {/* ---- Sketch ---- */}
          <CurStrokeView  stroke$={ props.widget.curSketchStroke$ }
                          size$={ props.widget.backdropWidget.size$ }
                          className={styles.StrokeView}/>

        </CollagerCoreView>

        { /* ---- Extras (dialogs, etc.) ---- */ }
        <EditorViewExtras widget={props.widget} />

      </div>

      <EditorViewControls widget={props.widget} />


    </RefStoreContext.Provider>
  );
}

function EditorViewControls(props: { widget: EditorWidget })
{
  // ---- Undo/Redo
  const canUndo = useBehaviorSubject(props.widget.undoContext.canUndo$)
  const canRedo = useBehaviorSubject(props.widget.undoContext.canRedo$)
  function onEvent(out$: Subject<boolean>) {
    return (e: BaseSyntheticEvent) => {
      console.log("++++ EditorViewControls event", e)
      e.stopPropagation()
      e.preventDefault()
      out$.next(true)
    }
  }

  return (
    <>
      <div className={ styles.header }>
        <Button color="primary"
                onMouseDown={ onEvent(props.widget.tappedUndo$) }
                className={ styles.button }
                disabled={!canUndo} >
          <UndoIcon />
        </Button>
        <Button color="primary"
                onMouseDown={ onEvent(props.widget.tappedRedo$) }
                className={ styles.button }
                disabled={!canRedo} >
          <RedoIcon />
        </Button>
      </div>

      <div className={ styles.sidebar }>
        <Tooltip title={ isTouchy ? false : "Hold Shift to pan image" } >
          <Button className={styles.zoomInButton}
                  onClick={onEvent(props.widget.tappedZoomIn$)}
                  color='primary' >
            <ZoomInIcon />
          </Button>
        </Tooltip>
        <Tooltip title={ isTouchy ? false : "Hold Shift to pan image" } >
          <Button className={styles.zoomOutButton}
                  onClick={onEvent(props.widget.tappedZoomOut$)}
                  color='primary' >
            <ZoomOutIcon />
          </Button>
        </Tooltip>
      </div>
    </>
  )
}

function CurStrokeView(props: { stroke$: BehaviorSubject<Stroke|null>,
                                size$: BehaviorSubject<Size>,
                                className?: string })
{
  const stroke = useBehaviorSubject(props.stroke$)
  if (!stroke)
    return null
  return <StrokeView stroke={stroke} size$={props.size$} className={props.className} />
}
function EditorViewExtras(props: { widget: EditorWidget }) {
  console.log("++++ EditorView render extras")

  // ----- Capture mode
  const captureMode = useBehaviorSubject(props.widget.captureMode$)

  // ---- Sub-widgets
  const progressViews        = useProgressViews(props.widget.progressWidgets$)
  const uploadWidget         = useBehaviorSubject(props.widget.uploadWidget$)
  const pathEditorWidget     = useBehaviorSubject(props.widget.pathEditorWidget$)
  const textEditorWidget     = useBehaviorSubject(props.widget.textEditorWidget$)
  const stickerChooserWidget = useBehaviorSubject(props.widget.stickerChooserWidget$)
  const linkEditorWidget     = useBehaviorSubject(props.widget.linkEditorWidget$)

  return (
    <div>

      {/* -------- Floater and progress widgets */}

      <FloaterView widget$={ props.widget.floaterWidget$ } />
      { !captureMode && uploadWidget     && (<UploadView uploadWidget={ uploadWidget }/>) }
      { !captureMode && progressViews }

      {
        stickerChooserWidget && (
          <StickerChooserView widget={stickerChooserWidget} />
        )
      }

      { !captureMode && pathEditorWidget && (
        <Dialog fullScreen open={true}
                onClose={ _ => pathEditorWidget.tappedDone$.next(true) }>
          <PathEditorView widget={ pathEditorWidget }/>
        </Dialog>
        ) }

      { !captureMode && textEditorWidget && (
        <Drawer open={true} anchor="bottom"
                onClose={ _ => textEditorWidget.tappedDone$.next(true) }
                BackdropProps={{ style: { backgroundColor: "#888", opacity: 0.3 } }}
                disableBackdropClick={false}   // Tap outside the drawer to dismiss
          >
          <TextEditorView widget={ textEditorWidget }/>
        </Drawer>
      ) }
      
      { !captureMode && linkEditorWidget && (
        <Dialog open={true}
                onClose={ _ => linkEditorWidget.tappedDone$.next(true) }
                BackdropProps={{ style: { backgroundColor: "#888", opacity: 0.3 } }}
                disableBackdropClick={false}   // Tap outside the drawer to dismiss
          >
          <LinkEditorView widget={linkEditorWidget} />
        </Dialog>
      ) }


    </div>
  )
}

// ====================================================================
// ---- Floater and Progress management

function useProgressViews(progressWidgets$: Observable<ProgressWidget[]>)
  : JSX.Element[]
{

  const widgets = useObservable(progressWidgets$, [], [progressWidgets$])
  return _.flatten(widgets.map(w =>
    <ProgressView key={w.id} progressWidget={w}/>
  ))
}


