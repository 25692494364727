import {ID, promise$, Size, useBehaviorSubject} from "@piccollage/cbjs";
import {BehaviorSubject} from "rxjs";
import {Path, pathToSVG} from "../../toolkit/models/path";
import React, {RefObject, useEffect, useRef} from "react";
import {FaceInfo, ImageScrapWidget} from "../ui/image_scrap_widget";
import {ImageInfo} from "../../toolkit/lib/image";
import {ScrapView} from "./scrap_view";
import * as faceapi from "face-api.js";
import {flatMap, tap} from "rxjs/operators";

export function ImageScrapView(props: { widget: ImageScrapWidget }) {


  // ---- Properties
  const imageSourceUrl = useBehaviorSubject(props.widget.imageSourceUrl$)
  const clippath_id  = 'scrap_clippath_' + props.widget.scrap.id
  const clippath_url = `url(#${clippath_id})`
  const style = {
    width: "100%",
    height: "100%",
    clipPath:       clippath_url,
    WebkitClipPath: clippath_url,
  }
  const refImg: RefObject<HTMLImageElement> = useRef(null)

  // ---- Face requests
  // ---- Canvas capture
  useEffect(() => {
    const subs = props.widget.faceRequest$.pipe(
      flatMap(request => {
        console.log("++++ FaceRequest", request)
        return promise$(() =>
          faceapi.detectAllFaces(refImg.current!!).withFaceLandmarks().run()
        ).pipe(
          tap((faceInfos: FaceInfo[]) => {
            console.log("++++ FaceRequest =>", faceInfos)
            faceInfos.forEach(i => request.face$.next(i))
            request.face$.complete()
          })
        )
      })
    ).subscribe()
    return () => subs.unsubscribe()
  })

  // ---- Event handlers
  function onLoad() {
    if (refImg.current != null) {
      const size = new Size(refImg.current.naturalWidth, refImg.current.naturalHeight)
      const info: ImageInfo = {isLoaded: true, size}
      props.widget.imageInfo$.next(info)
    }
  }

  function onError() {
    if (refImg.current != null) {
      const info: ImageInfo = {isLoaded: false, size: null}
      props.widget.imageInfo$.next(info)
    }
  }

  // ---- Render
  return (
    <ScrapView widget={props.widget}>
      <ClipPath id={clippath_id}
                path$={props.widget.clippingPath$}/>
      <img alt=""
           crossOrigin="anonymous"
           ref={refImg}
           style={style}
           src={imageSourceUrl}
           onLoad={onLoad}
           onError={onError}
      />
    </ScrapView>
  )
}

// ---- Structure this as a separate component so that it doesn't get
//      re-rendered and re-parsed every time the scrap changes.
//
function ClipPath(props: { id: ID, path$: BehaviorSubject<Path | null> }) {
  console.log('++++ ClipPath render')

  const path = useBehaviorSubject(props.path$)
  if (!path)
    return null
  const d = pathToSVG(path)

  // Note that `clipPathUnits="objectBoundingBox"` is necessary to make
  // the coordinate system for the path to be 0-1.0 based.
  //
  return (
    <svg width="0" height="0">
      <defs>
        <clipPath id={props.id} clipPathUnits="objectBoundingBox">
          <path d={d}/>
        </clipPath>
      </defs>
    </svg>
  )
}