import {BackdropWidget} from "../ui/backdrop_widget"
import * as React from "react"
import {BaseSyntheticEvent, CSSProperties} from "react"
import {$P, filterTruthy, useBehaviorSubject, useRefStore, Widget} from "@piccollage/cbjs"
import {positioningToCSS, useSpringProperties} from "../../app/views/view_util";
import styles from "./backdrop_view.module.scss";
import {animated} from 'react-spring'

export function BackdropView(props: { widget: BackdropWidget,
                                      children: JSX.Element[]|JSX.Element,
                                      })
{
  console.log("++++ BackdropView render")

  const size = useBehaviorSubject(props.widget.size$)
  const backgroundURL = useBehaviorSubject(props.widget.collage.backgroundURL$)

  // ---- RefStore used to store references to the views
  const refStore = useRefStore<Widget, HTMLDivElement>(props.widget)

  // ---- Animation of position
  const [pos, posAnimation] = useBehaviorSubject(props.widget.positioning.output$)
  const spring = useSpringProperties(
    positioningToCSS(pos, false),
    positioningToCSS(posAnimation.valueTo(pos), false),
    { duration: posAnimation.t },
  )

  const style: CSSProperties = {
    width: size.width,
    height: size.height,
    ...spring,
  }

  function onDrop(ev: React.DragEvent<HTMLElement>) {
    ev.preventDefault()

    const items = ev.dataTransfer && ev.dataTransfer.items
    const point = $P(ev.clientX, ev.clientY)
    const files = filterTruthy(
                    Array.from(items).map(item => item.getAsFile())
                  )
    props.widget.draggedFiles$.next({ point: point, files: files })
  }

  function onDragOver(ev: BaseSyntheticEvent) {
    ev.preventDefault();    // Prevent file from being opened
  }

  // NOTE: The inner div for capture is needed because domtoimage doesn't
  // capture right on the outer div, perhaps because of the transform and scaling?

  return (

    <animated.div className={ styles.BackdropView }
         style={ style }
         ref={ refStore }
         onDrop={onDrop}
         onDragOver={onDragOver}
         >

      <div id='backdrop_view_capture'
           className={ styles.capture }>
      { backgroundURL && (
        <img alt='background'
             crossOrigin="anonymous"
             id='BACKGROUND_IMG'
             src={ backgroundURL }
             className={ styles.img }/>
        ) }
      { props.children }
      </div>
    </animated.div>
  )
}
