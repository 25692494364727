import {List} from 'immutable';
import {alos, Point, Rect, Size, wiht} from "@piccollage/cbjs";

export type Path = List<Point>

export function pathToSVG(path: Path, size=Size.ONE): string {
  let cursor: Point|null = null
  return path.map(p =>
    alos(
      (!cursor) ?
        `m ${p.x * size.width},${p.y * size.height}` :
        wiht(p.subtract(cursor), d =>
          `l ${d.x * size.width},${d.y * size.height}`),
      _ => { cursor = p }
    )
  ).join('\n')
}

export function pathToArray(path: Path): Array<number> {
  return path.flatMap(p => [p.x, p.y]).toArray()
}

export function isPointInsidePath(p: Point, path: Path) {
  // Ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
  // https://stackoverflow.com/a/29915728/304734
  //
  // Note that this algorithm gives mixed results in the case that the points
  // are exactly on the edges or vertices. For the purposes of hit testing
  // we don't care about this.

  const {x, y} = p
  let inside = false;
  const length = path.size
  for (let i = 0, j = length - 1; i < length; j = i++) {
    const vi = path.get(i)!!
    const vj = path.get(j)!!
    if (vi.isEqual(vj))
      continue
    const {x: xi, y: yi} = vi
    const {x: xj, y: yj} = vj
    var intersect = ((yi > y) !== (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }
  return inside;
}

export function boundingBoxFromPath(path: Path): Rect
{
  let x0 = Number.POSITIVE_INFINITY
  let y0 = Number.POSITIVE_INFINITY
  let x1 = Number.NEGATIVE_INFINITY
  let y1 = Number.NEGATIVE_INFINITY

  path.forEach(p => {
    x0 = Math.min(x0, p.x)
    x1 = Math.max(x1, p.x)
    y0 = Math.min(y0, p.y)
    y1 = Math.max(y1, p.y)
  })
  return new Rect(new Point(x0, y0), new Size(x1-x0, y1-y0))
}

// Adapted from: https://stackoverflow.com/a/3122532/304734
//
export function closestToLine(p: Point, a: Point, b: Point): Point {
  const ap   = p.subtract(a)
  const ab   = b.subtract(a)
  const dot  = ap.dot(ab)
  const abm2 = ab.magnitude2()
  if (abm2 === 0)
    return a
  const delta = dot / abm2
  if (delta <= 0)
    return a
  if (delta >= 1)
    return b
  return a.add(ab.scale(delta))
}

export function arrayPairedLooped<T>(array: Array<T>, f: (t0: T, t1: T, i: number) => any) {
  const size = array.length
  if (size === 0)
    return
  for(let i=0; i<size; i++) {
    f(array[i]!!, array[i === (size-1) ? 0 : i+1]!!, i)
  }
}

type PathExtension = {
  closest: Point,
  index: number,
  distance2: number
}
export function closestToPath(p: Point, path: Path)
  : PathExtension|null
{
  let min: { closest: Point, index: number, distance2: number }|null = null
  arrayPairedLooped(path.toArray(), (a, b, index) => {
    const closest = closestToLine(p, a, b)
    const distance2 = p.subtract(closest).magnitude2()
    if (!min || distance2 < min.distance2) {
      min = { closest, index, distance2 }
    }
  })
  return min
}

// ---- Returns the first Point in the Path that is within the minimum distance
//      (not necessarily the closest)
//
export function isCloseToPath(p: Point,
                              path: Path,
                              distance: number)
  : Point|undefined
{
  const distance2 = distance * distance
  if (path.isEmpty())
    return undefined
  console.log(">>>> isCloseToPath", p, path.toArray(), distance)
  let pA: Point = path.first()
  let closest
  const pB: Point|undefined = path.rest().find(pB => {
    closest = closestToLine(p, pA, pB)
    const d2 = p.subtract(closest).magnitude2()
    console.log(">>>> isCloseToPath closest", Math.sqrt(d2), pA, pB, closest)
    pA = pB
    return d2 < distance2
  })
  return pB ? closest : undefined
}

export function distancer(start: Point)
  : (p: Point) => number
{
  let lastP    = start
  let distance = 0
  return (p: Point) => {
    const v = p.subtract(lastP)
    const d = v.magnitude()
    lastP = p
    distance += d
    return distance
  }
}

export function pathScale(path: Path, scale: Size): Path {
  return path.map(p => p.scale(scale))
}

// Calculates the "inner" distance and path, and the "outer" distance and path
// (wrapping around the origin).
//
export function pathDistance(path: Path,
                             end1: PathExtension,
                             end2: PathExtension)
{
  if (end1.index >  end2.index)
    throw new Error("pathDistance end2.index must be >= end1.index")
  const [ p1, p2 ] = [
    path.slice(end1.index + 1, end2.index + 1),
    path.slice(end2.index + 1).concat(path.slice(0, end1.index + 1))
  ]

  const d1$ = distancer(end1.closest)
  p1.forEach(d1$)
  const d1 = d1$(end2.closest)

  const d2$ = distancer(end2.closest)
  p2.forEach(d2$)
  const d2 = d2$(end1.closest)

  return { d1, p1, d2, p2 }
}

export function consolidatePaths(path1: Path|null,
                                 path2: Path|null,  // "New" path
                                 distance2: number,
                                 scale: Size = Size.ONE,
                                 shortest: boolean = true)
  : Path|null
{
  // ---- No existing path case
  if (!path1)
    return path2
  const path1Scaled = path1.map(p => p.scale(scale))

  // ---- No new path cases
  if (path2 === null)
    return path1
  const pStart = path2.get(0)
  if (!pStart)
    return path1
  const pStartScaled = pStart.scale(scale)

  // ---- See the closest *start* point and measure distance
  let extStart = closestToPath(pStartScaled, path1Scaled)
  if (!extStart)
    return path1

  // ---- See if start is too far
  const dStart2 = extStart.closest.subtract(pStartScaled).magnitude2()
  if (dStart2 > distance2)
    return path2

  // ---- Check where ending is
  const pEnd = path2.last(undefined)
  if (!pEnd)
    return path1
  const pEndScaled = pEnd.scale(scale)

  // ---- See if close or nearby
  let extEnd = closestToPath(pEndScaled, path1Scaled)
  if (!extEnd)
    return path1
  const dEnd2 = extEnd.closest.subtract(pEndScaled).magnitude2()
  if (dEnd2 > distance2) {
    // ---- Ending is far from existing path, just extend
    return path1.splice(extStart.index + 1,
                        path1.size - extStart.index,
                        ...path2.toArray())
  }
  // ---- ELSE, ending is on top of path, MERGE

  // ---- See if backwards, then flip extension
  if (extEnd.index < extStart.index) {
    [ extStart, extEnd ] = [ extEnd, extStart ]
    path2 = path2.reverse()
  }

  // ---- Calculate both distances and choose
  const { d1, d2 } = pathDistance(path1Scaled, extStart, extEnd)
  if (shortest === (d2 > d1)) {
    // Replace d1 direct path by splicing it
    return path1.splice(
      extStart.index + 1,
      extEnd.index - extStart.index,
      ...path2.toArray())
  }
  else {
    // Choose d2 wrap-around path to replace (i.e. keep the direct path).
    // That means reverse it and add it to our new path
    const direct = path1.slice(extStart.index + 1, extEnd.index + 1).reverse()
    return path2.concat(direct)
  }

}

export function pathMinMax(path: Path): Rect|null
{
  const xs = path.map(p => p.x)
  const ys = path.map(p => p.y)
  const xmin = xs.min()
  const xmax = xs.max()
  const ymin = ys.min()
  const ymax = ys.max()
  if (xmin === undefined || xmax === undefined || ymin === undefined || ymax === undefined)
    return null
  return new Rect(new Point(xmin, ymin), new Size(xmax - xmin, ymax - ymin))
}

export function pathReframe(path: Path, rect: Rect): Path
{
  return path.map(
    p => new Point(
      (p.x - rect.origin.x) / rect.size.x,
      (p.y - rect.origin.y) / rect.size.y,
    )
  )
}