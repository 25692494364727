import {defer, Observable, of} from "rxjs/index"
import {tap} from "rxjs/operators"
import {commandUpdateCollage} from "../../collage_firebase/command_firestore"
import {Positioning} from "../../toolkit/models/positioning";
import {Sticker} from "../models/stickers";
import {Collage} from "../models/collage";
import {EditorWidget, mapScrapToScrapWidget} from "../ui/editor_widget";
import {ImageScrapWidget} from "../ui/image_scrap_widget";
import {SCRAP_DEFAULT_BASE_SIZE} from "../models/scrap";
import {commandAddImageScrap} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateAddSticker(editorWidget: EditorWidget,
                                     collage: Collage,
                                     sticker: Sticker,
                                     posCollage: Positioning|null = null)
  : Observable<ImageScrapWidget|Collage> {

  console.log("++++ manipulateAddSticker", sticker)
  const url = sticker.image.toString()
  const size = SCRAP_DEFAULT_BASE_SIZE

  return defer(() => {

    // ---- Decide if dropping background or sticker
    console.log("++++ manipulateAddSticker url", sticker.title)
    if (sticker.title && sticker.title.match(/^bg_/)) {

      // ---- Background
      return commandUpdateCollage(collage, { background_url: url })
        .push(editorWidget.undoContext)
        .execution()
        .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
    }
    else {

      if (posCollage === null) {
        posCollage = new Positioning(
          collage.size$.value.scale(0.5),
          0,
          1/editorWidget.backdropWidget.positioning.value$.value.scale,
          collage.scrapZMax() + 1
        )
      }

      // ---- Normal sticker
      return commandAddImageScrap(collage, posCollage, size, url)
        .push(editorWidget.undoContext)
        .execution()
        .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
        .pipe(

          // ---- Map to ImageScrapWidget
          mapScrapToScrapWidget<ImageScrapWidget>(editorWidget),

          // ---- Manipulate ImageScrapWidget
          tap(imageScrapWidget =>
            editorWidget.currentlyManipulatedScrap$.next(of(imageScrapWidget))),

        )
    }
  })
}