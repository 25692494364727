export enum FontProtocol {
  web    = "web",
  google = "google",
}

export class Font {
  constructor(readonly family: string,
              readonly title: string = family,
              readonly protocol: FontProtocol = FontProtocol.web,
              readonly variation: string|null = null,
              ) {}
  isEqual(other: any) {
    return (other instanceof Font) && (this.family === other.family)
  }
}

