import * as firebase from "firebase/app";
import * as _ from "lodash";

import {Sticker, StickerCategory, StickerRepo} from "../collage_editor/models/stickers";
import {defer, from, Observable, of, zip} from "rxjs";
import {promise$, taplog, toMap, typeCheck} from "@piccollage/cbjs";
import {catchError, flatMap, map, publishReplay, refCount, shareReplay} from "rxjs/operators";

import {ajax} from 'rxjs/ajax';

const FIRESTORE_STICKERS_FOLDER = 'stickers'

type StorageRef = firebase.storage.Reference

// LEARN: Separate concerns over shared state objects (models & widgets)

export class StickerRepoFirebase implements StickerRepo {

  ROOT_REF = firebase.storage().ref().child(FIRESTORE_STICKERS_FOLDER)

  static _instance: StickerRepoFirebase|null = null
  static instance(): StickerRepo {
    let i = StickerRepoFirebase._instance
    if (!i)
      i = StickerRepoFirebase._instance = new StickerRepoFirebase()
    return i
  }

  constructor() {
    console.log("++++ StickerRepoFirebase")
  }

  metadata$: Observable<{[k: string]: any}> = promise$(() => this.ROOT_REF.child('_categories.json').getDownloadURL()).pipe(
    flatMap(url => typeof url !== 'string' ?
      of({})
      : ajax.getJSON<{}>(url)
    ),
    taplog("++++ StickerRepoFirebase categories"),
    catchError(err => {
      console.error(`Unable to load sticker category metadata`, err)
      return of({})
    }),
    shareReplay(),
  )

  categories$(): Observable<StickerCategory[]> {

    // ---- Observable to get category folders
    const categoryRefs$  = promise$<firebase.storage.ListResult>(() => this.ROOT_REF.listAll())

    // ---- Observable to get metadata file

    return zip(categoryRefs$, this.metadata$).pipe(
      map(([ categoryRefs, metadata]) => {
        console.log("++++ Categories metadata", categoryRefs, metadata)
        const categoriesD: Record<string, any> = typeCheck(metadata["categories"], "object", {})

        // ---- Filter the categories (by name and display)
        const filtered = categoryRefs.prefixes.filter(s => {
          const name = s.name
          return !name.startsWith('_')
            && typeCheck(categoriesD[name], "object", false)
            && typeCheck(categoriesD[name]["display"], "boolean", false)
        })

        // ---- Map them to models
        const models = filtered.map(ref => {
          const name = ref.name
          const categoryData: Record<string, any> = typeCheck(categoriesD[name], "object", {})
          return this.categoryRefToCategory(categoryData, ref)
        })

        // ---- Sort
        return _.sortBy(models, category => category.priority)
      })
    )
  }

  categoryRefToCategory(categoryData: Record<string, any>,
                        categoryRef: StorageRef)
    : StickerCategory
  {

    const name = categoryRef.name

    // ---- Create Observable to retrieve stickers$
    const stickers$ = defer(() => {
      console.log("++++ Getting Category", name, categoryData)

      // ---- Retrieve list of sticker files from each category
      return promise$(() => categoryRef.listAll()).pipe(
        map(listResult => listResult.items),
        taplog(`++++ Got Category ${name} items`),

        // ---- Map each sticker reference to a URL
        flatMap(stickerRefs => {

          // ---- Store in refs Map (only non-starting with _)
          const refMap = toMap(stickerRefs.filter(ref => !ref.name.startsWith('_')),
                               ref => ref.name)

          // ---- Output all (when creating the _categories.json)
          // console.log("++++ stickers", stickerRefs.map(ref => `"${ref.name}"`).join(",\n"))

          // ---- Map from the listed ones in the metadata
          let stickerNames = typeCheck(categoryData["stickers"], "object", [])
          if (!(stickerNames instanceof Array))
            stickerNames = []
          const selectedRefs = _.uniq(stickerNames)   // Need to uniq since names used as `id`
            .filter(n => typeof n === "string")
            .map(n => refMap.get(n) || null)
            .filter(ref => !!ref) as StorageRef[]

          // ---- Map to models
          const model$s = selectedRefs.map(ref =>
            from(ref.getDownloadURL()).pipe(
              map(downloadURL =>
                new Sticker(ref.name, new URL(downloadURL))
              )
            )
          )

          // Fetch each one
          return zip(...model$s)
        }),
      )
    }).pipe(
      // ---- Share so we cache
      // ---- LEARN: caching is easy if you know where to put it
      publishReplay(), refCount(),
    )

    // ---- Got category from server
    return new StickerCategory(name,
      stickers$,
      typeCheck(categoryData["title"], "string", null),
      typeCheck(categoryData["thumbnail"], "string", null),
      typeCheck(categoryData["priority"], "number", Number.MAX_SAFE_INTEGER),
    )
  }
}

