import {EditorWidget} from "../ui/editor_widget";
import {Path} from "../../toolkit/models/path";
import {Observable, of, zip} from "rxjs";
import {ImageScrapWidget} from "../ui/image_scrap_widget";
import {PathEditorWidget} from "../../path_editor/ui/path_editor_widget";
import {filter, finalize, first, flatMap, last, map, tap} from "rxjs/operators";
import {generateTimestamp} from "@piccollage/cbjs";
import {commandUpdateImageScrap} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateEditClippingPath(editorWidget: EditorWidget,
                                           isw: ImageScrapWidget)
  : Observable<Path|null>
{
  console.log("++++ manipulateEditClippingPath", isw)

  // ---- Wait for image to be loaded
  return zip(
    isw.imageSourceUrl$,
    isw.imageInfo$.pipe(filter(info => info.isLoaded)),
    isw.clippingPath$
  ).pipe(

    // ---- Create the editor (only once, so NEED the first())
    map(([url, imageInfo, clippingPath]) =>
      new PathEditorWidget(url,
                           imageInfo.size!!,
                           clippingPath,
                           isw.positioning.value$.value.rotation)
    ),
    first(),

    // ---- Open the editor
    tap(pathEditor => editorWidget.pathEditorWidget$.next(pathEditor)),

    // ---- Wait for path
    flatMap(pathEditor => zip(
      of(pathEditor),
      pathEditor.path$.pipe(last()))
    ),

    // ---- Got last Path
    flatMap(([pathEditor, path]) =>
      commandUpdateImageScrap(isw.imageScrap,
        {
          clipping_path: path,
          clipping_path_timestamp: generateTimestamp(),
        })
        .push(editorWidget.undoContext)
        .execution()
        .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
        .pipe(
          map(_ => path)
        )
    ),

    // ---- Stop the editor
    finalize(() => {
      console.log("++++ manipulateEditClippingPath finished")
      editorWidget.pathEditorWidget$.next(null)
    }),
  )
}