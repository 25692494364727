import React, {ChangeEvent} from "react";
import {TextField} from "@material-ui/core";
import {useBehaviorSubject} from "@piccollage/cbjs";
import {TextEntryPanelWidget} from "../ui/text_entry_panel_widget";

export function TextEntryPanelView(props: { widget: TextEntryPanelWidget })
{
  const t = useBehaviorSubject(props.widget.t$)
  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target)
      props.widget.t$.next(e.target.value)
  }

  return (
    <div>
      <TextField
        variant="outlined"
        multiline={true}
        fullWidth={true}
        onChange={ onChange }
        value={ t }
        autoFocus={true}
        onFocus={event => {
          event.target.select()}
        }
      />
    </div>
  )
}

