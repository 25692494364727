import * as React from 'react'
import {useRef} from 'react'
import {CollagerCoreWidget} from "../ui/collager_core_widget";
import {useResize} from "../../app/views/view_util";
import {BackdropView} from "./backdrop_view";
import {ScrapWidgetViews} from "./editor_view_scraps";
import styles from './collage_core_view.module.scss'

export function CollagerCoreView(props: { widget: CollagerCoreWidget, children?: any })
{
  // ---- Tell domain layer area available for backdrop
  const refContainer = useRef<HTMLDivElement>(null)
  useResize(refContainer,
    size => props.widget.backdropContainerSize$.next(size))

  return (
    <div ref={refContainer} className={styles.CollageCoreView}>

      { /* ---- Backdrop ---- */ }
      <BackdropView
        widget={props.widget.backdropWidget} >

        {/* ---- Main display of Scraps ---- */}
        <ScrapWidgetViews scrapWidgets$={props.widget.scrapWidgets$} />

        { /* ---- Children added by parent ---- */ }
        {props.children}

      </BackdropView>

    </div>

  )


}