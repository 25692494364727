import {BehaviorSubject} from "rxjs";
import {generateId, ID, Size} from "@piccollage/cbjs";
import {Scrap} from "./scrap";
import {TextSpec} from "./text_spec";
import {Positioning} from "../../toolkit/models/positioning";

export class TextScrap extends Scrap {

  spec$: BehaviorSubject<TextSpec>

  constructor(readonly id: ID = generateId(),
              sizesBase: Size = Size.ZERO,
              positioning: Positioning = new Positioning(),
              spec: TextSpec = TextSpec.DEFAULT) {
    super(id, sizesBase, positioning);
    console.log("++++ TextScrap constructor", spec)
    this.spec$ = new BehaviorSubject(spec)
  }
}