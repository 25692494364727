import React, {CSSProperties} from "react";
import {TextScrapWidget} from "../ui/text_scrap_widget";
import {filterDefined, useBehaviorSubject, useObserving} from "@piccollage/cbjs";
import {ScrapView} from "./scrap_view";
import {TextColorDefault} from "../models/text_spec";
import WebFont from "webfontloader";
import {distinctUntilChanged, filter, map} from "rxjs/operators";
import {fontConfig} from "../../app/views/view_util";
import {FontProtocol} from "../../toolkit/models/font";

export const TEXT_SCRAP_FONT_SIZE    = 30
export const TEXT_SCRAP_STROKE_SIZE  = 2.5
export const TEXT_SCRAP_BACKGROUND_RADIUS  = 2

export function TextScrapView(props: { widget: TextScrapWidget }) {

  console.log("++++ TextScrapView render", props.widget)

  useObserving(props.widget.spec$.pipe(
    map(spec => spec.font),
    distinctUntilChanged(),
    filterDefined(),
    filter(font => font.protocol !== FontProtocol.web),
  ), font => {
    if (font) {
      const config = fontConfig(font, {})
      WebFont.load(config)
    }
  })

  const spec = useBehaviorSubject(props.widget.spec$)
  const fontFamily = [ ...(spec.font ? [spec.font.family] : []), "serif"].join(',')

  const styles: { [key: string]: CSSProperties } = {
    span: {
      fontFamily,
      fontSize:     TEXT_SCRAP_FONT_SIZE + 'px',     // This is fixed
      width:        '100%',
      padding:      '0px',                           // Set to 0 so easier capture
      textAlign:    spec.alignment || 'center',
      lineHeight:   'normal',   // Was set by ScrapView to `0`, reset it
      color:        (spec.color || TextColorDefault).code,
      whiteSpace:   'pre-line',
    }
  }
  const styleStroke = spec.colorStroke ? {
    WebkitTextStrokeColor: spec.colorStroke.code,
    WebkitTextStrokeWidth: `${ (spec.widthStroke || 0) * TEXT_SCRAP_STROKE_SIZE }px`
  } : {}
  const styleBackground = spec.colorBackground ? {
    backgroundColor: spec.colorBackground.code,
    borderRadius: TEXT_SCRAP_BACKGROUND_RADIUS + 'px',
  } : {}

  return (
    <ScrapView widget={props.widget} >
      <div style={ { ...styles.span, ...styleStroke, ...styleBackground } } >
        { spec.t }
      </div>
    </ScrapView>
  )

}
