import {defer, EMPTY, Observable, of} from "rxjs";
import {consolidatePaths, Path} from "../../toolkit/models/path";
import {filter, flatMap, last, map, tap} from "rxjs/operators";
import {PathEditorWidget} from "./path_editor_widget";
import {BaseAnimation, Point, scan2, Transform, transformsFromGesture, TTouchGesture} from "@piccollage/cbjs";
import {List} from "immutable";
import {commandUpdatePath} from "./path_editor_widget_commands";
import {Positioning} from "../../toolkit/models/positioning";

export function manipulateFinish(pathEditorWidget: PathEditorWidget): Observable<Path | null> {
  console.log("++++ manipulateFinish")
  return of(pathEditorWidget.path$.value).pipe(
    tap(_ => pathEditorWidget.path$.complete())
  )
}

export function manipulateTransformCanvas(pathEditorWidget: PathEditorWidget, 
                                          gesture: TTouchGesture) 
{
  console.log("++++ manipulateTransformCanvas")
  return gesture.pipe(
    // ---- Convert to transform
    transformsFromGesture(),
    // ---- Convert to animation
    map(transform =>
      new BaseAnimation<Positioning>(
        pos => pos.transform(transform.repivot(Point.ZERO, pos.point)),
        0)
    ),
    // ---- Animate the positioning
    tap(animation => pathEditorWidget.montage.positioning.animation$.next(animation))
  )
}

export function manipulateZoom(pathEditorWidget: PathEditorWidget, zoom: number)
{
  return defer(() => {
    pathEditorWidget.montage.positioning.animation$.next(
      new BaseAnimation(
        (pos: Positioning) => pos.transform(new Transform({ scale: zoom })),
        300
      )
    )
    return of(zoom)
  })
}

export function manipulatePath(pathEditorWidget: PathEditorWidget, path: Path|null)
{
  return of(path).pipe(
    flatMap(path => {
      pathEditorWidget.pathNew$.next(null)
      return commandUpdatePath(pathEditorWidget, path)
        .push(pathEditorWidget.undoContext)
        .execution()
    })
  )
}

export function manipulatePathGesture(pathEditorWidget: PathEditorWidget,
                                      gesture: TTouchGesture)
  : Observable<Path|null>
{
  console.log("++++ manipulatePathGesture")

  const montagePos  = pathEditorWidget.montage.positioning.value$.value
  const montageSize = pathEditorWidget.montage.size

  function normalize(fromP: Point): Point {

    const r = fromP.subtract(montagePos.point)
                  .rotate(-montagePos.rotation)
                  .scale(1/montagePos.scale)
    const p = montageSize.scale(0.5).add(r)
    return p.normalizeTo(montageSize.width, montageSize.height)
  }
  function bound(p: Point): Point {
    const { min, max } = Math
    return new Point(min(1, max(p.x, 0)),
                     min(1, max(p.y, 0)))
  }

  const TOUCH_MIN2 = 30*30

  return gesture.pipe(

    // ---- For each gesture, convert it to a new Path
    scan2(null,
      (path: Path|null, touchEvent) => {
        const p = touchEvent.touches[0].point
        const n = bound(normalize(p))
        return (path || List()).push(n)
      }),

    // ---- Draw it as the new Path
    tap(path => pathEditorWidget.pathNew$.next(path)),

    // ---- Do nothing if path invalid
    filter((path: Path|null) => (path || false) && path.size > 0),

    // ---- When the gesture is finished, merge it
    last(null, null),
    map((newPath: Path|null) => {
      const scale = montageSize.scale(montagePos.scale)
      return consolidatePaths(pathEditorWidget.path$.value,
                        newPath,
                        TOUCH_MIN2,
                        scale)
    }),

    // ---- Reset and set the merged Path
    flatMap(mergedPath => {
      pathEditorWidget.pathNew$.next(null)
      return commandUpdatePath(pathEditorWidget, mergedPath)
        .push(pathEditorWidget.undoContext)
        .execution()
    })
  )
}

export function manipulateUndo(pathEditorWidget: PathEditorWidget) {
  return of(pathEditorWidget.undoContext.popUndo()).pipe(
    flatMap(command  =>
      (command === undefined || command.unexecution === undefined) ?
        EMPTY :
        command.unexecution()
    )
  )
}

export function manipulateRedo(pathEditorWidget: PathEditorWidget) {
  return of(pathEditorWidget.undoContext.popRedo()).pipe(
    flatMap(command  =>
      (command === undefined || command.unexecution === undefined) ?
        EMPTY :
        command.execution()
    )
  )
}
