import {COLLAGE_URL} from "../../collage_editor/models/collage"
import {BehaviorSubject} from "rxjs"
import {Size, Widget} from "@piccollage/cbjs"
import {Positioning} from "../models/positioning";

export class FloaterWidget extends Widget {

  // ---- Output properties
  sizeBase$   : BehaviorSubject<Size>
  positioning$: BehaviorSubject<Positioning>
  shade$      : BehaviorSubject<boolean>
  isVisible$  = new BehaviorSubject<boolean>(true)

  constructor(readonly image: COLLAGE_URL,
              sizeBase: Size,
              positioning: Positioning) {
    super()
    console.log("++++ FloaterWidget constructor")

    this.sizeBase$    = new BehaviorSubject(sizeBase)
    this.positioning$ = new BehaviorSubject<Positioning>(positioning)
    this.shade$       = new BehaviorSubject<boolean>(false)
  }
}

