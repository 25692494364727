import {Collage} from "../../collage_editor/models/collage";
import {Subject} from "rxjs";
import {TTap, Widget} from "@piccollage/cbjs";
import {AppWidgetContext} from "./app_widget";
import {manipulateStartNewCollage} from "../manipulators/manipulate_start_new_collage";
import {manipulateNavigateTopWidget} from "../manipulators/manipulate_navigate_top_widget";
import {HomeWidget} from "./home_widget";

export class ShareWidget extends Widget {

  newCollageTapped$  = new Subject<TTap>()
  homeTapped$        = new Subject<TTap>()

  // eslint-disable-next-line no-useless-constructor
  constructor(readonly collage: Collage) {
    super()
    console.log("++++ ShareWidget constructor")

    this.triggering(this.newCollageTapped$,
      _ => manipulateStartNewCollage(this.contexter.use(AppWidgetContext).appWidget),
    )
    this.triggering(this.homeTapped$,
      _ => manipulateNavigateTopWidget(this.contexter.use(AppWidgetContext).appWidget,
                                       this.legate(() => new HomeWidget())),
    )

  }

}
