import {
  Context,
  ContextedDomainer
} from "@piccollage/cbjs"
import {BehaviorSubject} from "rxjs";
import {Collage} from "../../collage_editor/models/collage";


export type ID = string

/**
 * TODO:
 * - Should the Persons collection be a separate collection at the same level as the Collages collection,
 *   or should the Collages collection be nested inside of the Persons collection?
 * - Where to make Firebase queries to retrieve/set initial data?
 * - Clean up Firebase stuff into separate files
 */

export class PersonContext implements Context {
  constructor(readonly person: Person) {}

}

export class Person extends ContextedDomainer {

  // ---- Properties
  displayName$: BehaviorSubject<string | null>;
  collages$: BehaviorSubject<Collage[]>;
  imageUrl$: BehaviorSubject<string | null>;


  // ---- Lifecycle
  constructor(readonly id: ID, displayName: string | null,  imageUrl: string | null) {
    super();
    console.log("++++ Person constructor")

    // ---- Setup context
    this.contexter.prepend(new PersonContext(this));

    // ---- Initialize
    this.displayName$ = new BehaviorSubject<string | null>(displayName);
    this.collages$ = new BehaviorSubject<Collage[]>([]);
    this.imageUrl$ = new BehaviorSubject<string | null>(imageUrl);
  }
}

