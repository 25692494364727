import * as React from "react";
import {CollageTileWidget} from "../ui/collage_tile_widget";
import styles from "./collage_tile_view.module.scss";
import {Subject} from "rxjs";
import {convertMouseToTouchEvent, TTap, useBehaviorSubject} from "@piccollage/cbjs";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


export function CollageTileView(props: {widget: CollageTileWidget}) {
  const onClick = (output$: Subject<TTap>) => (e: React.MouseEvent) => {
    e.stopPropagation()
    return output$.next(new TTap(convertMouseToTouchEvent(e).touches[0]));
  }

  const isTrashed = useBehaviorSubject(props.widget.isTrashed$);

  const imageURL = useBehaviorSubject(props.widget.imageURL$);

  function getButtons() {
    return (
      (!isTrashed) ?

        (<div className={styles._actionsOverlay}>
          <EditIcon
            className={styles.actionButton}
            onClick={onClick(props.widget.tappedEdit$)}
          />
          <DeleteIcon

            className={styles.actionButton}
            onClick={onClick(props.widget.tappedTrash$)}
          />
        </div>) :

        (<div className={styles._actionsOverlay}>
          <RestoreFromTrashIcon
            className={styles.actionButton}
            onClick={onClick(props.widget.tappedUntrash$)}
          />
          <DeleteForeverIcon

            className={styles.actionButton}
            onClick={onClick(props.widget.tappedDeleteForever$)}
          />
        </div>)

    )
  }

  return (
      <div className={styles.CollageTile}>
        <div className={styles._contentContainer}>
          <div
            className={styles._content__tile}
            onClick={onClick(props.widget.tappedEdit$)}
          >
            {getButtons()}
            <img
              className={styles._image}
              src={imageURL as string}
              alt={"Thumbnail"}
            />
          </div>
        </div>
      </div>
  )
}
