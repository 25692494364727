import {BehaviorSubject, combineLatest, of} from "rxjs";
import {filter, flatMap, map, switchMap} from "rxjs/operators";
import {ShareWidget} from "./share_widget";
import {CreatorWidget} from "./creator_widget";
import {Context, filterDefined, firebaseCurrentUser$, log$, LoginWidget, taplog, Widget, wiht} from "@piccollage/cbjs";
import {manipulateNavigateCollageCreator} from "../manipulators/manipulate_navigate_collage_creator";
import {manipulateNavigateCollageShare} from "../manipulators/manipulate_navigate_collage_share";
import {HomeWidget} from "./home_widget";
import {TrialWidget} from "./trial_widget";
import {manipulateNavigateTopWidget} from "../manipulators/manipulate_navigate_top_widget";
import {ViewerWidget} from "../../collage_editor/ui/viewer_widget";
import {manipulateNavigateCollageViewer} from "../manipulators/manipulate_navigate_collage_viewer";
import {Person} from "../models/person";
import {firebaseUserToPersonMapper, syncedPersonCollages$} from "../firebase/person_firestore";
import {HeaderWidget} from "./header_widget";
import AuthOverlayWidget from "./auth_overlay_widget";
import {Accessible} from "./accessible";
import {MyCollagesWidget} from "../../my_collages/ui/my_collages_widget";
import {Collage} from "../../collage_editor/models/collage";


export type TopWidget = Widget & Accessible;

export enum APP_VARIANTS {
  APP_DEFAULT,
  APP_PICCLASSROOM,
}
export var APP_VARIANT = APP_VARIANTS.APP_DEFAULT

export class AppWidgetContext implements Context {
  // eslint-disable-next-line no-useless-constructor
  constructor(readonly appWidget: AppWidget) {}

}

export class AppWidget extends Widget {
  // ---- Properties
  headerWidget$ = new BehaviorSubject<HeaderWidget|null>(null);
  authOverlayWidget: AuthOverlayWidget;

  // ---- State properties
  currentPerson$ = new BehaviorSubject<Person | null>(null)
  currentPersonCollages$ = new BehaviorSubject<Collage[]>([])

  // ---- Inputs
  path$: BehaviorSubject<string>

  // ---- Outputs
  topWidget$ = new BehaviorSubject<TopWidget|null>(null)
  loginWidget$ = new BehaviorSubject<LoginWidget | null>(null)
  bulletin$  = new BehaviorSubject<string|null>(null)


  constructor(initialPath: string) {
    super();

    // ---- Setup context
    this.contexter.prepend(new AppWidgetContext(this));

    // ---- Properties
    this.path$ = new BehaviorSubject(initialPath)
    this.authOverlayWidget = this.legate(() => new AuthOverlayWidget());

    // ---- Header widget (only show if not HomeWidget)
    const headerWidget = this.legate(() => new HeaderWidget()); // Keep reusing same one
    this.connecting(
      this.topWidget$.pipe(
        map(topWidget => topWidget instanceof HomeWidget ? null : headerWidget)
      ),
      this.headerWidget$)


    // ---- Connect the path
    // LEARN: Set in one place, rather than imperatively everywhere
    this.connectAuth();
    this.connecting(this.topWidget$.pipe(
        map(topWidget =>
          (topWidget instanceof HomeWidget ||
           topWidget instanceof MyCollagesWidget) ?  `/` :
          (topWidget instanceof CreatorWidget) ?  `/collages/${topWidget.editorWidget.collage.id}/edit` :
          (topWidget instanceof ShareWidget)   ?  `/collages/${topWidget.collage.id}/share` :
          (topWidget instanceof ViewerWidget)  ?  `/collages/${topWidget.collage.id}` :
          (topWidget instanceof TrialWidget)   ?  `/trial` :
          null
        ),
        filterDefined()
      ),
      this.path$)

    this.connecting(
      this.currentPerson$
        .pipe(
        filterDefined(),
        switchMap((currentPerson) => syncedPersonCollages$(currentPerson.id))
      ),
      this.currentPersonCollages$
    )

    // LEARN: Be careful with triggers, make sure if they change the
    // state, that there is a filter for when the state has
    // already changed.

    // ---- Trigger top level widget based on the path (if changes)
    const path$ = this.path$
    const topWidget$ = this.topWidget$;
    const currentPerson$ = this.currentPerson$;

    this.triggering(
      combineLatest([path$, topWidget$, currentPerson$]).pipe(
        filter(([path]) => !!path.match("^/$")),
        map(([_1, _2, currentPerson]) => currentPerson ? MyCollagesWidget : HomeWidget),
        filter(topWidgetClass => !(this.topWidget$.value instanceof topWidgetClass))
      ),
      topWidgetClass =>
        manipulateNavigateTopWidget(this, this.legate(() => new topWidgetClass()))
    )

    this.triggering(
      path$.pipe(
        filter(path => !!path.match("^/trial/?$")),
        filter(_ => !(this.topWidget$.value instanceof TrialWidget))
      ),
      _ => manipulateNavigateTopWidget(this, this.legate(() =>  new TrialWidget()))
    )

    this.triggering(
      path$.pipe(
        map(path => (path.match("^/collages/([^//]+)/edit/?$") || [])[1]),
        filterDefined(),
        filter(collageID => wiht(this.topWidget$.value, topWidget =>
          !(topWidget instanceof CreatorWidget) ||
          topWidget.editorWidget.collage.id !== collageID
        )),

      ),
      collageID => manipulateNavigateCollageCreator(this, collageID)
    )

    this.triggering(
      path$.pipe(
        map(path => (path.match("^/collages/([^//]+)/?$") || [])[1]),
        filterDefined(),
        filter(collageID => wiht(this.topWidget$.value, topWidget =>
          !(topWidget instanceof ViewerWidget) ||
          topWidget.collage.id !== collageID
        )),

      ),
      collageID => manipulateNavigateCollageViewer(this, collageID)
    )

    this.triggering(
      path$.pipe(
        map(path => (path.match("^/collages/([^//]+)/share/?$") || [])[1]),
        filterDefined(),
        filter(collageID => wiht(this.topWidget$.value, topWidget =>
          !(topWidget instanceof ShareWidget) ||
          topWidget.collage.id !== collageID
        ))
      ),
      collageID => manipulateNavigateCollageShare(this, collageID)
    )
  }



  connectAuth() {
    // ---- Connect firebase user to currentPerson$
    const personMapper = firebaseUserToPersonMapper();
    this.connecting(
      firebaseCurrentUser$().pipe(
        log$('++++ firebaseCurrentUser$'),
        flatMap(user => (user ? personMapper(user) : of(null))),
        taplog('++++ currentPerson$')
      ),
      this.currentPerson$
    )
  }
}
