import {EditorWidget} from "../ui/editor_widget";
import {Observable} from "rxjs";
import {
  BaseAnimation,
  Point,
  scan2,
  Transform,
  transformsFromGesture,
  TTouchEvent,
  TTouchGesture
} from "@piccollage/cbjs";
import {Positioning} from "../../toolkit/models/positioning";
import {map, tap} from "rxjs/operators";

export function manipulateBackdropTransform(editorWidget: EditorWidget, gesture: TTouchGesture)
  : Observable<Positioning>
{
  const pInitial = editorWidget.backdropWidget.positioning.value$.value

  const transform$ = gesture.pipe(

    // ---- Commit to disable other effects of dragging
    tap(e => TTouchEvent.commit(e)),

    // ---- 1. Convert to transforms
    transformsFromGesture()
  )

  return transform$.pipe(

    // ---- 2. Accumulate transforms
    scan2(Transform.ZERO, (total: Transform, t: Transform) => total.plus(t)),

    // ---- 3. Calculate manipulation position
    map((t: Transform) => {
      const tRepivot: Transform = t.repivot(Point.ZERO, pInitial.point)
      return pInitial.transform(tRepivot)
    }),

    // ---- 4. Connect animation to new position
    tap((p: Positioning) => {
      editorWidget.backdropWidget.positioning.animation$.next(
        new BaseAnimation(_ => p, 0.0)
      )
    }),
  )
}