import * as firebase from "firebase/app";
import {Collage, COLLAGE_URL} from "../collage_editor/models/collage"
import {concat, EMPTY} from "rxjs"
import {flatMap, map, tap} from "rxjs/operators"
import {firestoreCollage, firestoreCollages, firestoreCollageScraps, syncedCollage$} from "./collage_firestore"
import {
  Command,
  DocRef,
  DocSnap,
  firestoreCloneCollection,
  firestoreCloneReference,
  firestoreDeleteCollection,
  ID,
  promise$,
  Size
} from "@piccollage/cbjs";
import {fieldify, fieldifyDeletable, fieldifyIf} from "./command_firestore_fieldify";
import {Struct_Collage} from "./command_firestore_struct";
import {Person} from "../app/models/person";

// =====================================================================

// `commandLoadCollage` will keep sending the same Collage every time it changes,
// and will continue to sync it as long as the Observable is alive.
// If you only need one make sure to use `filterFirst`.
// Handles assigning an owner_id to the collage if there is a user signed in.
//
export function commandCreateCollage(data: Object = {})
  : Command<Collage> {

  return new Command(
    `commandCreateCollage`,
    () => {
      console.log("++++ commandCreateCollage")
      return promise$(
        () => firestoreCollages().add(data))
        .pipe(
          flatMap((collageRef: DocRef) => {
            if (!collageRef) {
              console.error("++++ commandCreateCollage unable to create Collage")
              return EMPTY
            } else {
              firebase.analytics().logEvent("collage_created", { collage_id: collageRef.id, })
              return commandLoadCollage(collageRef.id)
                .execution()
            }
          })
        )
    })
}

// `commandLoadCollage` will keep sending the same Collage every time it changes,
// and will continue to sync it as long as the Observable is alive.
// If you only need one make sure to use `filterFirst`.
//
export function commandLoadCollage(id: ID)
  : Command<Collage> {
  return new Command(
    `commandLoadCollage(${id})`,
    () => {
      console.log("++++ Firestore loading Collage", id)
      return promise$(
        () => firestoreCollages().doc(id).get())
        .pipe(
          flatMap((collageSnap: DocSnap) => {
            if (!collageSnap) {
              console.error("++++ commandLoadCollage no Collage found!", id)
              return EMPTY
            } else {
              return syncedCollage$(collageSnap.ref)
            }
          })
        )
    })
}

export function commandCloneCollage(collage: Collage | DocRef, currentPerson: Person | null)
  : Command<DocRef> {
  return new Command(
    `commandCloneCollage(${collage.id})`,
    () => {
      console.log("++++ Firestore cloning Collage", collage);

      // ---- Clone the collage
      return firestoreCloneReference(
        firestoreCollage(collage),
        firestoreCollages(),
        {
          clonee_id: collage.id,
          ...fieldify('owner_id', currentPerson ? currentPerson.id : undefined),
          created_at: firebase.firestore.Timestamp.now(),
          modified_at: firebase.firestore.Timestamp.now(),
          is_trashed: false
        }
      ).pipe(
        tap(newCollageRef =>
          firebase.analytics().logEvent("collage_cloned", { collage_id: newCollageRef.id, })),
        // ---- Clone the scraps
        flatMap(newCollageRef =>
          firestoreCloneCollection(
            firestoreCollageScraps(collage),
            firestoreCollageScraps(newCollageRef)
          ).pipe(
            map(_ => newCollageRef)
          )
        ),
      )

    })
}

type CollageUpdate = {
  size?:            Size,
  collage_url?:     COLLAGE_URL | null,
  collage_fb_url?:  COLLAGE_URL | null,
  background_url?:  COLLAGE_URL | null,
  thumbnail_url?:   COLLAGE_URL | null
}
export function commandUpdateCollage(collage: Collage,
                                     update: CollageUpdate)
  : Command<Collage, Partial<Struct_Collage>> {

  function sizefy(size: Size): [number, number] {
    return [size.width, size.height]
  }
  function write(s: Partial<Struct_Collage>) {
    console.log("++++ commandUpdateCollage update", collage, s)
    return promise$(() => firestoreCollage(collage).update(s)).pipe(
      map(_ => collage),
    )
  }
  return new Command(
    `commandUpdateCollage(${collage.id}, url})`,
    command => {
      command.memo = {
        ...fieldifyIf(update.size,
          fieldify('size', collage.size$.value && sizefy(collage.size$.value))),
        ...fieldifyIf(update.collage_url,
          fieldifyDeletable('collage_url', collage.collageURL$.value)),
        ...fieldifyIf(update.background_url,
          fieldifyDeletable('background_url',  collage.backgroundURL$.value)),
        ...fieldifyIf(update.thumbnail_url,
          fieldifyDeletable('thumbnail_url',  collage.thumbnailURL$.value)),
      }
      return write({
        ...fieldify(         'size',           update.size && sizefy(update.size)),
        ...fieldifyDeletable('collage_url',    update.collage_url),
        ...fieldifyDeletable('collage_fb_url', update.collage_fb_url),
        ...fieldifyDeletable('background_url', update.background_url),
        ...fieldifyDeletable('thumbnail_url',  update.thumbnail_url),
      })
    },
    command => {
      // ---- Unexecute
      return command.memo ? write(command.memo) : EMPTY
    })
}

export function commandTrashCollage(collage: Collage) {
  return new Command(
    `commandTrashCollage(${collage.id})`,
    () => {
      const id = collage.id;
      console.log("++++ commandTrashCollage trash", id);
      return promise$(
        () => firestoreCollages().doc(id).update(
          {
            is_trashed: true,
            trashed_at: firebase.firestore.Timestamp.now()
          }
        )
      )
    }
  )
}

export function commandUntrashCollage(collage: Collage) {
  return new Command(
    `commandUntrashCollage(${collage.id})`,
    () => {
      const id = collage.id;
      console.log("++++ commandUntrashCollage untrash", id);
      return promise$(
        () => firestoreCollages().doc(id).update(
          {
            is_trashed: false,
            trashed_at: firebase.firestore.FieldValue.delete(),
            // modified_at: firebase.firestore.Timestamp.now()
          }
        )
      )
    }
  )
}

// TODO: If collage is trashed and deleted in the same browser session,
//       the view doesn't update as it still tries to sync with the deleted collage.

export function commandDeleteCollage(collage: Collage) {
  return new Command(
    `commandDeleteCollage(${collage.id})`,
    () => {
      const id = collage.id;
      console.log("++++ commandDeleteCollage delete", id);
      const delScraps$  = firestoreDeleteCollection(firestoreCollageScraps(collage))
      const delCollage$ = promise$(() => firestoreCollage(collage).delete())
      return concat(delScraps$, delCollage$);
    }
  )
}

export function commandUpdateModifiedAt(collage: Collage)
{
  return new Command(
    `commandUpdateModifiedAt(${collage.id})`,
    () => {
      const id = collage.id;
      console.log("++++ commandUpdateModifiedAt", id);
      return promise$(
        () => firestoreCollages().doc(id).update(
          {modified_at: firebase.firestore.Timestamp.now()}
          )
      )
    }
  )
}



// LEARN: Polymorphism using functions

// ---- Utility/Common



