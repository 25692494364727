import {ScrapWidget} from "./scrap_widget";
import {SketchScrap} from "../models/sketch_scrap";
import {BehaviorSubject} from "rxjs";
import {Stroke} from "../../toolkit/models/stroke";
import {convertPointFromBoundingBox, Point, Rect} from "@piccollage/cbjs";
import {isCloseToPath, pathScale} from "../../toolkit/models/path";
import {EditorContext} from "./editor_context";

export class SketchScrapWidget extends ScrapWidget {
  strokes$: BehaviorSubject<Stroke[]>

  constructor(readonly sketchScrap: SketchScrap) {
    super(sketchScrap)

    this.strokes$ = new BehaviorSubject<Stroke[]>(sketchScrap.strokes$.value)
    this.connecting$(sketchScrap.strokes$, this.strokes$)
  }

  // ---- Override isTargetPrecise for hits near a stroke
  isTargetPrecise(p: Point, rect: Rect, distance: 1): boolean {

    // ---- Convert to normalized rotated coordinates
    const size = this.sizeBase$.value
    const positioning = this.scrap.positioning$.value
    const scrapP = convertPointFromBoundingBox(p, rect,
      positioning.rotation,
      size
    )

    console.log(">>>> SketchScrapWidget isTargetPrecise scrapP", p, rect,
      "----", scrapP, distance, size)

    // ---- Calculate stroke distance
    const editorContext = this.contexter.get(EditorContext)
    const backdropScale = editorContext?.backdropWidget.positioning.value$.value.scale
    const strokeDistance = distance / (positioning.scale * (backdropScale ||  1))
    console.log(">>>> SketchScrapWidget strokeDistance", strokeDistance)

    // ---- Check all the strokes
    const strokes = this.strokes$.value
    const stroke = strokes.find(stroke =>
      isCloseToPath(scrapP, pathScale(stroke.path, size), strokeDistance)
    )
    return !!stroke
  }
}