import {EditorWidget, mapScrapToScrapWidget} from "../ui/editor_widget"
import {Observable} from "rxjs"
import {SCRAP_DEFAULT_BASE_SIZE} from "../models/scrap";
import {TextScrapWidget} from "../ui/text_scrap_widget";
import {Font} from "../../toolkit/models/font";
import {Positioning} from "../../toolkit/models/positioning";
import {interject, Point} from "@piccollage/cbjs";
import {TextSpec} from "../models/text_spec";
import {first} from "rxjs/operators";
import {manipulateEditText} from "./manipulate_edit_text";
import {commandAddTextScrap} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

const DEFAULT_TEXT = "Text"

export function manipulateAddText(editorWidget: EditorWidget,
                                  p: Positioning|Point)
  : Observable<TextScrapWidget>
{
  console.log("++++ manipulateAddText", p)

  const collage = editorWidget.collage
  const backdropP = editorWidget.backdropWidget.positioning.value$.value
  const positioningCollage = p instanceof Positioning ?
    p :
    new Positioning(p,
      0,
      1/backdropP.scale,
      collage.scrapZMax() + 1
    )
  console.log("++++ manipulateAddText positioningCollage", positioningCollage)

  return commandAddTextScrap(collage,
      positioningCollage,
      SCRAP_DEFAULT_BASE_SIZE,
      new TextSpec({
        t: DEFAULT_TEXT,
        font: new Font("serif"),
      })
    )
    .push(editorWidget.undoContext)
    .execution()
    .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
    .pipe(
      // ---- Get first so we can complete
      first(),

      // ---- Map to TextScrapWidget and manipulate it so it gets
      mapScrapToScrapWidget<TextScrapWidget>(editorWidget),

      // ---- Open text editor
      interject(textScrapWidget => manipulateEditText(editorWidget, textScrapWidget)),

    )
}