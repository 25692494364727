import {Widget} from "@piccollage/cbjs";
import {Accessible} from "../../app/ui/accessible";
import {Person} from "../../app/models/person";
import {BehaviorSubject} from "rxjs";
import {SideBarWidget} from "./side_bar_widget";
import {AllCollagesWidget} from "./all_collages_widget";

export class MyCollagesWidget extends Widget implements Accessible {

  // ---- Interface - Accessible
  isAccessible(currentPerson: Person | null) {
    return !!currentPerson;
  }

  // ---- Outputs
  currentTabWidget$ = new BehaviorSubject<Widget|null>(null)
  sideBarWidget: SideBarWidget

  constructor() {
    super();
    console.log("++++ MyCollagesWidget constructor");

      // ---- Initialization
    this.sideBarWidget = this.legate(() => new SideBarWidget(this));
    this.currentTabWidget$.next(
      this.legate(() => new AllCollagesWidget())
    );
  }
}
