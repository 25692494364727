import {Font} from "../../toolkit/models/font";
import {Color} from "../../toolkit/models/color";
import {filterObject} from "@piccollage/cbjs";

export enum TextAlignment {
  Left = "left",
  Right = "right",
  Center = "center",
  Justify = "justify",
}

export const TextColorDefault = Color.BLACK

export class TextSpec {
  readonly t?: string
  readonly font?: Font

  readonly alignment?:        TextAlignment
  readonly color?:            Color
  readonly colorStroke?:      Color|null
  readonly colorBackground?:  Color|null
  readonly widthStroke?:      number

  constructor(spec: TextSpec = {}) {
    Object.assign(this, spec)
  }

  static DEFAULT = new TextSpec()

}

export function textSpecMerge(a: TextSpec, b: TextSpec): TextSpec {
  return new TextSpec({ ...filterObject(b), ...filterObject(a) })
}