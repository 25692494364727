import {COLLAGE_URL} from "../models/collage";
import {ScrapWidget} from "../ui/scrap_widget";
import {defer, Observable} from "rxjs";
import {commandUpdateScrapLinkUrl} from "../../collage_firebase/command_firestore_scrap";
import {LinkEditorWidget} from "../../link_editor/ui/link_editor_widget";
import {EditorWidget} from "../ui/editor_widget";
import {finalize, flatMap, last, map, takeUntil} from "rxjs/operators";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateScrapLink(editorWidget: EditorWidget, scrapWidget: ScrapWidget)
  : Observable<COLLAGE_URL|null> {

  console.log("++++ manipulateScrapLink", scrapWidget.scrap)

  return defer(() => {
    // ---- Create the editor
    const editor = editorWidget.legate(() => new LinkEditorWidget(scrapWidget.linkUrl$.value))
    editorWidget.linkEditorWidget$.next(editor)
    return editor.linkUrl$.pipe(
      takeUntil(editor.tappedDone$)
    )
  }).pipe(

    // ---- Normalize to null
    map(url => url || null),

    // ---- Make sure absolute url
    map(url => {
      if (url === null)
        return url
      var s = url.trim()
      if (!s?.startsWith('http'))
        s = 'http://' + s
      return s
    }),

    // ---- Generate command when final
    last(),
    flatMap(url =>
      commandUpdateScrapLinkUrl(scrapWidget.scrap, url)
        .push(editorWidget.undoContext)
        .execution()
        .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
    ),
    finalize(() => {
      editorWidget.linkEditorWidget$.next(null)
    })
  )
}