import {Observable, of} from "rxjs";
import {FaceRequest, ImageScrapWidget} from "../ui/image_scrap_widget";
import {filter, flatMap, map, take} from "rxjs/operators";
import {Point as P} from "tfjs-image-recognition-base"
import {generateTimestamp, Point, Size, UndoContext} from "@piccollage/cbjs";
import {List} from "immutable";
import {manipulatePositioningForPadding} from "./manipulate_adjust_positioning_for_padding";
import {commandUpdateImageScrap} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

const FACE_DEFAULT_SIZE = new Size(300, 300)
export function manipulateRecognizeFaces(imageScrapWidget: ImageScrapWidget)
  : Observable<ImageScrapWidget>
{
  console.log("++++ manipulateRecognizeFaces")

  return of(imageScrapWidget).pipe(

    // ---- Wait for the imageInfo$ to be loaded
    flatMap(widget => widget.imageInfo$.pipe(
      filter(imageInfo => imageInfo.isLoaded && !!imageInfo.size),
      take(1),
      map(imageInfo => ({ widget, imageInfo }))
    )),

    // ---- Do face recognition
    flatMap(({ widget, imageInfo }) => {
      console.log("++++ manipulateRecognizeFaces widget", widget, imageInfo)
      const request = new FaceRequest()
      widget.faceRequest$.next(request)
      return request.face$.pipe(
        take(1),
        map(face => ({ widget, imageInfo, face }))
      )
    }),

    // ---- Set the clipping path
    flatMap(({ widget, imageInfo, face }) => {
      console.log("++++ manipulateRecognizeFaces clip", widget, imageInfo, face)
      const { x: width, y: height } = imageInfo.size!!

      const pmap = (p: P) => new Point(p.x, p.y)

      // See https://docs.google.com/spreadsheets/d/1qRoY-mbKtae4JDYG4YsGDSaduqN3AeVYmCL08igZMEg/edit?usp=sharing
      // for the breakdown of the facepoint indices.
      //
      const ps = face.landmarks.positions
      const bottom    = ps.slice(0, 17)
      const noseV     = pmap(ps[27]).subtract(pmap(ps[30]))

      const path = [
        ...bottom.map(pmap),
        pmap(ps[16]).add(noseV),
        pmap(ps[24]).add(noseV),
        pmap(ps[19]).add(noseV),
        pmap(ps[0]).add(noseV),
        pmap(ps[0])
      ]

      // ---- Normalize the new path
      const normalized = path.map(p => p.normalizeTo(width, height))

      // ---- Update the collage
      return commandUpdateImageScrap(widget.imageScrap,
                                     {
                                       clipping_path: List(normalized),
                                       clipping_path_timestamp: generateTimestamp()
                                     })
        .tap(c => widget.contexter.get(UndoContext)?.push(c))
        .execution()
        .pipe(EditorContext.findFrom(widget)?.modifies() || NOOP)
        .pipe(

          // ---- Update adjust for padding
          flatMap(_ =>
            manipulatePositioningForPadding(
                              widget,
                              widget.positioning.value$.value.point,
                              FACE_DEFAULT_SIZE)
          ),

          // ---- Return
          map(_ => widget)
        )

    })
  )
}
