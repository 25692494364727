import {MontageWidget, PathEditorWidget} from "../ui/path_editor_widget";
import React, {CSSProperties, RefObject, useEffect, useRef} from "react"
import {Button, Fab} from "@material-ui/core";
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import DoneIcon from '@material-ui/icons/Done';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import {BehaviorSubject, Subject} from "rxjs";
import {positioningToCSS, useResize, useSpringProperties} from "../../app/views/view_util";
import {isTouchy, Size, useBehaviorSubject, useGestures} from "@piccollage/cbjs";
import {animated} from 'react-spring'
import {Path} from "../../toolkit/models/path";
import ReactTooltip from 'react-tooltip'
import styles from "./path_editor_view.module.scss"

export function PathEditorView(props: { widget: PathEditorWidget })
{
  console.log("++++ PathEditorView render")

  // ---- Button clicks
  function onClick(subject: Subject<boolean>) {
    return () => { subject.next(true) }
  }

  // ---- Connect to get the size
  const refCanvas = useRef(null)
  useResize(refCanvas, size => props.widget.viewSize$.next(size))

  // ---- Gestures
  useGestures(refCanvas, g => props.widget.gesture$.next(g))

  // ---- Undo/Redo
  const canUndo = useBehaviorSubject(props.widget.undoContext.canUndo$)
  const canRedo = useBehaviorSubject(props.widget.undoContext.canRedo$)

  // ---- Render
  return (
    <div className={ styles.root } >

      <div ref={refCanvas} className={ styles.canvas } >
        <MontageView widget={props.widget.montage}>
          <img alt="original" src={props.widget.imageURL} className={styles.img} />
          <PathView path$={props.widget.path$}    size={props.widget.imageSize} color="#00ccff" closed={true} headed={true}/>
          <PathView path$={props.widget.pathNew$} size={props.widget.imageSize} color="#66ff33"/>

        </MontageView>
      </div>

      <ReactTooltip/>

      <div className={ styles.sidebar }>
        <button className={styles.zoomInButton}
             data-tip={ isTouchy ? null : "Press Shift to pan image" }
             onClick={onClick(props.widget.tappedZoomIn$)} >
          <ZoomInIcon />
        </button>
        <button className={styles.zoomOutButton}
                data-tip={ isTouchy ? null : "Press Shift to pan image" }
             onClick={onClick(props.widget.tappedZoomOut$)} >
          <ZoomOutIcon />
        </button>
      </div>

      <div className={ styles.header }>
        {/* Header should go on top so it doesn't catch the clicks */ }

        <button className={styles.backButton}
             onClick={onClick(props.widget.tappedDone$)} >
          <ArrowBackIcon />
        </button>

        <div>
          <Button onMouseDown={ onClick(props.widget.tappedUndo$) }
                  className={ styles.undoRedoButton }
                  disabled={!canUndo}
                  color='primary' >
            <UndoIcon />
          </Button>
          <Button onMouseDown={ onClick(props.widget.tappedRedo$) }
                  className={ styles.undoRedoButton }
                  disabled={!canRedo}
                  color='primary' >
            <RedoIcon />
          </Button>
        </div>

        <button className={styles.doneButton}
             onClick={onClick(props.widget.tappedDone$)} >
          <DoneIcon />
        </button>

      </div>

      <footer className={ styles.footer } >
        <Fab variant="extended"
             color="primary"
             onClick={onClick(props.widget.tappedReset$)} >
          <ClearIcon />&nbsp;
          Reset
        </Fab>
      </footer>
    </div>
  )

}

function MontageView(props: { widget: MontageWidget, children: React.ReactNode|React.ReactNodeArray })
{
  console.log("++++ MontageView render")

  // ---- Position and animation
  const [pos, posAnimation] =
    useBehaviorSubject(props.widget.positioning.output$)

  const style: CSSProperties = {
    position: 'absolute',
    display: 'block',
    width:  props.widget.size.width,
    height: props.widget.size.height,
  }
  const spring = useSpringProperties(
    positioningToCSS(pos, true),
    positioningToCSS(posAnimation.valueTo(pos), true),
    { duration: posAnimation.t },
  )

  return (
    <animated.div style={ { ...style, ...spring } }>
      { props.children }
    </animated.div>
  )
}

function PathView(props: { path$: BehaviorSubject<Path|null>,
                           size: Size,
                           color: string,
                           closed?: boolean,
                           headed?: boolean
                           })
{
  const canvasRef: RefObject<HTMLCanvasElement> = useRef(null)
  const size = props.size
  const closed = props.closed || false

  const path = useBehaviorSubject(props.path$)

  const dim = size.width / 300

  useEffect(() => {
    const canvas: HTMLCanvasElement|null = canvasRef.current
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, size.width, size.height)

        if (path) {

          // ---- Setup line
          ctx.beginPath()
          ctx.lineWidth = dim
          ctx.strokeStyle = props.color
          ctx.fillStyle = props.color

          // ---- Draw ends
          if (props.headed && path.size >= 2) {
            ctx.setLineDash([])
            const p = path.get(0)!!.scale(size)
            ctx.arc(p.x, p.y, dim * 4, 0, 2 * Math.PI, false)
            ctx.fill()
            ctx.stroke()
          }

          // ---- Draw path
          ctx.setLineDash([dim * 3, dim])
          path.forEach((p, i) => {
            const n = p.scale(size)
            if (i === 0)
              ctx.moveTo(n.x, n.y)
            else
              ctx.lineTo(n.x, n.y)
          })

          if (closed)
            ctx.closePath()

          ctx.stroke()
        }
      }
    }
  })

  return (
    <canvas ref={canvasRef} className={styles.path_canvas}
            width={size.width} height={size.height}
    >
          
    </canvas>
  )
  
}