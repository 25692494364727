import {Point, Transform, useBehaviorSubject} from "@piccollage/cbjs";
import {positioningToCSS, useSpringProperties} from "../../app/views/view_util";
import {Positioning} from "../../toolkit/models/positioning";
import {animated} from "react-spring";
import React from "react";
import {ScrapWidget} from "../ui/scrap_widget";
import styles from "./scrap_decoration_view.module.scss"

export function ScrapDecorationView(props: {
  scrapWidget: ScrapWidget,
  children?:   any,
  placement?:  Point,    // Normalized position from the center of the scrap.
  offset?:     Point,    // Absolute offset applied after placement above.
}) {

  const placement = props.placement || Point.ZERO
  const offset    = props.offset    || Point.ZERO

  const scrapWidget = props.scrapWidget
  let [widgetPos, widgetPosAnimation] =
                    useBehaviorSubject(scrapWidget.positioning.output$)
  const size    =   useBehaviorSubject(scrapWidget.sizeActual$)
  const padding =   useBehaviorSubject(scrapWidget.padding$)

  // Offset is the vector from the *center* of the scrap
  const p = (
    padding ?
      padding.origin
        .subtract(Point.HALF)
        .add(padding.size.scale(0.5))
        .add(placement.scale(padding.size))
      :
      placement
  ).scale(size)
  function adjustPositioning(pos: Positioning) {
    return new Positioning(pos.addPoint(p)).transform(new Transform({ move: offset }))
  }

  const spring = useSpringProperties(
    positioningToCSS(
      adjustPositioning(widgetPos),
      true),
    positioningToCSS(
      adjustPositioning(widgetPosAnimation.valueTo(widgetPos)),
      true),
    {duration: widgetPosAnimation.t}
  )
  return (
    <animated.div style={spring} className={styles.ScrapDecorationView}>
      { props.children }
    </animated.div>
  )
}