import {EditorWidget} from "../ui/editor_widget";
import {take, tap} from "rxjs/operators";
import {StickerChooserWidget} from "../../sticker_chooser/ui/sticker_chooser_widget";
import {StickerRepoFirebase} from "../../collage_firebase/stickers_firebase";

export function manipulateToggleStickers(editorWidget: EditorWidget)
{
  console.log("++++ manipulateToggleStickers")
  return editorWidget.stickerChooserWidget$.pipe(
    take(1),
    tap(curWidget => {
      if (curWidget) {
        editorWidget.stickerChooserWidget$.next(null)
      }
      else {
        const w = editorWidget.legate(() =>
          new StickerChooserWidget(StickerRepoFirebase.instance()))
        editorWidget.stickerChooserWidget$.next(w)
      }
    })
  )

}
