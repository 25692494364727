// Note that Gesture must have only ONE touch, must be filtered beforehand.
import {EditorWidget} from "../ui/editor_widget"
import {ScrapWidget} from "../ui/scrap_widget"
import {Observable} from "rxjs"
import {commandTrashScrap} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

export function manipulateTrashScrap(editorWidget: EditorWidget,
                                      scrapWidget: ScrapWidget)
  : Observable<any>
{
  return commandTrashScrap(scrapWidget.scrap, true)
    .push(editorWidget.undoContext)
    .execution()
    .pipe(EditorContext.findFrom(editorWidget)?.modifies() || NOOP)
}
