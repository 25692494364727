import {useBehaviorSubject} from "@piccollage/cbjs";
import React, {ChangeEvent} from "react";
import {TextField} from "@material-ui/core";
import {LinkEditorWidget} from "../ui/link_editor_widget";
import styles from "./link_editor_view.module.scss";

export function LinkEditorView(props: { widget: LinkEditorWidget })
{
  const linkUrl = useBehaviorSubject(props.widget.linkUrl$)
  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target)
      props.widget.linkUrl$.next(e.target.value)
  }

  return (
    <div className={styles.LinkEditorView}>
      <div className={styles.label}>Link to:</div>
      <TextField variant="outlined"
                 style={{ width: 400 }}
                 onChange={ onChange }
                 value={ linkUrl } />
    </div>
  )
}