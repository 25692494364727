import {BaseAnimation, Point, transformsFromRadialGesture, TTouchGesture, UndoContext} from "@piccollage/cbjs"
import {flatMap, last, map, tap} from "rxjs/operators"
import {Positioning} from "../../toolkit/models/positioning"
import {Collage} from "../models/collage";
import {repositionTouchEvent} from "../../toolkit/models/positioning_util";
import {DotWidget} from "../ui/dot_widget";
import {commandUpdateScrapPositioning} from "../../collage_firebase/command_firestore_scrap";
import {EditorContext, NOOP} from "../ui/editor_context";

// Note that Gesture must have only ONE touch, must be filtered beforehand.
export function manipulateMagicDotTransform(gesture: TTouchGesture,
                                         collageOffset: Positioning,
                                         collage: Collage,
                                         dotWidget: DotWidget)
{
  console.log("++++ manipulateMagicDotTransform", gesture)

  const scrapWidget = dotWidget.scrapWidget
  const pInitial = scrapWidget.scrap.positioning$.value
  const padding = scrapWidget.padding$.value

  // Figure out "center" pivot (must be in Collage coordinates), depending
  // on whether there's padding or not
  const pivot = padding ?
    pInitial.addPoint(
      padding.center.subtract(Point.HALF).scale(scrapWidget.sizeBase$.value)) :
    pInitial.point

  return gesture.pipe(

    // ---- Convert gesture to collage coordinates
    map(event => repositionTouchEvent(event, collageOffset)),

    // ---- Calculate transform
    transformsFromRadialGesture(pivot),

    // ---- Repivot to Scrap
    map(t => t.repivot(pivot, pInitial.point)),

    // ---- Apply to position
    map(t => pInitial.transform(t)),

    // ---- Animate to new position
    tap((p: Positioning) => {
        scrapWidget.positioning.animation$.next(
          new BaseAnimation(_ => p, 0.0)
        )
      }),
    // ---- When finished, issue command
    last(null, pInitial),
    flatMap(p =>
      commandUpdateScrapPositioning(scrapWidget.scrap, p)
        .tap(c => scrapWidget.contexter.get(UndoContext)?.push(c))
        .execution()
        .pipe(EditorContext.findFrom(scrapWidget)?.modifies() || NOOP)
    )
  )
}