import {LoginWidget, Widget} from "@piccollage/cbjs";
import {BehaviorSubject, combineLatest} from "rxjs";
import {map} from "rxjs/operators";
import {AppWidgetContext, TopWidget} from "./app_widget";
import {Person} from "../models/person";

export default class AuthOverlayWidget extends Widget {
  shouldDisplay$: BehaviorSubject<Boolean>;
  loginWidget = new LoginWidget();

  constructor() {
    super();
    this.shouldDisplay$ = new BehaviorSubject<Boolean>(false);
    this.connectDisplaying();
  }

  connectDisplaying() {
    const appWidget = this.contexter.use(AppWidgetContext).appWidget;

    const isAccessible = function(topWidget: TopWidget | null, currentPerson: Person | null) {
      return (!topWidget ||
        !topWidget.isAccessible || // defaults to true if null
        topWidget.isAccessible(currentPerson)
      );
    }

    this.connecting(
      combineLatest([appWidget.topWidget$, appWidget.currentPerson$]).pipe(
        map(args => !isAccessible(...args))
      ),
      this.shouldDisplay$
    );
  }


}
