import {FloaterWidget} from "../ui/floater_widget";
import {CSSProperties, default as React} from "react";
import {positioningToCSS} from "../../app/views/view_util";
import {Size, useBehaviorSubject, useObservable} from "@piccollage/cbjs";
import {BehaviorSubject, EMPTY} from "rxjs";
import {flatMap} from "rxjs/operators";
import {Positioning} from "../models/positioning";


function useBehaviorSubjectMap<BASE, SUB>(base$: BehaviorSubject<BASE|null>,
                                     map: (base: BASE) => BehaviorSubject<SUB>)
  : SUB|null
{
  return useObservable(base$.pipe(
    flatMap(base => base ? map(base) : EMPTY)),
    base$.value && map(base$.value).value
  )
}

export function FloaterView(props: { widget$: BehaviorSubject<FloaterWidget|null> }) {
  const widget      = useBehaviorSubject(props.widget$)
  const sizeBase    = useBehaviorSubjectMap(props.widget$, w => w.sizeBase$)
                      || Size.ZERO
  const positioning = useBehaviorSubjectMap(props.widget$, w => w.positioning$)
                      || Positioning.ZERO
  const shade       = useBehaviorSubjectMap(props.widget$, w => w.shade$)
                      || false
  const isVisible   = useBehaviorSubjectMap(props.widget$, w => w.isVisible$)
                      || false

  const src = widget ? widget.image : '//:0'
    // See https://stackoverflow.com/questions/5775469/whats-the-valid-way-to-include-an-image-with-no-src


  // NOTE: Why is this so complicated?
  // First we try to reuse the same DOM component and only change the display
  // when the widget is not being displayed.
  // Also, in mobile Safari/Chrome there is a delay in loading the `img`, so the
  // dragging experience isn't good. So we display a box with a transparent
  // background as a placeholder while the image loads.
  // TODO: Make this better.

  const style: CSSProperties = {
    display: widget && isVisible ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: shade ? 'rgba(200,200,200,0.25)' : 'rgba(0,0,0,0)',

    position: "absolute",
    width:     sizeBase.width,
    minHeight: sizeBase.height,
    height: "auto",
    ...positioningToCSS(positioning, true),

    borderRadius: '10%',
    zIndex: 1500,   // Set high enough according to this to be on top
                    // https://material-ui.com/customization/z-index/
  }
  const styleImg: CSSProperties = {
    width: sizeBase.width,
  }
  return (
    <div style={style}>
      <img alt="" style={styleImg} src={src}/>
    </div>

  )
}