import {Fonts, TextFontPanelWidget, TextFontWidget} from "../ui/text_font_panel_widget";
import {useBehaviorSubject} from "@piccollage/cbjs";
import styles from "./text_font_panel_view.module.scss";
import React, {CSSProperties} from "react";
import {Button} from "@material-ui/core";
import {fontConfig} from "../../app/views/view_util";
import WebFont from "webfontloader";
import * as _ from "lodash"

// ---- Preload the choice fonts
setTimeout(() => {
  const config = _.reduce(Fonts, (config, font) => fontConfig(font, config), {})
  console.log("++++ Fonts config", config)
  WebFont.load(config)
})

export function TextFontPanelView(props: { widget: TextFontPanelWidget }) {
  console.log("++++ TextFontPanelView render", props)

  const fontWidgets = useBehaviorSubject(props.widget.fontWidgets$)
  const fontWidgetSelected = useBehaviorSubject(props.widget.fontWidgetSelected$)

  return (
    <div className={styles.TextFontPanelView}>
      <div className={styles.inner} >
        {fontWidgets.map(fontWidget => (
          <TextFontView key={fontWidget.font.family}
                        widget={fontWidget}
                        isSelected={
                          fontWidget.font.isEqual(fontWidgetSelected && fontWidgetSelected.font)
                        }/>
        ))}
      </div>
    </div>
  )
}

function TextFontView(props: { widget: TextFontWidget, isSelected: boolean }) {
  const font = props.widget.font
  const style: CSSProperties = {
    fontFamily: font.family,
  }
  return (
    <Button variant={props.isSelected ? "contained" : "outlined"}
            color={props.isSelected ? "primary" : "default"}
            onClick={_ => props.widget.tapped$.next(true)}
            className={styles.Button}>
      <div style={style} className={styles.TextFontView}>
        {font.title}
      </div>
    </Button>
  )
}

