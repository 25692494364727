import {Point, Rect, RefStore, Size, TTouch, TTouchEvent, TTouchGesture, TTouchTargeting} from "@piccollage/cbjs"
import {map} from "rxjs/operators";
import {CSSProperties, RefObject, useEffect} from "react";
import {ResizeObserver} from "resize-observer";
import {SpringConfig, useSpring} from "react-spring";
import WebFont from "webfontloader";
import {Font} from "../../toolkit/models/font";
import {Positioning} from "../../toolkit/models/positioning";

export function korRectFromClientRect(rect: ClientRect): Rect {
  return new Rect(
    new Point(rect.left, rect.top),
    new Size(rect.right - rect.left, rect.bottom - rect.top)
  )
}

export function useSpringProperties(
  from: CSSProperties,
  to: CSSProperties,
  config: SpringConfig = {})
{
  return useSpring({
    config,
    from: {
      ...from
    },
    ...to,
  })
}
export function positioningToCSS(p: Positioning,
                                 center: boolean = false,
                                 downscale: number = 1.0)
{
  const translate = center ? 'translate(-50%,-50%)' : ''
  const scale = p.scale * downscale
  return {
    left:         `${p.point.x}px`,
    top:          `${p.point.y}px`,
    transform:    `${translate} rotate(${p.rotation}rad) scale(${scale}, ${scale}) `,
                  // Note that `translate` HAS to go first
  }
}

export function addTargetsToGesture<T>(refStore: RefStore<T, Element>)
  : (gesture: TTouchGesture) => TTouchGesture {

  function targetingsAt(touch: TTouch): TTouchTargeting[] {
    const point = touch.point
    return refStore.stored

      // ---- Filter no reference
      .filter(({ ref }) => ref.current)

      // ---- Add in client rect
      .map(stored => ({
        ...stored,
        rect: korRectFromClientRect(stored.ref.current!.getBoundingClientRect())
      }))

      // ---- Filter if out of rect
      .filter(({ rect }) => rect.contains(point))

      // ---- Convert to TTouchTargeting
      .map(({ key: target, rect }) => ({ target, rect }))
  }

  return gesture => gesture.pipe(
    map(event => new TTouchEvent(
      event.touches.map(touch =>
        new TTouch(touch.identifier,
                   touch.point,
                   touch.button,
                   [...touch.targetings, ...targetingsAt(touch)]
                   )
      ),
      event.t,
      event.platform
    ))
  )
}

export function useResize(element: RefObject<Element>|Element|Window|null, f: (size: Size) => void)
{
  useEffect(() => {

    let e = element as any
    if (e && e.current !== undefined)
      e = e.current

    const listener = () => {
      if (e !== null && e !== undefined) {
        const size =
          (e.innerWidth  !== undefined) ? new Size(e.innerWidth, e.innerHeight) :
          (e.clientWidth !== undefined) ? new Size(e.clientWidth, e.clientHeight) :
          null
        size && f(size)
      }
    }

    // Call initially and listen for further updates
    listener()
    const obs = new ResizeObserver(entries => listener())
    const dom =
      (e === null) ? null :
      (e.innerWidth !== undefined)  ? e.window.body :
      (e.clientWidth !== undefined) ? e :
      null
    dom && obs.observe(dom)

    // Cleanup
    return () => ((dom && obs.unobserve(dom)))
  })
}

export function fontConfig(font: Font, c: WebFont.Config): WebFont.Config {
  if (font.protocol === "google") {
    if (!c.google)
      c.google = {families: []}
    if (!c.google.families.find(f => f === font.family))
      c.google.families.push(`${ font.family }${ font.variation ?  font.variation : '' }`)
  }
  return c
}