import {asyncScheduler, BehaviorSubject, merge, Observable, of, OperatorFunction, Subject} from "rxjs"
import {Collage} from "../models/collage"
import {BackdropWidget} from "./backdrop_widget"
import {
  BUTTON_SECONDARY,
  detectDragOrPinch,
  filtering,
  filterInstanceOf,
  filterIs,
  filterObservable,
  flipBoolean,
  hasButton$,
  isDoubleTap,
  isPinch$,
  isTouchy,
  pressesFromGesture,
  Size,
  taplog,
  tapsFromGesture,
  TTap,
  TTouchEvent,
  TTouchGesture,
  Widget
} from "@piccollage/cbjs"
import {UploadWidget} from "../../toolkit/ui/upload_widget"
import {LinkDotWidget, PanningDotWidget, ScrapWidget, TrashDotWidget} from "./scrap_widget"
import * as _ from "lodash"
import {manipulateScrapZToFront} from "../manipulators/manipulate_scrap_z_to_front"
import {manipulateScrapTransform} from "../manipulators/manipulate_scrap_transform"
import {
  delay,
  exhaustMap,
  filter,
  finalize,
  first,
  flatMap,
  map,
  pairwise,
  share,
  take,
  tap,
  throttleTime,
} from "rxjs/operators"
import {manipulateMagicDotTransform} from "../manipulators/manipulate_magic_dot_transform"
import {ProgressWidget} from "../../toolkit/ui/progress_widget"
import {manipulateScrapZToBack} from "../manipulators/manipulate_scrap_z_to_back"
import {StickerChooserWidget} from "../../sticker_chooser/ui/sticker_chooser_widget"
import {Sticker} from "../models/stickers"
import {ChooserItemWidget} from "../../toolkit/lib/chooser"
import {manipulateNewStickerTransform} from "../manipulators/manipulate_new_sticker_transform"
import {FloaterWidget} from "../../toolkit/ui/floater_widget"
import {manipulateAddSticker} from "../manipulators/manipulate_add_sticker";
import {ClipDotWidget, ImageScrapWidget} from "./image_scrap_widget";
import {Scrap} from "../models/scrap";
import {PathEditorWidget} from "../../path_editor/ui/path_editor_widget";
import {manipulateEditClippingPath} from "../manipulators/manipulate_edit_clipping_path";
import {GESTURE_LONG_PRESS_TIME, GESTURE_MIN_DRAG, repositionPoint} from "../../toolkit/models/positioning_util";
import {manipulateTrashScrap} from "../manipulators/manipulate_trash_scrap";
import {manipulateUndo} from "../manipulators/manipulate_undo";
import {manipulateRedo} from "../manipulators/manipulate_redo";
import {DotWidget} from "./dot_widget";
import {TextDotWidget, TextScrapWidget} from "./text_scrap_widget";
import {manipulateTextDotTransform} from "../manipulators/manipulate_text_dot_transform";
import {TextEditorWidget} from "../../text_editor/ui/text_editor_widget";
import {manipulateEditText} from "../manipulators/manipulate_edit_text";
import {manipulateAddFiles} from "../manipulators/manipulate_add_files";
import {manipulateBackdropZoom} from "../manipulators/manipulate_backdrop_zoom";
import {CaptureRequest, CollagerCoreWidget} from "./collager_core_widget";
import {manipulateBackdropTransform} from "../manipulators/manipulate_backdrop_transform";
import {manipulateSketchStroke} from "../manipulators/manipulate_sketch_stroke";
import {Stroke} from "../../toolkit/models/stroke";
import {Color} from "../../toolkit/models/color";
import {manipulatePanningDotTransform} from "../manipulators/manipulate_panning_dot_transform";
import {manipulateScrapLink} from "../manipulators/manipulate_scrap_link";
import {LinkEditorWidget} from "../../link_editor/ui/link_editor_widget";
import {manipulateUpdateThumbnail} from "../manipulators/manipulate_update_thumbnail";
import {manipulateUpdateModifiedAt} from "../manipulators/manipulate_update_modified_at";

const PINCH_TIMEOUT = 1

enum SKETCH_MODES {
  SKETCH_MODELESS,
  SKETCH_MODAL,
}
const SKETCH_MODE: SKETCH_MODES = SKETCH_MODES.SKETCH_MODAL

const DOUBLE_TAP_DURATION = 500
const DOUBLE_TAP_DISTANCE = 4

export class EditorWidget extends CollagerCoreWidget {

  // LEARN: UI Layer has two functions:
  // - Capture state of the UI (View should have no state).
  // - Interactions between components.
  //

  // ---- Inputs
  gesture$          = new Subject<TTouchGesture>()
  gestureTargeted$  = new Subject<[TTouchGesture, Widget|undefined]>()


  // ---- Inputs filtered from `gesture$`, optimize by sharing
  private gestureDragPinch$         = new Subject<[TTouchGesture, Widget|undefined]>()
  private gestureDragPinchAltOn$    = new Subject<[TTouchGesture, Widget|undefined]>()    // Shifted or secondary button
  private gestureDragPinchAltOff$   = new Subject<[TTouchGesture, Widget|undefined]>()    // Not shifted or secondary button
  private gestureWidgetDrag1$       = new Subject<[TTouchGesture, Widget|undefined]>()
  private gestureWidgetPinch$       = new Subject<[TTouchGesture, Widget|undefined]>()

  // ---- Undo/Redo
  tappedUndo$ = new Subject<boolean>()
  tappedRedo$ = new Subject<boolean>()

  // ---- Zoom controls
  tappedZoomIn$  = new Subject<boolean>()
  tappedZoomOut$ = new Subject<boolean>()

  // ---- Floater and progress subwidgets
  uploadWidget$         = new BehaviorSubject<UploadWidget|null>(null)
  progressWidgets$      = new BehaviorSubject<ProgressWidget[]>([])
  floaterWidget$        = new BehaviorSubject<FloaterWidget|null>(null)

  // ---- Chooser/editor widgets
  pathEditorWidget$     = new BehaviorSubject<PathEditorWidget|null>(null)
  textEditorWidget$     = new BehaviorSubject<TextEditorWidget|null>(null)
  stickerChooserWidget$ = new BehaviorSubject<StickerChooserWidget|null>(null)
  linkEditorWidget$     = new BehaviorSubject<LinkEditorWidget|null>(null)

  // ---- Sketch
  static colors = [
    new Color("#ff00ff"),
    new Color("#000000"),
    new Color("#ff0000"),
    new Color("#00ff00"),
    new Color("#0000ff"),
    new Color("#00ffff"),
    new Color("#ffff00"),
  ]
  curSketchStroke$ = new BehaviorSubject<Stroke|null>(null)
  curSketchColor$  = new BehaviorSubject<Color>(EditorWidget.colors[0])

  // =========================================================================
  // ---- Lifecycle
  constructor(readonly collage: Collage) {
    super(collage)
    console.log("++++ EditorWidget constructor")

   // ---- Setup properties and property connections
    this.connectGestures()

    // ---- Do connections for touch gestures
    this.connectScrapWidgetsTransform()
    this.connectTransformDots()
    this.connectTapping()
    this.connectStickerChooserWidgets()
    this.connectPressing()
    this.connectBackdropTransform()
    this.connectSketching()

    // ---- Do connections for tapping
    this.connectUndoRedo()
    this.connectBackdropButtons()

    // ---- Do connections for other inputs
    this.connectBackdropDraggedFiles()
    this.connectThumbnailGeneration()
    this.connectModifiedAt()
  }

  // TODO: if we can figure out how to implement finalize() for widgets,
  //       then we should call modified$.complete() when the user leaves the editor
  //       so that the thumbnail will be updated if between throttleTimes.
  private connectThumbnailGeneration() {
    this.triggering(
      this.modified$.pipe(
        throttleTime(30000, asyncScheduler, {leading: true, trailing: true}),
        delay(2000)
      ),
      _ => manipulateUpdateThumbnail(this)
    )
  }

  private connectModifiedAt() {
    this.triggering(
      this.modified$.pipe(
        // TODO: How do we want to throttle this command, if at all?
        throttleTime(5000, asyncScheduler, {leading: true, trailing: true})
      ),
      _ => manipulateUpdateModifiedAt(this)
    )
  }

  // ---- Connect filtered gesture streams
  private connectGestures() {

    this.connecting(
      this.gesture$.pipe(
        taplog(">>>> gesture$"),
        flatMap(gesture =>
          topmostTargetFromGesture(gesture).pipe(
            map(target => [gesture, target] as [TTouchGesture, Widget|undefined])
          ))
      ),
      this.gestureTargeted$
    )

    this.connecting(
      this.gestureTargeted$.pipe(
        filterObservable(([gesture, _]) =>
          gesture.pipe(detectDragOrPinch(GESTURE_MIN_DRAG))),
        share()),
      this.gestureDragPinch$)

    const isGestureAlt = (source: TTouchGesture) => merge(
      hasButton$(BUTTON_SECONDARY)(source),
      source.pipe(map(e => e.shiftKey)),
    )
    this.connecting(
      this.gestureDragPinch$.pipe(
        filterObservable(([gesture, _]) =>
          gesture.pipe(isGestureAlt)),
      ),
      this.gestureDragPinchAltOn$)
    this.connecting(
      this.gestureDragPinch$.pipe(
        filterObservable(([gesture, _]) =>
          gesture.pipe(isGestureAlt, map(b => !b))),
      ),
      this.gestureDragPinchAltOff$)

    this.connecting(
      this.gestureDragPinchAltOff$.pipe(
        filterObservable(([gesture, _]) =>
          gesture.pipe(isPinch$(PINCH_TIMEOUT), flipBoolean())
        ),
        taplog(">>>> gestureWidgetDrag1$"),
      ),
      this.gestureWidgetDrag1$
    )


    this.connecting(
      this.gestureDragPinchAltOff$.pipe(
        filterObservable(([gesture, _]) =>
          gesture.pipe(isPinch$(PINCH_TIMEOUT))
        ),
        taplog(">>>> gestureWidgetPinch$"),
      ),
      this.gestureWidgetPinch$
    )
  }

  // ---- Behavior drag/pinch -> transform
  private connectScrapWidgetsTransform() {

    // ---- Trigger manipulation
    this.triggering(
      this.gestureWidgetPinch$.pipe(
        filterIs(
          (t): t is [any, ScrapWidget] =>
            t[1] instanceof ScrapWidget
        )),
      ([ gesture, scrapWidget]) =>
        manipulateScrapTransform(gesture,
                                 this.collageOffset(),
                                 this.collage,
                                 scrapWidget
                                 ).pipe(
          this.focusingManipulation(scrapWidget),
        )
    )

    if (SKETCH_MODE === SKETCH_MODES.SKETCH_MODAL) {
      this.triggering(
        this.gestureWidgetDrag1$.pipe(
          filterIs((t): t is [TTouchGesture, ScrapWidget] =>
            t[1] instanceof ScrapWidget)
        ),
        ([gesture, scrapWidget]) =>
          manipulateScrapTransform(gesture,
                                   this.collageOffset(),
                                   this.collage,
                                   scrapWidget
                                   ).pipe(
            this.focusingManipulation(scrapWidget)
          )
      )

    }
  }

  private connectBackdropTransform() {

    this.triggering(
      this.gestureDragPinchAltOn$,
      ([gesture, _]) =>
        manipulateBackdropTransform(this, gesture)
    )

    this.triggering(
      this.gestureDragPinchAltOff$.pipe(
        filter(([gesture, target]) => target instanceof BackdropWidget),
      ),
      ([gesture, _]) =>
        manipulateBackdropTransform(this, gesture)
    )

    this.triggering(
      this.gestureWidgetPinch$.pipe(
        filter(
          ([gesture, widget]) =>
            widget instanceof BackdropWidget || widget === undefined
        )),
      ([ gesture, _]) =>
        manipulateBackdropTransform(this, gesture)
    )
  }

  // ---- Behavior drag/pinch -> transform
  private connectStickerChooserWidgets() {

    // ---- Trigger manipulation
    this.triggering(
      this.gestureDragPinchAltOff$.pipe(
        filterIs(
          (t): t is [any, ChooserItemWidget<Sticker>] =>
            (t[1] instanceof ChooserItemWidget) &&
            (t[1].model instanceof Sticker)
        ),
        map(([_, widget]) => [_, widget.model]),
      ),
      ([ gesture, sticker]) =>
        manipulateNewStickerTransform(gesture,
                                      this,
                                      this.collage,
                                      sticker)
    )
  }

  // ---- Behavior drag/pinch -> transform
  private connectTransformDots() {

    // ---- Trigger manipulations
    this.triggering(
      this.gestureWidgetDrag1$.pipe(
        filterIs((t): t is [TTouchGesture, DotWidget] =>
          t[1] instanceof DotWidget &&
          !(t[1] instanceof TextDotWidget) &&
          !(t[1] instanceof PanningDotWidget)
        )
      ),
      ([ gesture, dotWidget]) =>
        manipulateMagicDotTransform(gesture,
                                    this.collageOffset(),
                                    this.collage,
                                    dotWidget
                                    ).pipe(
          this.focusingManipulation(dotWidget.scrapWidget)
        )
    )
    this.triggering(
      this.gestureWidgetDrag1$.pipe(
        filterIs((t): t is [TTouchGesture, TextDotWidget] =>
          t[1] instanceof DotWidget &&
          t[1] instanceof TextDotWidget)
      ),
      ([ gesture, dotWidget]) =>
        manipulateTextDotTransform(gesture,
          this.collageOffset(),
          this.collage,
          dotWidget
        ).pipe(
          this.focusingManipulation(dotWidget.scrapWidget)
        )
    )
    this.triggering(
      this.gestureWidgetDrag1$.pipe(
        filterIs((t): t is [TTouchGesture, DotWidget] =>
          t[1] instanceof PanningDotWidget)
      ),
      ([ gesture, dotWidget]) =>
        manipulatePanningDotTransform(gesture,
          this.collageOffset(),
          this.collage,
          dotWidget
        ).pipe(
          this.focusingManipulation(dotWidget.scrapWidget)
        )
    )

  }

  // ---- Connect to tapping on scraps
  private connectTapping() {

    const targetTap$ = this.gestureTargeted$.pipe(
      flatMap(([gesture, target]) =>
        tapsFromGesture(gesture, GESTURE_MIN_DRAG).pipe(
          map(tap => [target, tap] as [Widget|undefined, TTap])
        ),
      )
    )

    // Use `exhaustMap` so that we don't get double triggering issues.
    // This happens if mouse and touch events get both processed.
    // TODO: figure out a better way of doing this.

    // ---- Trigger tap to front
    this.triggering(
      targetTap$.pipe(
        taplog("++++ tap"),
        filter(([target, tap]) => target instanceof ScrapWidget
                                  && tap.tPress < GESTURE_LONG_PRESS_TIME),
        map(([target, _]) => target),
        filterInstanceOf(ScrapWidget),
      ),
      target => (
        // (TTouchEvent.commit(tap.event)),
        manipulateScrapZToFront(this.collage, target).pipe(
          this.focusingManipulation(target as ScrapWidget)
        )
      ),
      exhaustMap
    )

    // ---- Trigger clipping editor
    this.triggering(
      targetTap$.pipe(
        // ---- Filter by dot target
        map(([ target, _ ]) => target),
        filterInstanceOf(ClipDotWidget),
        filter(target => !(target instanceof TrashDotWidget)
                         && !(target instanceof LinkDotWidget)
        ),
        // ---- Filter by scrap type
        map(target => target.scrapWidget),
        filterInstanceOf(ImageScrapWidget),
      ),
      widget => manipulateEditClippingPath(this, widget),
      exhaustMap
    )


    // ---- Trigger text editor via double-tap on text/any of its dot widgets
    this.triggering(
      targetTap$.pipe(
        pairwise(),

        // ---- Filter for double taps on the same target
        filter(([[target0, tap0], [target1, tap1]]) =>
          target0 === target1
          && isDoubleTap(DOUBLE_TAP_DURATION, DOUBLE_TAP_DISTANCE, tap0, tap1)
        ),

        // ---- Get scrap widget
        map(([[target]]) =>
            target instanceof DotWidget ? target.scrapWidget : target
        ),

        // ---- Filter by scrap type
        filterInstanceOf(TextScrapWidget)
      ),
      widget => manipulateEditText(this, widget),
      exhaustMap
    )

    // ---- Trigger trash/delete
    this.triggering(
     targetTap$.pipe(
       // ---- Filter by dot target
       map(([ target, _ ]) => target),
       filterInstanceOf(TrashDotWidget),
       map(target => target.scrapWidget),
      ),
      widget => manipulateTrashScrap(this, widget),
      exhaustMap
    )

    // ---- Trigger tapping on a sticker
    this.triggering(
      targetTap$.pipe(
        filter(([target, tap]) =>
          target instanceof ChooserItemWidget &&
          target.model instanceof Sticker),
      ),
      ([target, tap]) =>
        manipulateAddSticker(this, this.collage,
          (target as ChooserItemWidget<Sticker>).model),
      exhaustMap
    )

    // ---- Trigger link editor
    this.triggering(
      targetTap$.pipe(
        // ---- Filter by dot target
        map(([ target, _ ]) => target),
        filterInstanceOf(LinkDotWidget),
        map(target => target.scrapWidget),
      ),
      widget => manipulateScrapLink(this, widget),
      exhaustMap
    )


  }

  // ---- Connect to tapping on scraps
  private connectPressing() {

    const targetPress$ = this.gestureTargeted$.pipe(
      flatMap(([gesture, target]) =>
        pressesFromGesture(gesture,
          GESTURE_LONG_PRESS_TIME,
          GESTURE_MIN_DRAG,
        ).pipe(
          map(press => [target, press] as [Widget|undefined, TTap])
        )
      )
    )

    this.triggering(
      targetPress$.pipe(
        filter(([target, _]) => target instanceof ScrapWidget),
        // ------------------------------------------------
        // Currently we have a problem with touch+mouse issues since
        // both the touch* and mouse* events trigger a `TPress` event.
        // We can't preventDefault to disable the mouse* events because
        // we don't know it's a TPress until later and if the user doesn't
        // move a finger there are no more events.
        // So for now we harmlessly just throttle it, since the user
        // is unlikely to long press twice in a row.
        // ------------------------------------------------
        throttleTime(GESTURE_LONG_PRESS_TIME * 2),
      ),
      ([target, press]) => {
        TTouchEvent.commit(press.event)
        return manipulateScrapZToBack(press, this.collage, target as ScrapWidget).pipe(
          this.focusingManipulation(target as ScrapWidget),
        )
      },
      exhaustMap
    )

  }

  // ---- Connect sketching
  connectSketching() {
    if (SKETCH_MODE === SKETCH_MODES.SKETCH_MODELESS) {
      this.triggering(
        this.gestureWidgetDrag1$.pipe(
          filterIs((t): t is [TTouchGesture, ScrapWidget|BackdropWidget] =>
            t[1] instanceof ScrapWidget ||
            t[1] instanceof BackdropWidget)
        ),
        ([gesture]) => manipulateSketchStroke(this, gesture)
      )
    }
  }

  // ---- Connect Undo/Redo
  connectUndoRedo() {
    this.triggering(this.tappedUndo$, () => manipulateUndo(this))
    this.triggering(this.tappedRedo$, () => manipulateRedo(this))
  }

  // ---- Connect backdrop
  connectBackdropDraggedFiles() {
    this.triggering(
      this.backdropWidget.draggedFiles$.pipe(
        // Need to reposition based on the backdrop location
        map(({ point, files }) => {
          point = repositionPoint(point, this.collageOffset())
          return { point, files }
        })
      ),
      ({ point, files }) =>
        manipulateAddFiles(this, files, false, point, false)
    )
  }
  connectBackdropButtons() {
    this.triggering(this.tappedZoomOut$, _ => manipulateBackdropZoom(this, 0.8))
    this.triggering(this.tappedZoomIn$,  _ => manipulateBackdropZoom(this, 1.2))
  }

  capture(size: Size): Observable<Blob>
  {
    return of(new CaptureRequest(size)).pipe(
        // ---- Step 0: Setup capture mode
        tap(() => this.captureMode$.next(true)),

        // ---- Step 1: Request capture
        flatMap(request => {
          this.captureRequest$.next(request)
          return request.captured$
        }),
        taplog("++++ manipulateCaptureCollage blob"),

        // ---- Cleanup capture mode
        take(1),
        finalize(() => this.captureMode$.next(false)),
      )
  }

}

// ---- Utility methods
export function mapScrapToScrapWidget<T extends ScrapWidget>(editorWidget: EditorWidget)
  : OperatorFunction<Scrap, T>
{
  return flatMap(scrap =>
    editorWidget.scrapWidgets$.pipe(
      filtering(widgets => widgets.find(widget =>
        widget.scrap.id === scrap.id)),
      map(_ => _ as T),
      first()   // Needs first so that it stops when its found one
    )
  )
}




// ---- Return an Observable with the topmost Widget under the gesture (note that
//      it will only every return ONE value.
//
type Targets =
  DotWidget
  | ScrapWidget
  | BackdropWidget
  | ChooserItemWidget<any>

function topmostTargetFromGesture(g: TTouchGesture)
  : Observable<Targets|undefined>
{
  const targetDistance = isTouchy ? 20 : 10

  return g.pipe(
    take(1), // Do first, only if gesture starts at ScrapWidget
    map(touchEvent => {
      const targetings = touchEvent.targetings
      const targets = targetings.map(t => t.target)
      const point = touchEvent.touches[0]?.point
      console.log(">>>> topmostTargetFromGesture", point, targetings)
      if (!point)
        return undefined

      return (
        // ---- ChooserItemWidgets
        targets
          .find((t): t is ChooserItemWidget<any> => t instanceof ChooserItemWidget)

        // ---- ScrapDotWidgets
        || _.maxBy<DotWidget>(
          targets
            .filter((t): t is DotWidget => t instanceof DotWidget),
          t => t.scrapWidget.z$.value
        )
        // ---- ScrapWidgets
        || _.sortBy(
            targetings.filter(t => t.target instanceof ScrapWidget),
            t => (t.target as ScrapWidget).z$.value
          )
          .reverse()
          .find(t =>
            (t.target as ScrapWidget).isTargetPrecise(point, t.rect, targetDistance)
          )?.target as ScrapWidget

        // ---- BackdropWidget
        || targets.find((t): t is BackdropWidget => t instanceof BackdropWidget)
      )
    }),
  )
}