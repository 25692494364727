import {CollagerCoreWidget} from "./collager_core_widget";
import {Collage, COLLAGE_URL} from "../models/collage";
import {isTouchy, taplog, tapsFromGesture, TTap, TTouchGesture, Widget} from "@piccollage/cbjs";
import {Observable, Subject} from "rxjs";
import {filter, flatMap, map, take} from "rxjs/operators";
import {ScrapWidget} from "./scrap_widget";
import {BackdropWidget} from "./backdrop_widget";
import * as _ from "lodash"
import {GESTURE_MIN_DRAG} from "../../toolkit/models/positioning_util";
import {manipulateNavigateScrapLink} from "../../app/manipulators/manipulate_navigate_scrap_link";

export class ViewerWidget extends Widget {

  // ---- Inputs
  gesture$ = new Subject<TTouchGesture>()
  gestureTargeted$ = new Subject<[TTouchGesture, Widget | undefined]>()

  // ---- Properties
  collageCoreWidget: CollagerCoreWidget

  // ---- Outputs
  linkUrl$ = new Subject<COLLAGE_URL>()

  // ---- Lifecycle
  constructor(readonly collage: Collage) {
    super();
    console.log("++++ CreatorWidget constructor")

    this.collageCoreWidget = this.legate(() => new CollagerCoreWidget(collage))

    this.connectGestures()
    this.connectTapping()

  }

  // ---- Connect filtered gesture streams
  private connectGestures() {

    this.connecting(
      this.gesture$.pipe(
        taplog(">>>> gesture$"),
        flatMap(gesture =>
          topmostTargetFromGesture(gesture).pipe(
            map(target => [gesture, target] as [TTouchGesture, Widget | undefined])
          ))
      ),
      this.gestureTargeted$
    )
  }

  // ---- Connect to tapping on scraps
  private connectTapping() {

    const targetTap$ = this.gestureTargeted$.pipe(
      flatMap(([gesture, target]) =>
        tapsFromGesture(gesture, GESTURE_MIN_DRAG).pipe(
          map(tap => [target, tap] as [Widget | undefined, TTap])
        ),
      )
    )

    // ---- Trigger tap to front
    this.triggering(
      targetTap$.pipe(
        filter(([target, tap]) =>
          target instanceof ScrapWidget && target.linkUrl$.value != null),
      ),
      ([target]) => (
        manipulateNavigateScrapLink(this, (target as ScrapWidget).linkUrl$.value as string)
      )
    )



  }
}

type Targets =
  ScrapWidget
  | BackdropWidget

function topmostTargetFromGesture(g: TTouchGesture)
  : Observable<Targets|undefined>
{
  const targetDistance = isTouchy ? 20 : 10

  return g.pipe(
    take(1), // Do first, only if gesture starts at ScrapWidget
    map(touchEvent => {
      const targetings = touchEvent.targetings
      const targets = targetings.map(t => t.target)
      const point = touchEvent.touches[0]?.point
      console.log(">>>> topmostTargetFromGesture", point, targetings)
      if (!point)
        return undefined

      return (
        // ---- ScrapWidgets
        _.sortBy(
          targetings.filter(t => t.target instanceof ScrapWidget),
          t => (t.target as ScrapWidget).z$.value
        )
          .reverse()
          .find(t =>
            (t.target as ScrapWidget).isTargetPrecise(point, t.rect, targetDistance)
          )?.target as ScrapWidget

        // ---- BackdropWidget
        || targets.find((t): t is BackdropWidget => t instanceof BackdropWidget)
      )
    }),
  )
}

