import {BehaviorSubject, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {arraySubjectAdd, arraySubjectRemove, ID, Widget} from "@piccollage/cbjs";

export interface ChooserItem {
  readonly id: ID
  readonly title: string|null
  readonly thumbnail: URL|null
}

export class ChooserWidget<T extends ChooserItem> extends Widget {
  // ---- Input properties
  items$       = new BehaviorSubject<ChooserItemWidget<T>[]>([])
  itemsChosen$ = new BehaviorSubject<ChooserItemWidget<T>[]>([])

  // ---- This manipulators (need to connect appropriately)
  manipulate_choose_single(itemWidget: ChooserItemWidget<T> | null) {
    if (itemWidget)
      this.itemsChosen$.next([itemWidget])
    else
      this.itemsChosen$.next([])
  }

  manipulate_choose_multiple(chosen: boolean, itemWidget: ChooserItemWidget<T>) {
    if (chosen)
      arraySubjectAdd(this.itemsChosen$, itemWidget)
    else
      arraySubjectRemove(this.itemsChosen$, itemWidget)
  }

  manipulate_toggle_multiple(itemWidget: ChooserItemWidget<T>) {
    if (this.itemsChosen$.value.find(i => i === itemWidget))
      arraySubjectRemove(this.itemsChosen$, itemWidget)
    else
      arraySubjectAdd(this.itemsChosen$, itemWidget)
  }

}

export class ChooserItemWidget<T extends ChooserItem> extends Widget {

  // ---- Properties
  get id() { return this.model.id }

  // ---- Incoming events
  tapped$ = new Subject<boolean>()
  isChosen$ = new BehaviorSubject(false)

  constructor(readonly chooser: ChooserWidget<T>, readonly model: T) {
    super()

    this.connecting(
      this.chooser.itemsChosen$.pipe(
        map(itemsChosen => !!itemsChosen.find(i => i === this))
      ),
      this.isChosen$
      )

  }

}
