import * as React from "react";
import {MyCollagesWidget} from "../ui/my_collages_widget";
import styles from "./my_collages_view.module.scss"
import {AllCollagesWidget} from "../ui/all_collages_widget";
import {AllCollagesView} from "./all_collages_view";
import {TrashedCollagesView} from "./trashed_collages_view";
import {TrashedCollagesWidget} from "../ui/trashed_collages_widget";
import {SideBarView} from "./side_bar_view";
import {useBehaviorSubject} from "@piccollage/cbjs";

export function MyCollagesView(props: { widget: MyCollagesWidget}) {
  const currentTabWidget = useBehaviorSubject(props.widget.currentTabWidget$);

  return (
    <div className={styles.MyCollagesView}>
      <SideBarView widget={props.widget.sideBarWidget} />
      {
        (currentTabWidget instanceof AllCollagesWidget) ?
          (<AllCollagesView widget={currentTabWidget} />) :
        (currentTabWidget instanceof TrashedCollagesWidget) ?
          (<TrashedCollagesView widget={currentTabWidget} />) :
        null
      }
    </div>);
}